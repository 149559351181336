import React from 'react';

/* props
    _children_
    depth
    min
*/
const VariableHeading = (props) => {
  const depth = parseInt(props.depth);
  const min = parseInt(props.min);
  const CustomTag = `h${depth + min < 6 ? depth + min : 6}`;
   return (
     <CustomTag className="text-miuvi"> {props.children} </CustomTag>
   );
}

export default VariableHeading;
