import React, { useState } from 'react';

const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

const Admin_RegistrationForm = (props) => {

    const [inputs, setInputs] = useState({reg_user: "", reg_pw: "", reg_leftVids: null, reg_templates: ""});

    function handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        // console.log(name, value);
        setInputs((inputs) => {
            return({
                ...inputs,
                [name]: value
            })
        })
    }

    function handleRegSubmit() {
        const params = new URLSearchParams();
        params.append("email", inputs.reg_user);
        params.append("password", inputs.reg_pw);
        params.append("leftVideos", inputs.reg_leftVids);
        params.append("templateIDsString", inputs.reg_templates);
        // logFD(params); //TODO pull out to util file
        const token = window.sessionStorage.getItem('mv_usr');
        if(token) {
            axios.post(
            `${apiUrl}/api/auth/register`,
            params,
            {headers: { Authorization: `Bearer ${token}` }}) //api/auth/register
            .then(res => {
            // console.log(res.status + " " + res.statusText);
            // console.log(res.data);
            })
            .catch((err) => {
            // console.log(err);
            console.log(err.response.message);
            console.log(err.response.status);
            });
        } else {
            console.log("Err: token missing");
        }
    }
    
    return(
        <div>
            <form>
                <label htmlFor="reg_user">_username</label>
                <input name="reg_user" type="text" onChange={handleInputChange} />

                <label htmlFor="reg_pw">_pw</label>
                <input name="reg_pw" type="password" onChange={handleInputChange} />

                <label htmlFor="reg_leftVids">_leftVids</label>
                <input name="reg_leftVids" type="text" onChange={handleInputChange} />

                <label htmlFor="reg_templates">_templates[separate with ,]</label>
                <input name="reg_templates" type="text" onChange={handleInputChange} />

                <button type="button" onClick={handleRegSubmit}> _confirm </button>
            </form>
        </div>
    )
}

export default Admin_RegistrationForm;