import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Alert, Button, Form, FormGroup, Input } from 'reactstrap';

const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;


const ContactForm = (props) => {
    const [captchaError, setCaptchaError] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);

    const {register, handleSubmit, errors, reset, trigger, getValues} = useForm();
    const validationOptions = {
        email: {
            required: "Bitte E-Mail-Adresse angeben"
        },
        captcha: {
            validate: !captchaError
        }
    };  
  
    const handleAlertDismiss = () => setAlertVisible(false);
    const handleErrorDismiss = () => setCaptchaError(false);

    function handleContactSubmit(data) {
        setAlertVisible(false);
        setCaptchaError(false);
        
        const params = new URLSearchParams();
        params.append("name", data.name);
        params.append("email", data.email);
        params.append("subject", data.subject);
        params.append("text", data.text);
        params.append("captcha", data.captcha);
        
        axios.post(`${API_URL}/contact`, params) //api/auth/register
        .then(res => {
            // console.log(res.status + " " + res.statusText);
            // console.log(res.data);
            if(res.status === 200 || res.status === 304) {
                reset();
                setCaptchaError(false);
                setAlertVisible(true);
            } else {
                console.log(res.status);
            }
        })
        .catch(err => {
            console.log("Contact err: ", err);
            // reset({...getValues(), captcha: ""})
            setAlertVisible(false);
            setCaptchaError(true);
        })
    }

    function handleContactError(errors) {
        setAlertVisible(false);
        setCaptchaError(false);
        console.log(errors);
    }
    
    // Helpful - Nazaronis validation -- see tmp.js

    //Email
    //     if(!fields["email"]){
    //        formIsValid = false;
    //        errors["email"] = "Cannot be empty";
    //     }

    //     if(typeof fields["email"] !== "undefined"){
    //        let lastAtPos = fields["email"].lastIndexOf('@');
    //        let lastDotPos = fields["email"].lastIndexOf('.');

    //        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
    //           formIsValid = false;
    //           errors["email"] = "Email is not valid";
    //         }
    //    }  


    return (
        <Form onSubmit={handleSubmit(handleContactSubmit, handleContactError)} >
            <Input invalid={(errors.name)} type="text" name="name" 
                className={`mb-3 form-control ${props.dark ? "bg-transparent text-light border-primary" : "bg-light"}`}
                placeholder="Name" innerRef={register({required: true})} maxLength="64" ></Input>
            <Input invalid={(errors.email)} type="email" name="email"
                className={`mb-3 ${props.dark ? "bg-transparent text-light border-primary" : "bg-light"}`}
                placeholder="E-Mail" innerRef={register(validationOptions.email)} maxLength="128" ></Input>
            <Input invalid={(errors.subject)} type="text" name="subject"
                className={`mb-3 ${props.dark ? "bg-transparent text-light border-primary" : "bg-light"}`}
                placeholder="Betreff" innerRef={register({required: true})} maxLength="64" ></Input>            
            <Input invalid={(errors.text)} type="textarea" name="text" rows="4"
                className={`mb-3 ${props.dark ? "bg-transparent text-light border-primary" : "bg-light"}`}
                placeholder="Nachricht" innerRef={register({required: true})} maxLength="500" ></Input>            
            <FormGroup>
                <small className={`${props.dark && "text-light"}`}>25 + 7 = </small>
                <Input invalid={(errors.captcha)} type="text" name="captcha"
                    className={`mb-3 ${props.dark ? "bg-transparent text-light border-primary" : "bg-light"}`}
                    placeholder="Sicherheitsfrage" innerRef={register({required: true})} maxLength="8" ></Input>
            </FormGroup>
            <Button type="submit" color={`${props.dark ? "primary" : "dkshade"}`} className={`my-3 ${props.dark ? "text-light" : "text-primary"} mx-auto rounded-xxl py-3 px-5`}>
                Abschicken
            </Button>
            <Alert color="success" isOpen={alertVisible} toggle={handleAlertDismiss}>
                Nachricht gesendet
            </Alert>
            <Alert color="danger" isOpen={captchaError} toggle={handleErrorDismiss}>
                Sicherheitsfrage fehlerhaft
            </Alert>
        </Form>
    );
}

export default ContactForm;


{/* <Form>
<Input type="text" id="contact_name" className="mb-3 form-control bg-light" placeholder="Name"></Input>
<Input type="email" className="mb-3 bg-light" placeholder="E-Mail"></Input>
<Input type="text" className="mb-3 bg-light" placeholder="Betreff"></Input>
<Input type="textarea" rows="4" className="mb-3 bg-light" placeholder="Nachricht"></Input>
<Button type="button" color="dkshade" className="text-primary py-3 px-5">
  <i className="fas fa-paper-plane"></i> {"  "}Abschicken
</Button>
</Form> */}