import React from 'react';

import Video from '../Video';

const apiUrl = process.env.REACT_APP_API_URL;

/* props
    className
    templateId
    videoId
*/
const ExampleVideo = (props) => { //TODO make more react-y
    return(
        <div className={`row justify-content-md-center ${props.className}`}>
            <div className="embed-responsive embed-responsive-16by9 col-md-10 rounded-lg shadow">
                <Video url={`${apiUrl}/file/examplefile/${props.templateId}/${props.videoId}`} />
            </div>
        </div>
    );
}

export default ExampleVideo;