import React,{ Fragment, useState, Children, useEffect } from 'react';
import { Input, InputGroup, Tooltip, Badge, InputGroupAddon, InputGroupText, 
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col, FormGroup, Label } from 'reactstrap';
import HelpBadge from './HelpBadge';
import InputResetButton from './InputResetButton';
import TextLengthIndicator from './TextLengthIndicator';

import ValidationCheck from './ValidationCheck';

const apiUrl = process.env.REACT_APP_API_URL;

const breakingLength = 30;


/* props
    type
    tag
    label
    value
    help
    options
    required
    readOnly
    isValid
    onInputChange
    onValidationChange
*/
const SelectionInput = (props) => {
  const [modal, setModal] = useState(false);
  const [optionChecked, setOptionChecked] = useState(false);
  const [hoveredOption, setHoveredOption] = useState("");

  const toggle = () => setModal (!modal);


  function determineItemStyle(index){
    const isItemSelected = props.value === props.options[index];
    const isItemHovered = hoveredOption === props.options[index];
    
    if (isItemSelected && !isItemHovered){
      console.log(1);
      return "border border-primary rounded"
    } else if (!isItemSelected && isItemHovered){
      console.log(2);
      return "border bg-light rounded"
    } else if (isItemSelected && isItemHovered){
      
    console.log(3);
      return "border border-primary bg-light rounded"
    }
  }

  function handleInputChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    // console.log(inputValue);
    props.onInputChange(inputName, inputValue);
    props.onValidationChange(inputName, (!props.required || (inputValue !== "" && typeof inputValue !== "undefined")) ? //TODO make criteria submittable as prop - mby as function
      {valid: true, messages: []}
      :
      (!props.hideRegular && props.type === "text") ? //TODO will need some adjustments for other input types
        {valid: false, messages: ["Bitte auswählen oder ausfüllen"]}
        :
        {valid: false, messages: ["Bitte auswählen"]}
    ); //TODO make criteria submittable as prop - mby as function
  }

  function handleHover(option) {
    setHoveredOption(option);
    determineItemStyle(option);
    }

 /*  function handleClickOnOption(index){
    const clickedOptionTag = props.tag;
    const clickedOptionValue = props.options[index];
    // props.onOptionChange(clickedOptionTag, clickedOptionValue);

    setCheckedOption({ selectedItem: index });
    setOptionChecked(true);
    setTimeout(() => { modal ? setModal(!modal) : setModal(modal)}, 150);
    //ternary operator because toggle() already used in Input onClick
  } */

  function handleClickOnOption(event) {
    const value = event.target.value;
    // console.log(event.target.name, value);
    setOptionChecked(true);

    handleInputChange(event);
    
    setTimeout(() => { modal ? setModal(!modal) : setModal(modal)}, 150);
  }

  return (
      <div className="my-3">
        { true && //TODO
          <label
            htmlFor={props.tag}
            id={props.tag + "Label"}
            >
            {(props.required ? "* " : "") + props.label}
            {(!props.required) && <small className="text-secondary">{"  "}(optional)</small>}
          </label>
        }
        <HelpBadge id={props.tag + "Help"} text={props.help} /><br />

        {(props.type === "text") ?
          <Fragment>
            <InputGroup>
              {props.sizechangingcount <= props.options.length ?
              <Fragment>
                <InputGroupAddon addonType="prepend">
                  <Button className="bg-primary rounded-xxl border-primary" onClick={toggle}>
                    Auswählen {/*{(optionChecked || props.isValid) && props.required ? "Ändern" : "Auswählen"} //TODO <- */}
                  </Button>
                </InputGroupAddon>
                  {props.maxLength < breakingLength ?
                    <Input
                      type={props.type ? props.type : "text"} // ???
                      className="form-control bg-light"
                      id={props.tag}
                      name={props.tag}
                      placeholder={props.placeholder}
                      value={props.value}
                      maxLength={props.maxLength}
                      disabled={props.hideRegular}
                      required={props.required}
                      onChange={handleInputChange}
                    />
                    :
                    <Input
                      type={"textarea"}
                      className="form-control bg-light"
                      id={props.tag}
                      name={props.tag}
                      rows={Math.ceil(props.maxLength/(breakingLength*0.8))}
                      style={{resize: "none"}}
                      placeholder={props.placeholder}
                      value={props.value}
                      maxLength={props.maxLength}
                      disabled={props.hideRegular}
                      required={props.required}
                      onChange={handleInputChange}
                    />
                  }
                  {props.maxLength && !props.hideRegular &&
                    <TextLengthIndicator
                    text={props.value}
                    maxLength={props.maxLength}
                    toleranceFactor={0.75}
                    />
                  }
              </Fragment>
              :
              () => { //TODO build small SelectionInput
                console.log("Err: SelectionInput sizechangingcount > options.length");
                return(<div>:(</div>);
              }
            }
            </InputGroup>
            <InputResetButton 
              inputName={props.tag}
              initialValue={props.initialValue}
              value={props.value}
              onInputChange={props.onInputChange}
              onValidationChange={props.onValidationChange}
              />
          </Fragment>
          :
          <div> {/* for images etc. */}
            {(!props.readOnly) &&
              <Button className="bg-primary rounded-xxl border-primary" onClick={toggle}>
                  Auswählen {/*{(optionChecked || props.isValid) && props.required ? "Ändern" : "Auswählen"} //TODO <- */}
              </Button>
            }
            <div height="10rem" width="10rem" className="">
              {(props.value) &&
                <img height="200px" className={`p-1 rounded border border-primary`} src={`${apiUrl}/file/footage-image/${props.value}`} />
              }
            </div>
            {/* {(!props.hideRegular) && }*/
            }
          </div>
        }

        <Modal isOpen={modal} toggle={toggle} centered={true} size={"xl"} fade={false} returnFocusAfterClose={true}>
          <ModalHeader toggle={toggle}>{props.label}</ModalHeader>
          <ModalBody className="bg-dkshade">
            <Container>
              <FormGroup check>
                <Row >
                  {
                    props.options.map((option, index) =>
                      <Col key={index} className="justify-content-center hoverable" htmlFor={props.tag + option.value} xs="12" sm="6" md="6" lg="4" xl="3" style={(option.background) ? {backgroundImage: `url("${apiUrl}/file/footage-image/${option.background}")`, backgroundSize: "cover", height: "10rem"} : (props.type === "text") ? {height: "10rem"} : {backgroundImage: `url("${apiUrl}/file/footage-image/${option.value}")`, backgroundSize: "cover", height: "10rem"}} className={"rounded " + determineItemStyle(index)} 
                            onMouseEnter={() => handleHover(option.value)} onMouseLeave={() => setHoveredOption({})} >
                        <Input style={{display: "none"}} id={props.tag + option.value} name={props.tag} value={option.value} onClick={handleClickOnOption} />
                        <Label htmlFor={props.tag + option.value} style={{width: "100%",height:"100%",display: "flex",margin: "3", textAlign: "center", flexDirection: "column", justifyContent: "center"}}>{(props.type === "text") && <span className={`${(hoveredOption === option.value) ? "bg-light text-dkshade" : ((props.value === option.value) ? "bg-primary text-light" : "bg-dkshade text-light")} rounded`}>{(option.label) ? option.label : option.value}</span>}</Label>
                      </Col>
                    )} 
                </Row>
              </FormGroup>
            </Container>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" onClick={toggle}>Übernehmen</Button>{' '}
          </ModalFooter> */}
        </Modal>
        {
          !props.readOnly &&
          <ValidationCheck
            criteria={props.isValid.valid} //{typeof props.value !== "undefined"}
            messages={props.isValid.messages}
          />
        }
      </div>
  );
}

export default SelectionInput;
