import React, { Fragment, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
  } from "react-router-dom";
import { Container, Row, Col, Jumbotron, Card, CardImg, CardBody, CardTitle, CardText, CardColumns, Spinner, CardDeck } from 'reactstrap';
import InfoMessage from './notification/InfoMessage';

const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

const TemplateCard = (props) => {
    const [templateInfos, setTemplateInfos] = useState(null);

    useEffect(() => {
    //   if(props.isAuthenticated) {
    //     if(!props.userData.leftVideos || props.userData.leftVideos > 0) {
    //       if(props.userData.props.templateIDs) {
    //         props.userData.props.templateIDs.map(props.templateID => {
              fetchTemplate(props.templateID);
            // });
    //       }
    //     }
    //   }
    }, [props.isAuthenticated, props.userData])

    const fetchTemplate = async (id) => { //TODO fetch info of all requested templates in one db call @ TemplatePage
      // setIsLoading(true);
      const token = window.sessionStorage.getItem('mv_usr');
      if(token) {
        try { //TODO replace try/catch block with axios' then/catch (see handle submit)
          const result = await axios.get(
            `${apiUrl}/templateinfo/${id}`, // api
            { headers: { Authorization: `Bearer ${token}` } } // auth
          );
          const isValid = (result.status === 200 || result.status === 304);
          if(isValid) {
            setTemplateInfos(result.data);
  
            // setIsLoading(false);
          } else {
            console.log("Token invalid");
            if(props.isAuthenticated)
              console.log("WARNING: Template page: Auth gone wrong!!!"); //TODO elaborate
              // props.onAuthChange(false);
          }
        } catch (error) {
          console.log(error);
        }
        // console.log(formState);
      } else {
        console.log("No token: user not signed in");
        if(props.isAuthenticated)
          console.log("WARNING: Template page: Auth gone wrong!!!"); //TODO elaborate
          // props.onAuthChange(false);
      }
    };

    return(
        <Card tag={Link} to={`/create/${props.templateID}`} name={`template${props.templateID}`} style={{ textDecoration: 'none' }} className={`card-wider text-white bg-transparent p-3 ${(props.hovered) ? "border-blue rounded-xxl" : "border-0"}`}
            onMouseEnter={() => props.onCardHoverStart(props.templateID)}
            onMouseLeave={() => props.onCardHoverEnd(props.templateID)}
        >
            <CardImg top src={`${apiUrl}/file/examplefile/${props.templateID}/example1.png`} alt="Template image" className="rounded" />
            <CardBody className="">
                {(templateInfos != null) ?
                    <Fragment>
                        <CardTitle><h4 className="text-blue">{templateInfos.name}</h4></CardTitle>
                        <CardText>{templateInfos.description}</CardText>
                    </Fragment>
                    :
                    <Spinner color="primary" />
                }
            </CardBody>
        </Card>
    );
}

export default TemplateCard;