import React, { useState } from 'react';
import { Input, Tooltip } from 'reactstrap';

import InputResetButton from './InputResetButton';
import ValidationCheck from './ValidationCheck';


/* props
    tag
    name
    label
    mainColor
    value
    help
    isRequired
    onInputChange
*/
const ColorInput = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function handleTooltipToggle() {
    setTooltipOpen(!tooltipOpen);
  }

  function handleInputChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    props.onInputChange(inputName, inputValue);
    props.onValidationChange(inputName, (!props.required || (props.tag === props.mainColor.tag)) ?  //TODO make criteria submittable as prop - mby as function; make more useful for ColorInput
      {valid: true, messages: []}
      :
      {valid: false, messages: ["Bitte auswählen"]}
    );
  }

  return (
      <div className="mb-3">
        {props.tag === props.mainColor.tag &&
          <label
            htmlFor={props.name}
            id={props.name + "Label"}
            >
            {props.label}
          </label>
        }
        <Input
          type="color"
          style={{cursor: "pointer"}}
          className={"form-control colorPicker bg-light rounded " + (props.tag !== props.mainColor.tag && "d-none")} /*mb-4*/
          id={props.name}
          name={props.name}
          value={props.tag === props.mainColor.tag ? props.value : props.mainColor.value}
          required={props.required}
          disabled={props.tag !== props.mainColor.tag}
          onChange={handleInputChange}
          />
        <InputResetButton 
          inputName={props.name}
          initialValue={props.initialValue}
          value={props.tag === props.mainColor.tag ? props.value : props.mainColor.value}
          onInputChange={props.onInputChange}
          onValidationChange={props.onValidationChange}
          />
        {props.tag === props.mainColor.tag &&
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={props.name + "Label"}
            toggle={handleTooltipToggle}
            >
            {props.help}
          </Tooltip>
        }
        { props.required && 
            <ValidationCheck
              criteria={props.isValid.valid} //{typeof props.value !== "undefined"}
              messages={props.isValid.messages}
            />
        }
      </div>
  );
}

export default ColorInput;
