
import React from 'react';

const Imprint = () => {
	return(
			<div className="text-center">
				<address className="mb-2 font-weight-bold text-miuvi">PERSON</address>
				<address className="mb-2">Florian Herrmann</address>
				<address className="mb-2">Weglehnerstraße 6</address>
				<address className="mb-2">91732 Merkendorf</address>
				<br />
				<address className="mb-2 font-weight-bold text-miuvi">KONTAKT</address>
				<address className="mb-2"> +49 179 106 9155</address>
				<address className="mb-2"> hallo@miuvi.de</address>
				<br />
				<address className="mb-2 font-weight-bold text-miuvi">UMSATZSTEUER ID:</address>
				<address className="mb-2">DE314238358</address>
			</div>
	);
}

export default Imprint;