import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import VariableHeading from './VariableHeading.js';
import ExampleImage from '../ExampleImage.js';
// import exampleImage from '../../../resources/example.png'; //testing

/* props
    _children_
    label
    depth
    imgId
*/
const InputBlock = (props) => {

  if(typeof props.imgId === "undefined") {
    return (
      <div className="py-3">
        <VariableHeading
          depth={props.depth}
          min="3"
          >
          {props.label}
        </VariableHeading>
        <Container className="border-left border-primary pr-0">
          {props.children}
        </Container>
      </div>
    );
  } else {
    return (
      <Row className="mb-3 py-3 justify-content-center">
        <Col xs="12">
          <VariableHeading
            depth={props.depth}
            min="3"
            >
            {props.label}
          </VariableHeading>
        </Col>
        <Col md={{size: 10, offset: 1}} lg={{size: 6, order: 2}} className="py-3 mx-0">
          <ExampleImage templateId={props.templateId} imgId={props.imgId}/>
          <p className="mb-0 text-secondary text-center">Beispiel</p>
        </Col>
        <Col lg={{size: 6, order: 1}} className="mb-3 mx-0">
          <Container className="border-left border-primary pr-0">
            {props.children}
          </Container>
        </Col>
      </Row>
    );
  }
}

export default InputBlock;
