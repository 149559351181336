import React, {useRef, useEffect} from 'react';

/* props
    className
    templateId
    videoId
*/
const ExampleVideo = (props) => { 
    const videoRef = useRef();
    const previousUrl = useRef(props.url);

    useEffect(() => {
      if (previousUrl.current === props.url) {
        return;
      }
      if (videoRef.current) {
        videoRef.current.load();
      }
      previousUrl.current = props.url;
    }, [props.url]);

    return(
        <video playsInline preload="metadata" controls ref={videoRef} >
            <source src={`${props.url}`} />
        </video>
    );
}

export default ExampleVideo;