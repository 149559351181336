import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Collapse, Input, Tooltip } from 'reactstrap';

import Nouislider from 'nouislider-react';
import "nouislider/distribute/nouislider.css";

import RecorderModal from '../recording/RecorderModal';
import HelpBadge from './HelpBadge';
import ValidationCheck from './ValidationCheck';
import VideoTrimmer from '../trim/VideoTrimmer';

const axios = require('axios');
const apiUrl = process.env.REACT_APP_API_URL;

const maxFileSize = 500 * 1000 * 1000; //also change in backend!

/* props
    tag
    type
    label
    help
    required
    isValid
    onInputChange
    onValidationChange
*/
const FileInput = (props) => {

  // const [trim, setTrim] = useState({});

  const [supportedFiles, setSupportedFiles] = useState({});

  useEffect(() => {
    axios.get(
      `${apiUrl}/filesupport` // api
      // { headers: { Authorization: `Bearer ${token}` } } // auth //not necessary (probably)
    )
    .then(res => {
      setSupportedFiles(res.data);
    })
    .catch (err => {
      console.log(err);
    });

  }, []);


  function checkFileSupport (filename) { //TODO handle situation where supportedFiles couldn't be populated
    const ext = (filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename).toLowerCase();
    if(!supportedFiles[props.type].includes(ext)) {
      return `Der Dateityp ".${ext}" wird leider nicht unterstützt. Versuche es bitte mit einem der folgenden Formate: ${"." + supportedFiles[props.type].join(', .')}`;
    } else {
      return "";
    }
  }

  function handleInputChange(event, optionalInfo) {
    const inputName = event.target.name;
    const inputValue = event.target.files[0];
    props.onInputChange(inputName, inputValue, optionalInfo);
    props.onValidationChange(inputName, function() {
      if(typeof inputValue !== "undefined") {
        const fileSupportMsg = checkFileSupport(inputValue.name);
        if(inputValue.size <= maxFileSize) {
          return (fileSupportMsg === "") ? {valid: true, messages: []} : {valid: false, messages: [fileSupportMsg]};
        } else {
          return {valid: false, messages: [`Die ausgewählte Datei ist leider zu groß. Die maximale Dateigröße beträgt ${Math.round(maxFileSize / 1000000)}MB.`]};
        }
      } else {
        return (!props.required) ? {valid: true, messages: []} : {valid: false, messages: ["Bitte auswählen oder aufnehmen"]};
      }
    }());
  }  

  function hanldeTrimChange(newTrim) {
    props.onOptionalInfoChange(props.tag, {trimInfo: newTrim});
  }
  
  
  function generateObjectURL(file) {
    // 'use strict'
    var URL = window.URL || window.webkitURL;
    // var displayMessage = function (message, isError) {
    //   // var element = document.querySelector('#message')
    //   console.log(message, isError);
    //   // element.innerHTML = message
    //   // element.className = isError ? 'error' : 'info'
    // }
    // // var playSelectedFile = function (event) {
    //   // var file = this.files[0]
    //   if(props.type === "video") {
    //     var type = file.type;
    //     var videoNode = element; //document.getElementById('video');
    //     var canPlay = videoNode.canPlayType(type);
    //     if (canPlay === '') canPlay = 'no';
    //     var message = 'Can play type "' + type + '": ' + canPlay;
    //     var isError = canPlay === 'no';
    //     displayMessage(message, isError);
        
    //     // if (isError) {
    //     //   return
    //     // }
    //   }
  
      var fileURL = URL.createObjectURL(file)
      // videoNode.src = fileURL
      return fileURL;
    // }
    // var inputNode = document.querySelector('input')
    // inputNode.addEventListener('change', playSelectedFile, false)
  }



  return (
      <div className="mb-3">
          <label
            htmlFor={props.tag}
            id={props.tag + "Label"}
            >
            {(props.required ? "* " : "") + props.label}
            {(!props.required) && <small className="text-secondary">{"  "}(optional)</small>}
          </label>
        <HelpBadge id={props.tag + "Help"} text={props.help} />
        <Input
          type="file"
          accept={(props.type === "image") ? "image/*" : (props.type === "video") ? "video/*" : (props.type === "audio") ? "audio/*" : ""}
          style={{cursor: "pointer"}}
          className={`form-control dnd dnd-b bg-light border-dashed ${props.isValid.valid ? "border-success" : ""} rounded-lg`} /*mb-4*/
          id={props.tag}
          name={props.tag}
          required={props.required}
          onChange={handleInputChange}
          disabled={false && (props.tag.startsWith() === "Vid1" || props.tag === "Vid2" || props.tag === "ImgPortrait")} // !! remove && false to block, remove expression on right or handle differently for production
          />



        
        
          <Collapse isOpen={(props.value && props.isValid.valid) ? true : false} >
            {( (props.type === "image") ?
                <img src={props.value && generateObjectURL(props.value)} className="rounded img-fluid mt-3"></img>
              : (props.type === "video") &&
                <VideoTrimmer
                  sourceObject={props.value}
                  templateId={props.templateId}
                  onTrimChange={newTrim => { hanldeTrimChange(newTrim); }}
                  />
            )}
          </Collapse>

        {/* { (props.tag === "Vid1" || props.tag === "Vid2") ? //TODO Handle generally (above too)
          <RecorderModal
                tag={props.tag}
                type={"video"} 
                maxLength={60}
                buttonLabelRecord={"Video aufnehmen"}
                  //buttonLabelRetry={"Erneut aufnehmen"} //TODO
                headerLabel={props.label}
                help={props.help}
                required={props.required}
                isValid={props.isValid}
                onSaveRecording={handleInputChange}
                >
                  <strong>Nimm jetzt <span className="text-miuvi">dein Video</span> auf</strong> - aber stress dich nicht,<br />
                  im Anschluss kannst du es auch auf das Wesentliche zuschneiden.
          </RecorderModal>
          : (props.tag === "ImgPortrait") &&
          <RecorderModal
            tag={props.tag}
            type={"image"}
            buttonLabelRecord={"Bild aufnehmen"}
            //buttonLabelRetry={"Erneut aufnehmen"}
            headerLabel={props.label}
            help={props.help}
            required={props.required}
            isValid={props.isValid}
            onSaveRecording={handleInputChange}
            >
            <strong>Nimm jetzt <span className="text-miuvi">dein Profilbild</span> auf</strong>
          </RecorderModal>
        } */}
        {
          // !props.readOnly && //TODO when preview is added
            <ValidationCheck
              criteria={props.isValid.valid} //{typeof props.value !== "undefined"}
              messages={props.isValid.messages}
              >
            </ValidationCheck>
        }
      </div>
  );
}

export default FileInput;
