import React, { Fragment, useState } from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  CardText,
  Card,
  CardDeck,
  CardTitle,
  Collapse,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import ExampleVideo from './videoForm/ExampleVideo';

const AboutPage = (props) => {

  return (
    <Fragment>


        <Container fluid className="pt-5 ">
          <Row className="align-items-center pt-3 mb-5">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col sm="2"> </Col>
            <Col sm="8" className=" pb-3 px-3 mb-5 text-center">
              <h1 className="text-blue"><strong>
              <span className="text-primary">miuvi</span> mag es bunt, frech <span className="text-pink">&</span><br />
              liebt <span className="text-light bg-miuvi px-2 rounded">Videos</span> über alles</strong></h1>
            </Col>
            <Col sm="2"></Col>
          </Row>
          <Row>
            <Col sm="3" className="">
            </Col>
            <Col sm="6" className="text-blue px-3 text-center">
              <h4 ><strong><span className=" text-miuvi">miuvi</span></strong> gibt Menschen die Möglichkeit, sich <span className="text-pink">& </span> ihre Firmen fesslend in Videos zu präsentieren. 
                Dafür gestalten wir vordefinierte Konzepte, die die Erstellung der Videos so einfach wie möglich macht - 
                 <br/>auch für Sie. </h4>
            </Col>
            <Col sm="3"></Col>
          </Row>
          <Row>
            <Col xs={{size: 12,}} md={{size: 12}} className="mx-auto my-auto" >
                  {/* <video playsInline preload="metadata" autoPlay muted loop className="img-fluid mx-auto d-block">
                    <source src={'smart.m4v'} type="video/mp4" />
                  </video> */}
                  
                  <img src={'videos.svg'} alt="content" className="img-fluid mx-auto d-block " />
                  {/* <div className="text-center">
                      <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                      <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                      <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
                  </div> */}
            </Col>
          </Row>
        </Container>


        <Container className=" pb-5 text-light">
          <Row className=" my-5 py-5 px-1 text-blue text-right">
            <Col sm="6"></Col>
            <Col sm="5">
              <img height="100rem" src="/artist_blue.svg" className="m-4  "></img>
              <h3><strong><span className=" bg-miuvi text-blue rounded py-1 px-2">Der Name:</span></strong></h3>
                <p><h5 className="pt-3 "><strong><span className="text-blue px-2">Eine bezaubernde Französin </span></strong><br/>
                <strong><span  className=""> mit charmantem Dialekt sagte „Movie" </span></strong><br/>
                <strong><span className="">und aus ihrem Munde erklingte „miuvi“</span></strong></h5></p>
                <p><em className="text-miuvi px-2"><h5>Oder auch die Kurzform von: <br/>"<strong>in minutes Videos</strong> - miuvi"</h5></em></p>
            </Col>
            <Col sm="2"></Col>
          </Row>
          <Row className="my-5 py-5 rounded-xxl bg-blue ">
            <Col xs="12" md="8" lg="4" className="px-5 py-3 mx-3 text-yellow text-uppercase">
              <h1><strong>Nutzen Sie die Kraft von Videos</strong></h1>
            </Col>
            <Col xs="12" md="8" lg="7" className="px-5 py-3 text-light ">
              <h5><strong>Videos informieren, unterhalten, begeistern & verbinden Menschen miteinander</strong><br/><br/>
              Mit dieser Überzeugung sind wir 2019 als Start-Up im eigenen Wohnzimmer gestartet. Wir wussten, die Welt hat gute Recruiting-Videos nötig - dann kam Corona und nun braucht die Welt wirklich gute Videos...<br/><br/>
              Genau da kommen wir ins Spiel. Wir haben Tage und Nächte viel nachgedacht, getüftelt und entwickelt. Dabei ist eine phänomenale Lösung entstanden.<br/><br/>
              Ein Tool, das genutzt werden kann um Videos schnell einfach & online zu erstellen. Jeder der es bedient erlebt schnelle Erfolge und fühlt sich wie ein echter Videodesigner.<br/><br/>
                  <strong><span className="text-yellow">Unser Leitsatz: "Videoserstellung ist unser Herzblut, Kundenzufriedenheit unser Antrieb & Kundenerfolg unsere tägliche Motivation."</span></strong></h5>
            </Col>  
          </Row>
          <Row className= "my-5 py-5 px-1">
            <Col sm="1">
            </Col>
            <Col sm="5">
              <img height="100rem" src="/globe.svg" className="m-3"></img>
              <h3><strong><span className="text-blue rounded bg-pink py-1 px-2">Die Mission:</span></strong></h3>
              <p><h5 className="pt-3 "><strong><span className="text-blue ">Digitalisierung von<br />bewegenden Persönlichkeiten</span></strong></h5></p>
            </Col>
            <Col sm="6">
            </Col>
          </Row>

          <Row className="text-right my-5 py-5 px-1 mb-md-5 pb-5">
            <Col sm="6"></Col>
            <Col sm="4">
              <img height="100rem" src="/galaxy.svg" className="m-3"></img>
              <h3><strong><span className="text-blue rounded bg-yellow py-1 px-2">Die Vision:</span></strong></h3>
                <p><h5 className="pt-3 "><strong><span className="text-blue px-2 ">Ein virtueller Kosmos zur</span>
                  <br/><span className="text-blue  ">Gestaltung & Visualisierung </span>
                  <br/><span className="text-blue ">von Ideen & Wünschen</span></strong></h5>
                </p>
            </Col>
            <Col sm="2"></Col>
          </Row>



      <Container className="rounded-xxl text-blue bg-lightmiuvi my-5">
        <Row className="mt-3 mb-5 mt-xl-5 pt-3 pt-lg-5">
            {/* <Col sm="1" /> */}
            <Col lg="4" className="pt-3 px-3 pt-xl-5">
              <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                <h2 className="text-blue"><strong>Wir <span className="rounded bg-blue px-2 pb-1 text-yellow"> lieben</span> Videos</strong></h2>
                <p className="lead mt-4">
                <strong></strong>Wir analysieren, konzipieren, designen, strukturieren & animieren Videos - bis sie uns gefallen</p>
                <p className="lead mt-4 pt-3">
                  {/*TODO*/}
                    <Button tag={Link} to={`/contact`} color="" className="bg-blue text-yellow rounded-xxl py-3 px-4 mr-3">
                      Kontakt
                    </Button>
                </p>
              </Jumbotron>
            </Col>
            <Col lg="8" className="d-flex align-items-end">
              <img src="recruiting.svg" className="img-fluid mx-auto d-block" />
            </Col>
        </Row>
      </Container>

     <Container fluid className="py-1 py-md-5 text-blue">
          <Row id="flo" >
            <Col className="text-center">
            </Col>
          </Row>
          <Row className="pt-5 mt-5">
            <Col xs={{size: 7, offset: 1, order: 2}} md={{size: 5, offset: 2, order: 2}} lg={{size: 3, offset: 0, order: 2}} className=" mt-3 mx-4 py-3 my-auto mx-auto text-blue text-center">
              <img src={'/flo_2.png'} style={{opacity: "0."}} alt="content" className= " img-fluid mx-auto d-block p-2 rounded-xxl" />
            </Col>
            <Col xs={{size: 8, offset: 2, order: 3}} md={{size: 5, offset: 1, order: 3}} lg={{size: 3, offset: 1, order: 1}} className="my-auto">
            <img height="75rem" src="/motto_yellow.svg" className="m-3"></img>
              <h3 classname=""><strong><span className=" px-1 text-blue">Motto:</span></strong></h3>
              <blockquote className="blockquote"><h2><span className="bg-yellow py-1 rounded text-blue">Go with the Flo(w)</span></h2></blockquote>
            </Col>
            <Col  xs={{size: 10, offset: 2, order: 1}} md={{size: 7, offset: 4, order: 1}} lg={{size: 4, offset: 0, order: 3}} className="my-auto text-left rounded-xxl ">
            <h2 className="text-left"><strong><span className="rounded px-2 py-1 bg-miuvi text-light"> Florian Herrmann </span></strong> </h2>
            <h3 className="text-left"><strong><span className="  rounded px-1 py-1 text-blue">Entrepreneur</span></strong></h3>
              <h4><p className="pt-3"> Ich liebe Projekte, <br/>bin spontan & surfe gerne <br/>die Wellen des "Start-Up-Lifes" </p></h4>
            </Col>
          </Row>
        </Container>

        <Container fluid className=" mt-5 pt-5 mx-auto py-1">
          <Row xs="" className="text-center mx-auto mt-5 pt-5 text-"> 
            <Col xs="1" ></Col>
            <Col sm="10" className="text-center mx-auto text-blue" >
              <h2 className="text-yellow pb-3" ><strong><span className=" bg-pink text-blue rounded px-2 ">Werte</span></strong></h2>
              {/*  <h4>So wie unsere Video-konzepte funktionieren, so funktionieren auch wir:</h4>*/}
            </Col>
            <Col xs="1" ></Col>
          </Row>
          <CoolVerticalValues /> 
        </Container>
          <Row className=" text-center mt-5 py-4 pb-3">
            <Col className="pt-5 mt-5">
           <h1 ><strong><span className="text-center px-2 bg-miuvi rounded text-blue" >Wie alles begann...</span></strong></h1> 
            </Col>
          </Row>
          <Row className="mb-5">  
            <Col xs={{size: 12, offset: 0, order:2}} lg={{size: 7, offset: 0, order: 1 }} lg="5" className=" my-auto p-5 bg-lightyellow text-center text-blue">
              <h5 className=" px-3"> <strong>...Inspiriert durch den Wunsch in einer Videofirma zu arbeiten,
              kam die naheliegende Idee, hierfür ein herausstechendes Video zu produzieren. Es kam super gut an & nun, eine gute Zeit später, entstand daraus diese Firma.
              </strong></h5><h5 className="text-miuvi px-3"> <strong>Was ist Ihre Inspiration?
              </strong></h5>
            </Col>  
            <Col xs={{size: 12, offset: 0, order:1}} lg={{size: 7, offset: 0, order: 2 }} className="p-0">
              <video src={`/bewerbung_flo.mp4`} playsInline preload="metadata" controls className="img-fluid d-block border-0" />
            </Col>
          </Row>
      </Container>

      <Container className="pb-5 mb-5">
          <Row className="align-items-center pt-3">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col className="pt-5 mt-5 pb-3 text-dkshade text-center">
              <h1 className="text-blue"><strong>Erstellen Sie Ihre Videos<br/> innerhalb von <span className="bg-pink text-light rounded pb-2 px-2">wenigen</span> Minuten</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mb-3">
              <Button tag={Link} to="/contact" color="" className="bg-primary text-light rounded-xxl mt-4  py-3 px-4">
                Kontakt
              </Button>
            </Col>
          </Row>
      </Container>

    </Fragment>
  );
}

const CoolVerticalValues = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const FAQCard = (props) => {
    return(
      <Card style={{cursor: "pointer"}} className="card-wider border-0 bg-blue mb-3 ">
        <CardTitle tag={"h3"} className="text-miuvi bg-transparent m-0 p-3 text-center rounded" >
          {props.label}
        </CardTitle>
        <Collapse isOpen={props.isOpen}>
          <CardBody className="text-center text-light bg-transparent rounded-">
            {props.children}
          </CardBody>
        </Collapse>
      </Card>
    );
  }

  return(    
    <Row onClick={toggle} className="mb-lg-5">
      <Col xs="1"></Col>
      <Col xs="10">
        <CardDeck className="py-2 d-flexjustify-content-center">
          <FAQCard label="Schnell"
                    isOpen={isOpen}>
              <CardText>Wir sind dynamisch, passen uns Veränderungen schnell an & lieben die direkte Umsetzung</CardText>
          </FAQCard>
          <FAQCard label="Einfach"
                    isOpen={isOpen}>
              <CardText>Einfachheit ist unser denken. Unser Ziel ist es, Produkte, Prozesse & Abläufe so simpel wie nur möglich zu gestalten  </CardText>
          </FAQCard>
          <FAQCard label="Online"
                    isOpen={isOpen}>
              <CardText>Unser Open-Mindset ist online, wir connecten einander, sind bewusst & mit Freude bei der Sache.  </CardText>
          </FAQCard>
        </CardDeck>
        <i style={{cursor: "pointer"}} className={`d-flex text-primary justify-content-center fas fa-3x fa-angle-${isOpen ? "up" : "down"}`}></i>
      </Col>
      <Col xs="1"></Col>
    </Row>
  );
}

export default AboutPage;