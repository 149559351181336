import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { 
    Button,
    Modal, 
    ModalBody, 
    Form, 
    Input, 
    FormGroup, 
    Label, 
    ModalFooter, 
    ModalHeader } from 'reactstrap';
    
import ValidationCheck from '../videoForm/inputs/ValidationCheck';

const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

/* props
    isAuthenticated
    onAuthChange
*/
const LogoutButton = (props) => {
    let history = useHistory();
    
    function handleAuthenticationToggle() {
        window.sessionStorage.removeItem('mv_usr'); 
        props.onAuthChange(false);
        console.log("Signed out");
        // window.location.reload();
        history.push('/');
    }

    return(
        props.isAuthenticated &&
            <span onClick={handleAuthenticationToggle}>Abmelden</span>
    );
}

export default LogoutButton;

