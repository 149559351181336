import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // NavbarText,
  Container,
  Row,
  Button,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap';
import { HashLink, NavHashLink } from 'react-router-hash-link';

import AuthenticationModal from './authentication/AuthenticationModal';
import RegistrationModal from './authentication/RegistrationModal';
import UserDropdown from './authentication/UserDropdown';
import { Fragment } from 'react';
import CalendlyPopupButton from './booking/CalendlyPopupButton';

const Header = (props) => {
  const [isTogglerOpen, setIsTogglerOpen] = useState(false);
  const [userData, setUserData] = useState({});

  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);


  const handleTogglerToggle = () => setIsTogglerOpen(!isTogglerOpen);
  const handleTogglerClose = () => setIsTogglerOpen(false);

  const productDropdownToggle = () => setProductDropdownOpen(prevState => !prevState);
  const serviceDropdownToggle = () => setServiceDropdownOpen(prevState => !prevState);

  function handleAuthChange(authenticated) {
    props.onAuthChange(authenticated);
  }

  function handleFetchUserData(receivedData){
    if(receivedData)
    setUserData(receivedData);
    props.onUserData(receivedData);
  }

// '/miuVi_Logo_col2.svg'

  return (
    <Navbar fixed="top" className="bg-white" light expand="lg">
      <NavbarBrand tag={Link} to="/" onClick={handleTogglerClose} >
        <Container>
          <Row>
            <img src={'/logo_white.svg'} className="d-inlineblock" style={{height: "2rem"}} alt="logo" />
            <h3 className="d-inline-block ml-2 my-auto text-blue">miu<span className="text-miuvi">vi</span></h3>
            <p className="d-inline-block ml-2 my-auto text-blue">
              <code style={{fontSize: "0.75rem"}}>α</code>
            </p>
          </Row>
        </Container>
      </NavbarBrand>
      <NavbarToggler onClick={handleTogglerToggle}  className="order-3 align-self-end" />
      {(props.isAuthenticated) &&
        <div className="ml-auto d-flex flex-row order-2 order-lg-3">
          <Nav navbar className="px-2 flex-row">
            <NavItem>
              <UserDropdown isAuthenticated={props.isAuthenticated} onAuthChange={handleAuthChange} userData={userData} onFetchUserData={handleFetchUserData}/>
            </NavItem>
          </Nav>
        </div>
      }
      <Collapse isOpen={isTogglerOpen} navbar className=" order-3 order-lg-2">
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink tag={HashLink} to="/#offer" className="text-dkshade" onClick={handleTogglerClose} >Angebot</NavLink>
          </NavItem>

          {/* <Dropdown isOpen={productDropdownOpen} nav inNavbar toggle={productDropdownToggle} >
            <DropdownToggle caret className="bg-transparent border-0 text-dkshade pl-0" >
              Produkte
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag={Link} to="/recruiting-video" className="text-dkshade" onClick={handleTogglerClose} >
                <NavLink>Recruitingvideo</NavLink>
              </DropdownItem>
              <DropdownItem tag={Link} to="/video-cv" className="text-dkshade" onClick={handleTogglerClose} >
                <NavLink>Bewerbungsvideo</NavLink>
              </DropdownItem>
              <DropdownItem tag={Link} to="/employee-pitch" className="text-dkshade" onClick={handleTogglerClose} >
                <NavLink>Mitarbeiterpitch</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
          <NavItem>
            <NavLink tag={Link} to="/tutorials" onClick={handleTogglerClose} className="text-dkshade" >Tutorials</NavLink>
          </NavItem>{"  "}
          <NavItem>
            <NavLink tag={Link} to="/training" onClick={handleTogglerClose} className="text-dkshade" >Training</NavLink>
          </NavItem>{"  "}
          <NavItem>
            <NavLink tag={Link} to="/about" className="text-dkshade" onClick={handleTogglerClose} >About</NavLink>
          </NavItem>{"  "}
          <NavItem>
            <NavLink tag={Link} to="/contact" onClick={handleTogglerClose} className="text-dkshade" >Kontakt</NavLink>
          </NavItem>{"  "}
          {/*
            <Dropdown isOpen={serviceDropdownOpen} nav inNavbar toggle={serviceDropdownToggle} >
              <DropdownToggle caret className="bg-transparent border-0 text-dkshade border-xxl pl-0" >
                Know-How
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/tutorials" className="text-dkshade" onClick={handleTogglerClose} >
                  <NavLink>Tutorials</NavLink>
                </DropdownItem>
                <DropdownItem tag={Link} to="/training" className="text-dkshade" onClick={handleTogglerClose} >
                  <NavLink>Videoaufnahmen</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>{"  "}
          */}
          {(props.isAuthenticated) &&
            <NavItem>
              <NavLink tag={Link} to="/create" className="text-primary rounded-xxl" onClick={handleTogglerClose} >Video erstellen</NavLink>
            </NavItem>
          }
          {(!props.isAuthenticated) &&
            <Fragment>
              {/* <NavItem>
              </NavItem> */}
              <NavItem onClick={handleTogglerClose}>
                <div style={{display: "inline"}} className="mr-2">
                  <AuthenticationModal isAuthenticated={props.isAuthenticated} onAuthChange={handleAuthChange}/>
                </div>
                <div style={{display: "inline"}}>
                  {/* <RegistrationModal /> */}
                  {/* BELOW VERY PROVISORIC - TODO switch with reg-&-db branch */}
                    {/* <Button tag={Link} to={`/contact`} className="text-light rounded-xxl border-0 bg-miuvi">
                      Registrieren
                    </Button> */}
                    <CalendlyPopupButton url="https://calendly.com/miuvi/30min" className="text-light rounded-xxl border-0 bg-miuvi">
                      Produkt-Demo
                    </CalendlyPopupButton>
                </div>
              </NavItem>
            </Fragment>
          }
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header;

// <Navbar fixed="top">

