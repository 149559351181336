import React, { useState, useRef, useEffect } from 'react';
import { Col, Container, Input, Row, Tooltip } from 'reactstrap';
import HelpBadge from '../HelpBadge';

import ValidationCheck from '../ValidationCheck';

import './MusicSelection.css';

const apiUrl = process.env.REACT_APP_API_URL;

/* props
    tag
    type
    label
    value
    help
    options
    required
    isValid
    onInputChange
    onValidationChange
*/
const MusicSelection = (props) => {
  const [tracks, setTracks] = useState([]);
  const [hovered, setHovered] = useState("");

  useEffect(() => {
    setTracks((prevState) => {
      for (let i = 0; i < props.options.length; i++) {
        prevState.push(new Audio(`${apiUrl}/file/footage-audio/${props.options[i].trackId}`));
      }
      return(prevState);
    })
  }, []);

  function handleInputChange(event, value) {  
    const inputName = event.target.name;
    props.onInputChange(inputName, value);
    props.onValidationChange(inputName, (!props.required || (value !== "" && typeof value !== "undefined")) ? 
      {valid: true, messages: []}
      :
      {valid: false, messages: ["Bitte auswählen"]}
    ); //TODO make criteria submittable as prop - mby as function
  }

  function playTrack(index){
    setHovered(index);
    tracks[index].play();
  }
  
  function stopTrack(index){
    setHovered("");
    tracks[index].pause();
    tracks[index].currentTime = 0;
  }

  return (
      <Container className="">
        <label
          htmlFor={props.tag}
          id={props.tag + "Label"}
          >
          {(props.required ? "*" : "") + props.label}
        </label>
        <HelpBadge id={props.tag + "Help"} text={props.help} />

        <Row className="justify-content-center musicRadios">
          {props.options.map((option, index) => {
              return(
                <Col xs="6" sm="4" md="3" lg="2" className={`${(props.value === option.trackId) && "border border-primary rounded shadow-sm"}`}>
                  <Input type="radio" className="form-control" style={{display: "none"}} name={props.tag} value={index} id={`rMusic${index}`} required={props.required} 
                    onClick={(event) => handleInputChange(event, option.trackId)} />
                  <label style={{cursor: "pointer"}} className={`mb-1 radio-music text-primary ${(hovered === index) && "hovered"}`} onMouseEnter={() => playTrack(index)} onMouseLeave={() => stopTrack(index)} htmlFor={`rMusic${index}`}>
                    <i className={`fas fa-music fa-5x`}></i>
                  </label>
                  <p className="text-center">{option.trackLabel}{"  "}
                  {(hovered === index) && 
                    <i className={`fas fa-volume-up text-primary hovered`}></i>
                  }
                  </p>
                </Col>
              );
            })
          }
        </Row>

          
        {
          props.required &&
          <ValidationCheck
            criteria={props.isValid.valid} //{typeof props.value !== "undefined"}
            messages={props.isValid.messages}
          />
        }
      </Container>
  );
}

export default MusicSelection;