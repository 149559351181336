import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Fade,
    Input,
    Label,
    InputGroup,
    Table,
  } from 'reactstrap';
import Admin_RegistrationForm from './authentication/admin/Admin_RegistrationForm';

  const axios = require('axios');
  const API_URL = process.env.REACT_APP_API_URL;

const DashboardAdmin = (props) => {
    // {name: "Testo Testino", email: "testo@testmail.ts", subject: "Testing", text: "Dies ist ein Test.", date: Date.now(), read: true}
    const [messages, setMessages] = useState([]);

    const {register, handleSubmit} = useForm();

    // useEffect(() => {
    //     handleRequestMessages({entriesPP: 20, page: 1});
    // }, [])

    function handleRequestMessages(data) {
        const token = window.sessionStorage.getItem('mv_usr');
        if(token) {
            axios.get(
            `${API_URL}/checkmessages/${data.entriesPP}/${1}`,
            {headers: { Authorization: `Bearer ${token}` }})
            .then(res => {
                console.log(res.status + " " + res.statusText);
                console.log(res.data);

                setMessages(res.data.messages.sort((a, b) => {
                    if(a.date < b.date) return 1;
                    if(a.date > b.date) return -1;
                    return 0;
                }));
            })
            .catch((err) => {
            // console.log(err);
                console.log(err.message);
                console.log(err.response.status);
            });
        } else {
            console.log("Err: token missing");
        }
    }

    // function handleRead(date, email) {               //TODO
    //     const params = new URLSearchParams();
    //     params.append("date", date);
    //     params.append("email", email);
    //     const token = window.sessionStorage.getItem('mv_usr');
    //     if(token) {
    //         axios.patch(
    //         `${API_URL}/openmessage`,
    //         params,
    //         {headers: { Authorization: `Bearer ${token}` }})
    //         .then(res => {
    //             console.log(res.status + " " + res.statusText);
    //             console.log(res.data);

                
    //             setMessages(res.data.messages.sort((a, b) => {
    //                 if(a.date < b.date) return 1;
    //                 if(a.date > b.date) return -1;
    //                 return 0;
    //             }));
    //         })
    //         .catch((err) => {
    //         // console.log(err);
    //             console.log(err.message);
    //             console.log(err.response.status);
    //         });
    //     } else {
    //         console.log("Err: token missing");
    //     }
    // }

  return (
    <Container fluid className="bg-primary">

      <Container className="py-5" >
          <Fade in={true}>
        <Row className="pt-5">
          <h1 className="text-light mb-3">Dashboard <code>[admin]</code></h1>
        </Row>
        <Row>
            {/* action={`http://localhost:3001/formsubmit/${props.templateId}`} method="post" target="_blank" enctype="multipart/form-data" */}
            <Col className="rounded shadow bg-dkshade text-light p-5">
                <Row className="pb-3">
                  <h3 className="text-light">Nachrichten</h3>
                </Row>
                <Row className="pb-3">
                    <Col>
                        <Form inline onSubmit={handleSubmit(handleRequestMessages)} >
                            <Button color="primary" type="submit" className="mr-2" >
                                Nachrichten laden
                            </Button>
                            <Label htmlFor="entriesPP" className="mr-1">Anzahl: </Label> {/* //TODO */}
                            <Input id="entriesPP" name="entriesPP" type="number" className="bg-transparent border-primary text-light" defaultValue="20" min="1" bsSize="sm" innerRef={register}></Input>
                        </Form>
                    </Col>
                {/*<Button type="button" onClick={() => console.log(triedSubmit)}>_debug</Button>*/}
                </Row>
                <Row>
                    <Col>
                        {(typeof messages !== 'undefined' && messages.length > 0) &&
                            <Table bordered responsive className="rounded text-light">
                                <thead className="text-primary">
                                    <tr>
                                        <th>Absender</th>
                                        {/* <th>Name</th>
                                        <th>E-Mail</th>
                                        <th>Betreff</th> */}
                                        <th>Nachricht</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {messages.map((message, i) => {
                                        return (
                                            <tr key={message.date.toString() + message.email} className={`${message.read && "text-liaccent"}`}>
                                                <td>
                                                    <strong>{message.name}</strong><br/>
                                                    <a href={`mailto:${message.email}?subject=Re: ${message.subject}&body=Anfrage: ${message.text}`} className={`text-primary`}>{message.email}</a><br/>
                                                    <small>{new Date(message.date).toLocaleTimeString("de", { year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</small><br/>
                                                </td>
                                                <td>
                                                    <strong>{message.subject}</strong><br/>
                                                    <p>"{message.text}"</p>
                                                </td>
                                                {/* <td> //TODO see above (handleRead)
                                                    <i onClick={() => handleRead(message.date, message.email)} className={`text-primary far ${message.read ? "fa-envelope-open" : "fa-envelope"}`}></i>
                                                </td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>
                <Row className="pb-3">
                  <h3 className="text-light">User anlegen</h3>
                </Row>
                <Row>
                    <Admin_RegistrationForm />
                </Row>
              {/*isSubmitted && <ProgressChecker apiUrl={props.apiUrl} jobId={jobId} interval={5000} info={renderInfo} onProgressChange={handleProgressChange} />*/}
            </Col>
        </Row>
      
        </Fade>
    </Container>
    </Container>   
  );
}

export default DashboardAdmin;