import React, { Fragment, useState } from 'react';
import { Container, Row, Col, CardDeck, Alert } from 'reactstrap';
import InfoMessage from './notification/InfoMessage';

import TemplateCard from './TemplateCard';

/* props
  isAuthenticated
  userData
*/
const TemplatePage = (props) => {
    const [hoveredCard, setHoveredCard] = useState(null);

    function handleCardHoverStart(id) {
        setHoveredCard(id);
    }

    function handleCardHoverEnd(id) {
        setHoveredCard(null);
    }

    if(props.isAuthenticated) {
      if(!props.userData.leftVideos || props.userData.leftVideos > 0) {
          if(props.userData.templateIDs) {
            return (
                <Container fluid className="bg-primary">
                    <Row className="pt-5">
                      <Col>
                        <h2 className="text-blue text-center">Wähle eine Template</h2>
                      </Col>
                    </Row>
                    <CardDeck className="text-center text-light py-5 d-flex justify-content-center">
                        {
                            props.userData.templateIDs.map(templateID => {
                                return(
                                  <TemplateCard 
                                    key={templateID}
                                    templateID={templateID}
                                    hovered={(templateID === hoveredCard)}
                                    onCardHoverStart={handleCardHoverStart}
                                    onCardHoverEnd={handleCardHoverEnd} />
                                ); 
                            })
                        }
                    </CardDeck>
                </Container>
            );
          } else {
              return <Fragment />
          }
      } else {
        return <InfoMessage log={props.userData.leftVideos} className="text-light" />
      }
    } else //TODO useState height when rescaling browser window
      return (
        <Container>
            <Row className="justify-content-center my-5 py-5">
                <Alert color="warning">Bitte melde dich an um ein Video zu erstellen</Alert>
            </Row>
        </Container>
      );
  }

export default TemplatePage;