import React, { Fragment } from 'react';

/* props
- children -
    active
    criteria
*/
//:O
const ValidationCheck = (props) => {
    return(
        <Fragment>
        {
            (!props.criteria) &&
              <div className="text-danger"> {/*invalid-feedback //default bootstrap way to validate form*/}
                {
                  (props.messages != undefined) ?
                    props.messages.map((message, index) => {
                      return <span key={Date.now() + index}>{message}</span>
                    })
                    :
                    <span>undefined</span>
                }
              {}
              </div>
        }
        </Fragment>
    );
}

export default ValidationCheck;