import React, { useState, useEffect, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams
} from "react-router-dom";

import ReactPixel from 'react-facebook-pixel';

import useInterval from 'use-interval'

import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import VideoForm from './components/videoForm/VideoForm';
import InfoMessage from './components/notification/InfoMessage';
import ExampleVideo from './components/videoForm/ExampleVideo';
import VideoCVPage from './components/VideoCVPage';
import RecruitingVideoPage from './components/RecruitingVideoPage';
import EmployeePitchPage from './components/EmployeePitchPage';
import TutorialPage from './components/TutorialPage';
import TemplatePage from './components/TemplatePage';
import ScrollToTop from './components/routing/ScrollToTop';
import AboutPage from './components/AboutPage';
import TrainingPage from './components/TrainingPage';
import LandingPage from './components/LandingPage';
import DashboardAdmin from './components/DashboardAdmin';
import ContactPage from './components/ContactPage';

import { Alert } from 'reactstrap';

import CookieConsentModal from './components/legal/CookieConsentModal';
import PreviewPage from './components/PreviewPage';


const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

const pixelId = "595418058564488";


const App = () => {
  //TODO move following
  const [template, setTemplate]               = useState(""); // set to "" !
  const [templateChosen, setTemplateChosen]   = useState(true); // set to false ! - stil relevant??

  const [userData, setUserData]               = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  const [useIntervalRunning, setUseIntervalRunning] = useState(true);

  // let [count, setCount] = React.useState(0); //for testing useInterval()

  useInterval(() => {
    const ccCookie = getCookie("cc_cookie");
    // console.log("ä");
    if(ccCookie) {
      // console.log("y");
      if(JSON.parse(ccCookie).level.includes("analytics")) {
        ReactPixel.grantConsent();
        console.log("Pixel consent granted");
      }
      setUseIntervalRunning(false)
    } else {
      // console.log("n");
      ReactPixel.revokeConsent();
    }
    // setCount(count + 1);
    // console.log(count);
  }, useIntervalRunning ? 1000 : null);

  //Pixel
  // const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: false, // enable logs
  // };
  ReactPixel.init(pixelId/*, advancedMatching, options*/);
  ReactPixel.revokeConsent();
  ReactPixel.pageView();

  useEffect(() => {
    if(process.env.NODE_ENV === 'development')
      console.log("running in dev mode");
    // console.log("RECEIVED DATA: " + userData.leftVideos);

    // const savedLPT = localStorage.getItem('lpt');
    // switch(savedLPT) {
    //   case "business":
    //   case "private":
    //     setLandingPageType(savedLPT);
    //     break;
    //   default:
    //     console.log(":(", savedLPT);
    // }
    
    // if(getCookieConsentValue() === "true") {
    //   ReactPixel.grantConsent();
    //   console.log("Pixel consent granted");
    // }

    

    checkToken();
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const checkToken = () => {
    const token = window.sessionStorage.getItem('mv_usr');
    if(token) {
        axios.get(
          `${apiUrl}/test/token`,
          { headers: { Authorization: `Bearer ${token}` } })
        .then(result => {
          const isValid = (result.status === 200 || result.status === 304);
          console.log(result.status);
          if(isValid) {
            // console.log("Token valid");
            setIsAuthenticated(true);
          } else {
            console.log("Token invalid");
            setIsAuthenticated(false);
          }  
        })
        .catch(err => {
          // console.log("Token invalid");
          setIsAuthenticated(false);
        });
    } else {
      console.log("No token: user not signed in");
      setIsAuthenticated(false);
    }
  }

  function handleUserData(userData){
    setUserData(userData);
  }

  function handleInputChange(event) {
    setTemplate(event.target.value);
  }

  function handleButtonClick(event) {
    setTemplateChosen(true);
  }

  function handleTestAuthButtonClick() {
    checkToken();
  }

  function handleAuthChange(authenticated) {
    // console.log(authenticated);
    setIsAuthenticated(authenticated);
    // if(!isAuthenticated && authenticated) {
    //   // console.log("DEbug is life, debuG is Love");
    //   // return <Redirect to={`/create`} />
    // }
  }

  //bg-lishade


  const SwitchInfo = () => { // TODO this wouldn't be needed if the checks happened in VideoForm
    const paramTemplateId = useParams().id;

    if(isAuthenticated) {
      if(!userData.leftVideos || userData.leftVideos > 0) {
        if (!templateChosen){
          return <Fragment>
          <label htmlFor="#templateInput"> Template id: </label>
          <input type="text" id="templateInput" value={template} onChange={handleInputChange} />
          <button type="button" onClick={handleButtonClick}> Auswählen </button>{"  "}
          {/* <button type="button" onClick={handleTestAuthButtonClick}> test_auth </button> */}
        </Fragment>
        }
        else {
          if (userData.leftVideos <= 0){
            return <InfoMessage log={userData.leftVideos}/>
          }
          return (
            <VideoForm
              templateId={paramTemplateId/*template*/}
              apiUrl={apiUrl}
              preferedTargetMail={userData.email} //PROVISORIC
              isAuthenticated={isAuthenticated}
              onAuthChange={handleAuthChange} />
          );
        }
      } else {
        return <InfoMessage log={userData.leftVideos}/>
      }
    } else
    return <Alert color="warning" className="text-center my-5 py-5">Bitte melde dich an um diesen Inhalt zu sehen</Alert>; /* <Redirect to="/" /> TODO rethink */
  }

  return(
    <Router>
      {/* bg-col-mi */}
      <div className="App p-0 m-0 theme-turquoise">
  	    <CookieConsentModal />
        <Header
          isAuthenticated={isAuthenticated}
          onAuthChange={handleAuthChange}
          onUserData={handleUserData}
          />
        {/* <div className="mt-1 pt-5 mb-2"></div> */}

        {/* <CookieConsent
          location="center"
          flipButtons={true}
          buttonText="Einverstanden"
          buttonClasses="rounded"
          enableDeclineButton
          declineButtonText="Ablehnen"
          declineButtonClasses="rounded"
          onAccept={() => {
            ReactPixel.grantConsent();
            console.log("Pixel consent granted");
          }}
          onDecline={() => {
            ReactPixel.revokeConsent();
            console.log("Pixel consent revoked");
          }}
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#33c5b6", color: "#000", fontSize: "1rem" }}
          declineButtonStyle={{ background: "transparent", border: "none", fontSize: ".8rem"}}
          expires={150}
        >
          Zur verbesserung unserer Dienste nutzen wir Cookies{" "}
        </CookieConsent> */}

        <ScrollToTop>
          <Switch>
            {/* <Route path="/video-cv">
              <VideoCVPage />
            </Route>
            <Route path="/recruiting-video">
              <RecruitingVideoPage />
            </Route>
            <Route path="/employee-pitch">
              <EmployeePitchPage />
            </Route> */}
            <Route path="/preview/:bucketId/:fileName">
              <PreviewPage />
            </Route>
            <Route path="/create/:id">
              <SwitchInfo/> 
            </Route>
            <Route path="/create">
              <TemplatePage
                isAuthenticated={isAuthenticated}
                userData={userData}
                />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/tutorials/:sectionName/:vidOrdinal">
              <TutorialPage />
            </Route>
            <Route path="/tutorials">
              <Redirect to="/tutorials/leandra/1" />
            </Route>
            <Route path="/training">
              <TrainingPage />
            </Route>
            <Route path="/dashboard">
              {(userData._id === "5f2b3ae3588e9e5c988e4dee" || //TODO CHANGE!! very bad!
                userData._id === "5fb577979af01c1994b14e95") ?
                <DashboardAdmin />
                :
                <Redirect to="/" />
              }
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </ScrollToTop>
        
        <Footer/>
      </div>      
    </Router>
  );
}



const Jumbo = () => {
  return(
    <div className="pt-5 text-center">
      <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
      <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
      <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
    </div>
  );
}



export default App;

//<header className="App-header p-3 shadow bg-dkshade">
