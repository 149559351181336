import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table,
	TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Pagination, PaginationItem, PaginationLink, ButtonGroup, Alert } from 'reactstrap';

import classnames from 'classnames';

const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

/* props
    modalIsOpen
	templateId
    onToggle
	onLoadSave
*/
const LoadPresetModal = (props) => {
    // const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);

		const [activeTab, setActiveTab] = useState('presets'); // presets|created

		const [entries, setEntries] = useState([]);

		const toggleTab = tab => {
			if(activeTab !== tab) setActiveTab(tab);
		}

		const [warningModalOpen, setWarningModalOpen] 	= useState(false);
		const [selectedEntry, setSelectedEntry] 		= useState();
	  
		const toggleWarningModalOpen = () => {
			setWarningModalOpen(!warningModalOpen);
		  }

		useEffect(() => {
			setWarningModalOpen(false);
			if(props.modalIsOpen) {
				handleRequestEntries(activeTab);
			}
		}, [activeTab, props.modalIsOpen])

		function handleRequestEntries(category) { //(data)
			if(props.templateId) {
				const token = window.sessionStorage.getItem('mv_usr');
				if(token) {
					axios.get(
					`${API_URL}/saves/list/${props.templateId}/${category}`, //${data.entriesPP}/${1}
					{headers: { Authorization: `Bearer ${token}` }})
					.then(res => {
						console.log(res.status + " " + res.statusText);
						// console.log(res.data);

						const resList = res.data.sort((a, b) => {
							if(a.date < b.date) return 1;
							if(a.date > b.date) return -1;
							return 0;
						})

						console.log(resList);
						setEntries(resList);
					})
					.catch((err) => {
						console.log(err);
						// console.log(err.message);
						// console.log(err.response.status);
					});
				} else {
					console.log("Err: token missing");
				}
			} else {
				console.log("Err: templateId not found");
			}
		}

		function handleRequestData(saveId) { //(data)
			if(saveId) {
				const token = window.sessionStorage.getItem('mv_usr');
				if(token) {
					axios.get(
					`${API_URL}/saves/${saveId}`, //${data.entriesPP}/${1} //? mby rather get them by id
					{headers: { Authorization: `Bearer ${token}` }})
					.then(res => {
						console.log(res.status + " " + res.statusText);
						console.log(res.data);

						props.onLoadSave(res.data);
						props.onToggle();
					})
					.catch((err) => {
						console.log(err);
						// console.log(err.message);
						// console.log(err.response.status);
					});
				} else {
					console.log("Err: token missing");
				}
			} else {
				console.log("Err: saveId not found");
			}
		}

    return (
        <Fragment>      
            <Modal isOpen={props.modalIsOpen} onToggle={props.onToggle} className={""} size="xl" centered scrollable >
                <ModalHeader onToggle={props.onToggle} toggle={props.onToggle} className="">
					Gespeicherte <span className="text-primary">Vorlage laden</span> {/* {`${API_URL}/saves/${entries._id}`} */}
                </ModalHeader>
                <ModalBody>

					<Nav tabs>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === 'presets' })}
								onClick={() => { toggleTab('presets'); }}
							>
								Entwürfe
							</NavLink>
						</NavItem>
						{/* <NavItem>
							<NavLink //disabled
								className={classnames({ active: activeTab === 'created' })}
								onClick={() => { toggleTab('created'); }}
							>
								Erstellt
							</NavLink>
						</NavItem> */}
					</Nav>
					
					<TabContent activeTab={activeTab}>
						<TabPane tabId="presets">
							<Row>
								<Col sm="12">
									<Table responsive className="rounded text-dkshade">
										{(entries.length > 0) &&
											<thead className="text-primary">
												<tr>
													<th>Name</th>
													<th>Erstellt am</th>
													<th></th>
												</tr>
											</thead>
										}
										<tbody>
											{entries.length > 0 ?
												entries.map((entry, i) => {
													return (
														<tr key={entry.date.toString() + entry.name} >
															<td>
																<strong>{entry.name}</strong><br/>
															</td>
															<td>
																<span>{new Date(entry.date).toLocaleTimeString("de", { year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</span><br/>
															</td>
															<td className="d-flex justify-content-center">
																<Button color="primary" onClick={() => {setSelectedEntry(entry); toggleWarningModalOpen()}} className="text-light rounded-xxl">
																	<i className="far fa-folder-open"></i>{"  "}Laden
																</Button>
															</td>
															{/* <td>
																<Button color="transparent" onClick={() => handleRequestData(entry._id)} className="ml-auto">
																	<i className="fas fa-ellipsis-v"></i>
																</Button>
															</td> */}
														</tr>
													);
												})
											:
												<tr><td>
													<strong>Noch keine Einträge vorhanden</strong>
													<p>Fülle die Eingabefelder und erstelle ein Video am Ende dieser Seite um deine erste Vorlage zu speichern.<br />Sie wird in Zukunft hier für dich bereit stehen.</p>
												</td></tr>
											}
										</tbody>
									</Table>
								</Col>
							</Row>
						</TabPane>
						{/* <TabPane tabId="created" >
							<Row>
								<Col sm="12">
									<Table responsive className="rounded text-dkshade">
										<tbody>
											{entries.length > 0 ?
												entries.map((entry, i) => {
													return (
														<tr key={entry.date.toString() + entry.name} >
															<td>
																<strong>{entry.name}</strong><br/>
															</td>
															<td>
																<small>{new Date(entry.date).toLocaleTimeString("de", { year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</small><br/>
															</td>
															<td>
																<Button color="primary" onClick={() => handleRequestData(entry._id)} className="text-dkshade">
																	<i className="far fa-folder-open"></i>
																</Button>
															</td>
														</tr>
													);
												})
											:
												<tr><td>
													<strong>Noch keine Einträge vorhanden</strong>
													<p>Fülle die Eingabefelder und erstelle ein Video am Ende dieser Seite um deine erste Vorlage zu speichern.<br />Sie wird in Zukunft hier für dich bereit stehen.</p>
												</td></tr>
											}
										</tbody>
									</Table>
								</Col>
							</Row>
						</TabPane> */}
					</TabContent>
									
                </ModalBody>
                {/* <ModalFooter className="justify-content-start">
					<Pagination size="sm" aria-label="Pagination">
						<PaginationItem>
							<PaginationLink first className="text-primary" />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink previous className="text-primary" />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink className="text-primary">
								1
							</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink className="text-primary">
								2
							</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink className="text-primary">
								3
							</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink next className="text-primary" />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink last className="text-primary" />
						</PaginationItem>
					</Pagination>
                </ModalFooter> */}

				
				<Modal isOpen={warningModalOpen} onToggle={toggleWarningModalOpen} centered>
					<ModalHeader toggle={toggleWarningModalOpen}>Vorlage laden <span className="text-primary"></span></ModalHeader>
					<ModalBody className="text-center">
						<p>Lade <strong>{selectedEntry && selectedEntry.name}</strong></p>
						<Alert color="warning" >
							Achtung: Hierdurch werden alle <strong className="text-primary">Eingaben</strong> die du bisher im Tool gemacht hast überschrieben
						</Alert>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="text-light rounded-xxl" onClick={() => handleRequestData(selectedEntry._id)}>Laden</Button>
						<Button color="secondary" className="text-light rounded-xxl" onClick={toggleWarningModalOpen}>Abbrechen</Button>
					</ModalFooter>
				</Modal>
            </Modal>
        </Fragment>
    );
}

export default LoadPresetModal;