import React, {useState} from 'react';

import TextInput from './TextInput';
import InputBlock from './InputBlock';
import FileInput from './FileInput';
import ColorInput from './ColorInput';
import MusicSelection from './MusicSelection/MusicSelection';
import SelectionInput from './SelectionInput';
import { Fragment } from 'react';

/* props
    inputs
    inputValues
    areInputsValid
    templateId
    validationTrigger
    onInputChange
    onOptionalInfoChange
    onValidationChange
*/
const InputArea = (props) => {
  const [mainColor, setMainColor] = useState({
    tag: "color1",
    value: "#212B3A"});

  function handleColorInputChange(inputName, inputValue) {
    props.onInputChange(inputName, inputValue);
    setMainColor(prevState => {
      return({
        tag: prevState.tag,
        value: inputValue
      })
    });
  }

  function handleInputChange(inputName, inputValue, optionalInfo) {
    props.onInputChange(inputName, inputValue, optionalInfo);
  }

  function handleOptionalInfoChange(inputName, optionalInfo) {
    props.onOptionalInfoChange(inputName, optionalInfo)
  }

  function handleValidationChange(inputName, isValid) {    
    props.onValidationChange(inputName, isValid);
  }

  function renderInputBlocks(textInputStateObject, _depth = 0) {
    return (
      textInputStateObject.map((entry, i) => {
        if(typeof entry.groupLabel != "undefined" && typeof entry.groupContent != "undefined") {
          return (
            <InputBlock
              label={entry.groupLabel}
              depth={_depth}
              imgId={entry.groupExampleImgId}
              templateId={props.templateId}
              >
              { renderInputBlocks(entry.groupContent, _depth + 1) }
            </InputBlock>
          )
        }
        else {
          switch(entry.type) {
            case "text":
              return(
                (!entry.options) ? 
                <Fragment>
                  <TextInput
                    key={i + entry.tag}
                    tag={entry.tag}
                    type={entry.type}
                    label={entry.label}
                    placeholder={entry.placeholder}
                    value={props.inputValues[entry.tag]}
                    initialValue={entry.initialValue}
                    help={entry.help}
                    maxLength={entry.maxLength}
                    required={entry.required}
                    isValid={props.areInputsValid[entry.tag]}
                    validationTrigger={props.validationTrigger}
                    onInputChange={handleInputChange}
                    onValidationChange={handleValidationChange}
                    />
                </Fragment>
              :
                <SelectionInput
                  key={i + entry.tag}
                  tag={entry.tag}
                  type={entry.type}
                  label={entry.label}
                  placeholder={entry.placeholder}
                  value={props.inputValues[entry.tag]}
                  initialValue={entry.initialValue}
                  help={entry.help}
                  maxLength={entry.maxLength}
                  sizechangingcount={0}
                  options={entry.options}
                  hideRegular={entry.hideRegular}
                  required={entry.required}
                  isValid={props.areInputsValid[entry.tag]}
                  onInputChange={handleInputChange}
                  onValidationChange={handleValidationChange}
                  />
              )
              break;
            case "image":
              if(entry.options) {
                return(
                  <SelectionInput 
                    key={i + entry.tag}
                    tag={entry.tag}
                    type={entry.type}
                    label={entry.label}
                    value={props.inputValues[entry.tag]}
                    help={entry.help}
                    required={entry.required}
                    readOnly={entry.readOnly}
                    options={entry.options}
                    hideRegular={entry.hideRegular}
                    isValid={props.areInputsValid[entry.tag]}
                    onInputChange={handleInputChange}
                    onValidationChange={handleValidationChange}
                  />
                );
              } //..else fall through to video
            case "video":
              return(
                <FileInput 
                  key={i + entry.tag}
                  tag={entry.tag}
                  type={entry.type}
                  label={entry.label}
                  value={props.inputValues[entry.tag]}
                  help={entry.help}
                  required={entry.required}
                  isValid={props.areInputsValid[entry.tag]}
                  templateId={props.templateId}
                  onInputChange={handleInputChange}
                  onOptionalInfoChange={handleOptionalInfoChange}
                  onValidationChange={handleValidationChange}
                />
              )
              break;
            case "audio":
              return(
                <MusicSelection
                  key={i + entry.tag}
                  tag={entry.tag}
                  type={entry.type}
                  label={entry.label}
                  value={props.inputValues[entry.tag]}
                  help={entry.help}
                  options={entry.options}
                  required={entry.required}
                  isValid={props.areInputsValid[entry.tag]}
                  onInputChange={handleInputChange}
                  onValidationChange={handleValidationChange}
                />
              )
              break;
            case "color":
              return(
                <ColorInput
                  key={i + entry.tag}
                  tag={entry.tag}
                  type={entry.type}
                  name={entry.tag}
                  label={entry.label}
                  mainColor={mainColor}
                  value={props.inputValues[entry.tag]}
                  initialValue={entry.initialValue}
                  help={entry.help}
                  required={entry.required}
                  isValid={props.areInputsValid[entry.tag]}
                  onInputChange={handleColorInputChange}
                  onValidationChange={handleValidationChange}
                />
              )
            default:
              // code block
            }
        }
      })
    )
  }

  return renderInputBlocks(props.inputs);

}



export default InputArea;
