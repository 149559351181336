import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

const apiUrl = process.env.REACT_APP_API_URL;

/* props
    imgId
    // imgPath
*/
const ExampleImage = (props) => {
    const [modal, setModal] = useState(false); 
    // const [hover, setHover] = useState(false)

    const toggle = () => setModal(!modal);
    // const toggleHover = () => setHover(!hover);
    ///file/examplefile/2/example6.png
    return(
        <React.Fragment>
            <img src={`${apiUrl}/file/examplefile/${props.templateId}/${props.imgId}`} alt="Beispielbild nicht gefunden" onClick={toggle} className={"img-fluid rounded border"} />
            <Modal isOpen={modal} toggle={toggle} centered={true} size={"lg"}>
                <ModalBody className="p-0">
                    <img src={`${apiUrl}/file/examplefile/${props.templateId}/${props.imgId}`} alt="Beispielbild nicht gefunden" className={"img-fluid rounded border"} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default ExampleImage;