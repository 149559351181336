import React, { useState } from 'react';
import { Fragment } from 'react';
import { Form, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Collapse, Alert, Fade } from 'reactstrap';
import { useForm } from "react-hook-form";

const SavePresetModal = (props) => {
    // const {register, handleSubmit, errors, reset, trigger, getValues} = useForm();

  const {register, handleSubmit, errors, reset, trigger, getValues} = useForm();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const toggleModal = () => {
      setIsCollapseOpen(false);
      props.onToggle();
    }
  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

    function handleSaveSubmit(data) {
        props.onSave(data);
        console.log(data);
    }
    function handleDontSave() {
        props.onDontSave();
        toggleModal();
    }
    function handleSaveError(errors) {
        console.log(errors);
    }

    return(
        // <Form onSubmit={handleSubmit(handleContactSubmit, handleContactError)} >
        <Fragment>
            {/* <Button onClick={toggleModal}>
                test_save
            </Button> */}
            <Modal isOpen={props.modalIsOpen} toggle={toggleModal} centered >
                <Collapse isOpen={!isCollapseOpen}>
                    <ModalHeader toggle={toggleModal}>Eingaben als <span className="text-primary">Vorlage</span> speichern?</ModalHeader>
                    <ModalBody>
                        <p className="text-center">
                            Du kannst die (Text-) <strong className="text-primary">Eingaben</strong> die du für diese Video-Template gemacht hast <strong className="text-primary">als Entwurf</strong> speichern damit du für dein nächstes Video nicht alles erneut eingeben musst.
                        </p>
                    </ModalBody>
                    <ModalFooter className="justify-content-center"> {/*toggleModal(); props.handleSave()*/}
                        <Button color="primary" className="text-light rounded-xxl" onClick={toggleCollapse}>Eingaben speichern</Button>{' '}
                        <Button color="secondary" className="text-light rounded-xxl" onClick={handleDontSave}>Nicht speichern</Button>
                        <Button color="secondary" className="text-light rounded-xxl" onClick={toggleModal}>Abbrechen</Button>
                    </ModalFooter>
                </Collapse>
                <Collapse isOpen={isCollapseOpen}>
                    <Form onSubmit={handleSubmit(handleSaveSubmit, handleSaveError)}>
                        <ModalHeader toggle={toggleModal}><span className="text-primary">Erkenne</span> deine Vorlage schnell wieder</ModalHeader>
                        <ModalBody>
                            <Label htmlFor="saveName">Name der Vorlage:</Label>
                            <Input name="saveName" id="saveName" type="text" innerRef={register()} />
                            <small><em>Für automatische Benennung leer lassen</em></small>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                            <Button type="submit" color="primary" className="text-light rounded-xxl" onClick={toggleModal}>Speichern</Button>{' '}
                            <Button color="secondary" className="text-light rounded-xxl" onClick={toggleCollapse}>Zurück</Button>
                        </ModalFooter>
                    </Form>
                </Collapse>
            </Modal>
        </Fragment>
    );
}

export default SavePresetModal;