import React, { useEffect, useState } from 'react';

import { Input, InputGroup, Tooltip, Badge } from 'reactstrap';
import HelpBadge from './HelpBadge';
import InputResetButton from './InputResetButton';

import TextLengthIndicator from './TextLengthIndicator';
import ValidationCheck from './ValidationCheck';

const breakingLength = 50;

/* props
    type
    tag
    label
    value
    help
    maxLength
    required
    isValid
    validationTrigger
    onInputChange
    onValidationChange
*/
const TextInput = (props) => {

  useEffect(() => {
    validate(props.tag, props.value);
  }, [props.validationTrigger]);

  function validate(tag, newValue) {
    props.onValidationChange(tag, (!props.required || (newValue !== "" && typeof newValue !== "undefined")) ? //TODO make criteria submittable as prop - mby as function
      {valid: true, messages: []}
      :
      {valid: false, messages: ["Bitte ausfüllen"]}
    );
  }

  function handleInputChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    props.onInputChange(inputName, inputValue);
    validate(inputName, inputValue);
  }

//props.label !== "" ||
  return (
      <div className="my-3">
        { true &&
          <label
            htmlFor={props.tag}
            id={props.tag + "Label"}
            >
            {(props.required ? "* " : "") + props.label}
            {(!props.required) && <small className="text-secondary">{"  "}(optional)</small>}
          </label>
        }
        <HelpBadge id={props.tag + "Help"} text={props.help} />
        <InputGroup>
        {props.maxLength < breakingLength ?
          <Input
          type={props.type ? props.type : "text"}
          className="form-control bg-light"
          id={props.tag}
          name={props.tag}
          placeholder={props.placeholder}
          value={props.value}
          maxLength={props.maxLength}
          required={props.required}
          onChange={handleInputChange}
          />
          :
          <Input
            type={"textarea"}
            className="form-control bg-light"
            id={props.tag}
            name={props.tag}
            rows={Math.ceil(props.maxLength/(breakingLength*0.8))}
            style={{resize: "none"}}
            placeholder={props.placeholder}
            value={props.value}
            maxLength={props.maxLength}
            required={props.required}
            onChange={handleInputChange}
            />
          }
          {props.maxLength && 
            <TextLengthIndicator
            text={props.value}
            maxLength={props.maxLength}
            toleranceFactor={0.75}
            />
          }
        </InputGroup>
        <InputResetButton 
          inputName={props.tag}
          initialValue={props.initialValue}
          value={props.value}
          onInputChange={props.onInputChange}
          onValidationChange={props.onValidationChange}
          />
        {
          props.required && 
            <ValidationCheck
              criteria={props.isValid.valid} //{typeof props.value !== "undefined"}
              messages={props.isValid.messages}
            />
        }
      </div>
  );
}

export default TextInput;
