import React, { Fragment, useState } from "react";
import { Collapse, Fade } from "reactstrap";
import useTimeout from "../../hooks/useTimeout";

const DelayVisibility = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    console.log("Hello");

    useTimeout(() => {
        setIsVisible(() => true);
    }, props.delay)

    return (
        <Fragment>
        {/* {isVisible &&  */}
            <Collapse isOpen={isVisible}>
                {props.children}
            </Collapse>
        {/* } */}
        </Fragment>
    );
}

export default DelayVisibility;