import React from 'react';

/* props
    inputName
    initialValue
    value
    onInputChange
    onValidationChange
*/
const InputResetButton = (props) => {

    function handleValueReset () {
      props.onInputChange(props.inputName, props.initialValue);
      props.onValidationChange(props.inputName, {valid: true, messages: []}); // makes sense just setting to true?
    }
  
    if(props.initialValue && props.value != props.initialValue) {
      return(
				<small id="redoButton" style={{cursor: "pointer"}} onClick={handleValueReset} >
					<i className="fas fa-redo-alt"></i>
				</small>
      );
    } else 
			return null;
  }

  export default InputResetButton;