import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Collapse, Input, Tooltip } from 'reactstrap';

import Nouislider from 'nouislider-react';
import "nouislider/distribute/nouislider.css";
import DelayVisibility from '../../wrappers/DelayVisibility';

/* props
    sourceObject
    templateId
    onTrimChange
*/
const VideoTrimmer = (props) => {

  const sliderRef = useRef(null);
  var videoRef = useRef(undefined);
  const playButtonRef = useRef(null);
  const sourceRef = useRef(null/* generateObjectURL(props.sourceObject) */);

  const [videoPlayable, setVideoPlayable] = useState(false);

  useEffect(() => {  
    console.log("NEW INSTANCE", new Date().toLocaleTimeString());
    

    if(props.sourceObject)
      sourceRef.current = generateObjectURL(props.sourceObject);

    if(sliderRef.current) {
      // called when video is playing back
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);

      // videoRef.current.addEventListener('load', () => {
      //   videoRef.current.currentTime = videoRef.current.duration * sliderRef.current.noUiSlider.get(true)[1] / 100;
      // })

      sliderRef.current.noUiSlider.on("slide", function(values, handle){
        if(handle === 1) {
          // console.log("NEW SLIDE-EVENT CALL", new Date().toLocaleTimeString());
          const newVal = videoRef.current.duration * parseInt(values[handle]) / 100;
          if(videoRef.current.src) {
            // console.log("Hello");
            videoRef.current.currentTime = newVal;
          } 
        }
      });

      // when slider gets changed current playback time gets set to relative handle location
      sliderRef.current.noUiSlider.on('set', function(values, handle){
        // console.log("I", videoRef.current.currentTime);
        if(handle === 0 || handle === 2) {
          console.log("NEW SET-EVENT CALL", new Date().toLocaleTimeString());
          const newVal = videoRef.current.duration * parseInt(values[handle]) / 100;
          // console.log(handle, values[handle], newVal.toString());
          // videoRef.current.currentTime = 4;
          // console.log("pv", props.sourceObject); // WHY UNDEFINED ????
          // console.log("II", videoRef.current.currentTime);
          if(videoRef.current.duration) {
            props.onTrimChange({
                start: videoRef.current.duration * parseInt(values[0]) / 100,
                end: videoRef.current.duration * parseInt(values[2]) / 100
            });
            
            // /* videoRef.current.currentTime =  */console.log( videoRef.current.duration, values[1] / 100 )
            // console.log("IV", videoRef.current.currentTime);
          }
        }
        // console.log("III", videoRef.current.currentTime);
      });
    }
  }, []);

  useEffect(() => {
    setVideoPlayable(() => false);

    console.log("NEW SOURCE", new Date().toLocaleTimeString());

    if(props.sourceObject)
      videoRef.current.src = generateObjectURL(props.sourceObject);

      
      
    videoRef.current.addEventListener('loadeddata', (e) => {
      //Video should now be loaded but we can add a second check
      checkVideoReadyState();
    });
      // sourceRef.current = generateObjectURL(props.sourceObject);

    if(sliderRef.current)
      sliderRef.current.noUiSlider.set([0, 0, 100]);
    if(playButtonRef.current)
      playButtonRef.current.innerText = "▶";
  }, [props.sourceObject]);

  function generateObjectURL(file) {
    // 'use strict'
    var URL = window.URL || window.webkitURL;
    // var displayMessage = function (message, isError) {
    //   // var element = document.querySelector('#message')
    //   console.log(message, isError);
    //   // element.innerHTML = message
    //   // element.className = isError ? 'error' : 'info'
    // }
    // // var playSelectedFile = function (event) { //TODO
    //   // var file = this.files[0]
    //   if(props.type === "video") {
    //     var type = file.type;
    //     var videoNode = element; //document.getElementById('video');
    //     var canPlay = videoNode.canPlayType(type);
    //     if (canPlay === '') canPlay = 'no';
    //     var message = 'Can play type "' + type + '": ' + canPlay;
    //     var isError = canPlay === 'no';
    //     displayMessage(message, isError);
        
    //     // if (isError) {
    //     //   return
    //     // }
    //   }
  
      var fileURL = URL.createObjectURL(file)
      // videoNode.src = fileURL
      return fileURL;
    // }
    // var inputNode = document.querySelector('input')
    // inputNode.addEventListener('change', playSelectedFile, false)
  }

  function checkVideoReadyState() {
    console.log("VIDEO LOADED! rs=" + videoRef.current.readyState);
     
    if(videoRef.current.readyState >= 3){
      setVideoPlayable(() => true);
    } else {
      setVideoPlayable(() => false);
    }
  }

  function onTrimChange(newTrim) {
    // setTrim(newTrim);
    // console.log(newTrim);
  }


  // constrain video playback to relative positions of slider handles
  function handleTimeUpdate() {
    const lengthValues = sliderRef.current.noUiSlider.get();
    const startValue = parseInt(lengthValues[0]) / 100;
    const endValue = parseInt(lengthValues[2]) / 100;
    // console.log(videoRef.current.currentTime, startValue, endValue);
    if(videoRef.current.currentTime > videoRef.current.duration * endValue) {
      // console.log("W", videoRef.current.currentTime, videoRef.current.duration * endValue);
      videoRef.current.currentTime = videoRef.current.duration * startValue;
      sliderRef.current.noUiSlider.set([null, startValue, null]);
      // playPauseVideo();
    } else if(videoRef.current.currentTime === videoRef.current.duration) {
      videoRef.current.currentTime = videoRef.current.duration * startValue;
      playPauseVideo();
      // videoRef.current.play();
    } else {
      sliderRef.current.noUiSlider.set([null, (videoRef.current.currentTime / videoRef.current.duration) * 100, null]);
    }
  }

  function playPauseVideo() {
    if(videoRef.current && videoRef.current.src) {
      if(videoRef.current.paused) {
        videoRef.current.play();
        playButtonRef.current.innerText = "||"
      } else {
        videoRef.current.pause();
        playButtonRef.current.innerText = "▶"
      }
    }
  }

  return (
    <div>
      <Collapse isOpen={(videoPlayable && props.sourceObject) ? true : false} >
        {/* {props.sourceObject && */}
        <div className="rounded embed-responsive embed-responsive-16by9 mt-3" style={{cursor: "pointer"}} onClick={playPauseVideo}>
          <video ref={videoRef} playsInline id="video" src={props.sourceObject && sourceRef.current}></video>
            <Button type="button" className="m-3 bg-primary border-0" innerRef={playButtonRef} style={{position: "absolute", bottom: 0}}>
              {videoRef.current && videoRef.current.src && !videoRef.current.paused ? 
                "||"
              :
                "▶"
              }
            </Button>
        </div>

        <Nouislider
          // behaviour=""
          range={{ min: 0, max: 100 }}
          start={[0, 0, 100]}
          instanceRef={sliderRef}
          connect
          disabled={!videoPlayable}
          />     
      </Collapse>
        {/* <p>"{sourceRef.current}"</p>
        <p>"{props.sourceObject && generateObjectURL(props.sourceObject)}"</p> */}

      {(!videoPlayable && props.sourceObject && videoRef.current) &&
        <DelayVisibility delay={500} setVisible>
          <p className="text-warning">
            <i class="fas fa-exclamation-triangle"></i>{"  "}<br/>
            Für den gewählten Dateityp ist leider keine Vorschau möglich. Dies beinhaltet auch das Trimmer-Tool.<br/>
            Wenn du dein Video noch schneiden musst, versuche einen anderen Dateityp (z.B. mp4) oder schneide es in einer externen Anwendung bevor du es hier erneut hochlädst.<br/>
            Anderenfalls kannst du wie gewohnt fortfahren.        
          </p>
        </DelayVisibility>
      }

    </div>
  );
}


export default VideoTrimmer;