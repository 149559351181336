import React, { useEffect, useRef } from 'react';

import { Progress } from 'reactstrap';

const axios = require('axios');

/*props
   apiUrl
   jobId
   interval
   info
   onProgressChange
*/
const ProgressChecker = (props) => {
  //fetch render progress from api every {props.interval} seconds
  useInterval(() => {
    console.log(props.apiUrl, props.jobId, props.interval, props.info);    

    const fetchData = async () => {
      const result = await axios(
        `${props.apiUrl}/jobinfo/${props.jobId}`, // api
      );
      console.log(result.data);
      if(result.data.state !== undefined) { //TODO make less problematic
        console.log(result.data);      
        props.onProgressChange(result.data);
      } else {
        console.log("fetch res undefined");
        // props.onProgressChange({state: "finished", progress: "100"}); //TODO should get endresult from jobInfo-api !!! also handle rendering errors
      }
    }

    fetchData();
  }, props.interval);

  return (
    <div>
      <div className="text-center"> {`job state: ${props.info.state}`} </div>
      <Progress animated color="primary" value={props.info.progress}>
        {props.info.progress + "%"}
      </Progress>
    </div>
  );
}


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export default ProgressChecker;
