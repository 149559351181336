import React from 'react';

import { openPopupWidget } from "react-calendly";
import { Button } from 'reactstrap';

const CalendlyPopupButton = ({ className, url, prefill, pageSettings, utm, children }) => {
    return (
        <Button onClick={() => openPopupWidget({ url, prefill, pageSettings, utm })} className={`${className}`}>
            {children}
        </Button> 
    );
}

export default CalendlyPopupButton;