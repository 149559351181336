import React, {
  useState, useEffect, Fragment
} from 'react';
import { Link } from 'react-router-dom';
import {
  Tooltip,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import LogoutButton from './LogoutButton';

const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

const UserDropdown = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleDropdownOpen = () => setDropdownOpen(prevState => !prevState);

  function handleTooltipToggle() {
    setTooltipOpen(!tooltipOpen);
  }

  function handleAuthChange(authenticated) {
    props.onAuthChange(authenticated);
  }

  useEffect(() => {
    if(props.isAuthenticated === true) {
      fetchUserData();
    }
  }, [props.isAuthenticated])

  function fetchUserData() {
    const token = window.sessionStorage.getItem('mv_usr');
    if (token) {
        axios.get(
            `${apiUrl}/userdata`, // api
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            } // auth
          ) //api/auth/register
          .then(res => {
            const isValid = (res.status === 200 || res.status === 304);
            if (isValid) {
              // console.log("Token valid");
              // console.log("UserDropdown: ", res.data);
              // console.log("LeftVids: ", res.data.leftVideos);
              try{
                props.onFetchUserData(res.data);
              } catch {
                console.log(":(");
              }
              
            } else {
              console.log("Token invalid");
              if (props.isAuthenticated)
                props.onAuthChange(false);
            }
          })
          .catch(err => {
            console.log("userData err: ", err);
          })
      }
  }

  return ( 
    <Dropdown className="ml-2" isOpen={dropdownOpen} toggle={toggleDropdownOpen}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <img src="/avatar.svg" style={{maxWidth: "100%", height: "2.5rem"}} className="img-fluid border border-primary rounded-circle"></img>
      </DropdownToggle>
      <DropdownMenu className="mt-2">
        <DropdownItem header className="text-primary">{(props.userData.email) && props.userData.email}</DropdownItem>
        <Tooltip
          placement="left"
          isOpen={tooltipOpen}
          target={"vidsLeft"}
          toggle={handleTooltipToggle}>
            Noch {props.userData.leftVideos} Videos zur Verfügung
        </Tooltip>
        <DropdownItem text id="vidsLeft">
          <i style={{fontSize: "1rem", color: "#33c5b6"}} className='fas'>&#xf144;</i>{"  "}
          <span>{(props.userData.leftVideos) && props.userData.leftVideos}</span>
        </DropdownItem>
        {(props.userData._id === "5f2b3ae3588e9e5c988e4dee" || //TODO CHANGE!! very bad!
          props.userData._id === "5fb577979af01c1994b14e95") &&
            <DropdownItem>
              <Link to="/dashboard" style={{color: "black", textDecoration: "none"}} >
                Dashboard
              </Link>
            </DropdownItem>
        }
        <DropdownItem divider />
        <DropdownItem>
          <LogoutButton isAuthenticated={props.isAuthenticated} onAuthChange={handleAuthChange} />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    // <NavbarText>
    //   <span className="mx-3 align-items-center">
    //     <i style={{fontSize: "1.5rem"}} className='fas text-primary'>&#xf2bd;</i>
    //     <span style={{fontFamily:"Arial", fontSize: "1rem"}} className="ml-2 text-white">{(props.userData.email) && props.userData.email}</span>
    //   </span>
      // <Tooltip
      //   placement="top"
      //   isOpen={tooltipOpen}
      //   target={"badge"}
      //   toggle={handleTooltipToggle}
      // >Noch {props.userData.leftVideos} Videos zur Verfügung
      // </Tooltip>
    //   <span className="mx-3" data-toggle="tooltip" id="badge">
    //     <i style={{fontSize: "24px", color: "#33c5b6"}} className='fas'>&#xf144;</i>
    //     <span style={{fontFamily:"Arial", fontSize: "1rem"}} className="mx-2 text-white">{(props.userData.leftVideos) && props.userData.leftVideos}</span>
    //   </span>
    // </NavbarText>
    );
}


export default UserDropdown;