import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { 
    Button,
    Modal, 
    ModalBody, 
    Form, 
    Input, 
    FormGroup, 
    Label, 
    ModalFooter, 
    ModalHeader } from 'reactstrap';
    
import ValidationCheck from '../videoForm/inputs/ValidationCheck';

const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

/* props
    isAuthenticated
    onAuthChange
*/
const AuthenticationModal = (props) => {
    let history = useHistory();

    // const [modal, setModal]                         = useState(false); 
    const [inputs, setInputs]                       = useState({userEmail: "", userPw: ""});
    const [modal, setModal]                         = useState(false);
    const [wrongCredentials, setWrongCredentials]   = useState(false);


    useEffect(() => {
        setInputs({userEmail: "", userPw: ""});
        setWrongCredentials(false);
    }, [props.isAuthenticated])

    function toggle() {
        // props.onAuthChange(!props.isAuthenticated);
        setModal(!modal);
    } 

    function handleInputChange(event) {
        const targetName = event.target.name;
        const value = event.target.value;

        setInputs({
            ...inputs,
            [targetName]: value});
    }

    function handleAuthenticationToggle() {
        // test acc
        // params.append("email", "johnny@johnson.com");
        // params.append("password", "123456");
        
        if(!props.isAuthenticated) {
            const params = new URLSearchParams();
            params.append("email", inputs.userEmail);
            params.append("password", inputs.userPw);
            axios.post(`${apiUrl}/api/auth/login`, params) //api/auth/register
            .then(res => {
                // console.log(res.status + " " + res.statusText);
                // console.log(res.data);
                if(res.status === 200 || res.status === 304) {
                    window.sessionStorage.setItem(('mv_usr'), res.data.token);
                    toggle();
                    props.onAuthChange(true);
                    history.push('/create');
                } else {
                    console.log("Authentication error: ", res.data.message);
                    setWrongCredentials(true);
                }
            })
            .catch(err => {
                console.log("Auth err: ", err);
                setWrongCredentials(true);
            })
        }
    }

    return(
        <Fragment>
            {!props.isAuthenticated &&
                <Fragment>
                    <Button className={`text-primary rounded-xxl bg-transparent border-miuvi ${props.className}`} onClick={toggle}>Anmelden</Button>
                    <Modal isOpen={!props.isAuthenticated && modal /*&& (true || modal)*/} toggle={toggle} centered={true} size={""}>
                        <Form>
                            <ModalHeader onToggle={toggle} className="text-miuvi bg-blue">
                                Anmeldung
                            </ModalHeader>
                            <ModalBody className="">
                                <FormGroup>
                                    <Label for="userEmail">Nutzername</Label>
                                    <Input type="text" name="userEmail" id="userEmail" className={`${(wrongCredentials && "border-danger")}`} onChange={handleInputChange} value={inputs.email} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="userPw">Passwort</Label>
                                    <Input type="password" name="userPw" id="userPw" className={`${(wrongCredentials && "border-danger")}`} onChange={handleInputChange} value={inputs.pw} />
                                </FormGroup>
                                <ValidationCheck
                                    criteria={!wrongCredentials}
                                    messages={[<span>Anmeldung fehlgeschlagen<br/> Bitte überprüfe deinen Nutzernamen und Passwort"</span>]}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" className=" text-blue rounded-xxl" onClick={() => {
                                    handleAuthenticationToggle();
                                    }}>
                                    Anmelden
                                </Button>{' '}
                                <Button color="secondary" className=" rounded-xxl" onClick={toggle}>Abbrechen</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Fragment>
            }
        </Fragment>
    );
}



// I DON'T BELONG HERE !!! (already present in VideoForm [where i also don't belong {should be globally accessible help function}])
function logFD(formDataObject) {
    let log = "formData:\n";
    for (var pair of formDataObject.entries()) {
      log += "  " + pair[0] + ": " + pair[1] + "\n";
    }
    console.log(log);
  }




export default AuthenticationModal;

