import React, { useRef, useEffect, useState, Fragment } from 'react';
import {
  Button,
  Collapse, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Imprint from './legal/Imprint';
import PrivacyStatement from './legal/PrivacyStatement';

var body = document.body,
    html = document.documentElement;

const Footer = (props) => {
  const scrollTo = useRef(null);
  const [imprintOpen, setImprintOpen] = useState(false);
  const [privacyModalopen, setPrivacyModalOpen] = useState(false);
  const toggleImprint = () => {
    setImprintOpen(!imprintOpen);
  }
  const togglePrivacyModal = () => {
    setPrivacyModalOpen(!privacyModalopen);
  }
  const handleEntered = () => {
    window.scrollTo({
      top: Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight ),
      behaviour: "smooth"
    });
    //<div className="pt-4 bg-dkshade text-white w-100 d-flex flex-column justify-content-end">
  }

  return (
        <div className="pt-5 bg-blue text-white w-100 d-flex flex-column border-top border-primary">
          <h5 style={{cursor: "pointer"}} className="text-center text-primary" onClick={toggleImprint}>Impressum</h5><br></br>
          <Modal isOpen={imprintOpen} toggle={toggleImprint} size="lg" scrollable={true}>
            <ModalHeader toggle={toggleImprint} className="text-blue bg-miuvi">Impressum</ModalHeader>
            <ModalBody>
              <Imprint />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="text-light rounded-xxl" onClick={toggleImprint}>Schließen</Button>{' '}
            </ModalFooter>
          </Modal>

          <h5 style={{cursor: "pointer"}} className="text-center text-primary" onClick={togglePrivacyModal}>Datenschutzbestimmungen</h5><br></br>
          <Modal isOpen={privacyModalopen} toggle={togglePrivacyModal} size="lg" scrollable={true}>
            <ModalHeader toggle={togglePrivacyModal} className="text-blue bg-miuvi">Datenschutzbestimmungen</ModalHeader>
            <ModalBody>
              <PrivacyStatement />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="text-light rounded-xxl" onClick={togglePrivacyModal}>Schließen</Button>{' '}
            </ModalFooter>
          </Modal>

          <div className="text-muted text-center">
            <p className="mb-2">&copy;<span id="year">{(new Date().getFullYear())}</span> miuVi</p>
          </div>
        </div>
  );
}

export default Footer;

