import React, { useState, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

/* props
    modalIsOpen
    onToggle
    onToggleNoReload
*/
const SubmitModal = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <Fragment>      
            <Modal isOpen={props.modalIsOpen} onToggle={props.onToggle} className={""} centered>
                <ModalHeader onToggle={props.onToggle} toggle={props.onToggle} className="">
                    <span className="text-miuvi">Danke</span> für die Erstellung!
                </ModalHeader>
                <ModalBody>
                    <p className="text-center">Deine Daten sind bei uns eigegangen. Du erhältst in Kürze eine <strong><span className="text-miuvi">E-Mail</span></strong> mit dem Link zur Vorschau deines Videos.</p>
                    <p className="text-center">Du kannst daraufhin entscheiden, ob dir das Video so gefällt oder ob du das Video nochmals erstellen möchtest.</p>
                    <p className="text-center">{"(Bitte schau auch in deinem Spam-Ordner nach, falls du nach längerer Zeit noch keine Email erhalten hast)"}</p>
                    <br />
                    {/* <Container>
                        <Row className="justify-content-center mb-2">
                            <strong>Gleich noch ein Video erstellen?</strong>
                        </Row>
                        <Row className="justify-content-center mb-1">
                            <Col className="p-1 justify-content-center d-flex">
                                <Button color="primary" onClick={props.onToggle} className={"text-light rounded-xxl "}>Aus leerer Vorlage</Button>{' '}
                            </Col>
                            <Col className="p-1 justify-content-center d-flex">
                                <Button color="primary" onClick={props.onToggleNoReload} className={"text-light rounded-xxl "}>Mit derzeitigen Eingaben</Button>
                            </Col>
                        </Row>
                    </Container> */}
                </ModalBody>
                <ModalFooter className="text-center">
                    <Button color="primary" onClick={props.onToggleNoReload} className={"text-light rounded-xxl "}>Schließen</Button>
                    <Link to="/create" className="text-primary">Zurück zur Auswahl</Link>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default SubmitModal;