import React, { useState, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import PrivacyStatement from '../../legal/PrivacyStatement';

import ValidationCheck from './ValidationCheck';

/* props
    onCheckChange
    value
    isValid
*/
const PrivacyCheck = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    // const handleCheckChange = (event) => {
    //     setChecked(event.target.value);
    // }

    function handleCheckChange(event) {
      const checkValue = event.target.checked;
      props.onCheckChange({valid: checkValue, messages: (checkValue) ? [] : ["Bitte akzeptiere die Datenschutzrichtlinien um fortzufahren"]});
    }

    return(
        <Fragment>
            <FormGroup check>
                <Label check style={{cursor: "pointer"}}>   
                    <Input type="checkbox" onChange={handleCheckChange} value={props.isValid.valid} />{' '}
                         * Ich bin mit miuVi's <a className="text-miuvi" onClick={toggle}> Datenschutzbestimmungen </a> einverstanden.
                </Label>
            </FormGroup>
            <ValidationCheck
              criteria={props.isValid.valid}
              messages={props.isValid.messages}
            />
            <Modal isOpen={modal} toggle={toggle} size="lg" scrollable={true}>
                <ModalHeader toggle={toggle} className="text-white bg-dkshade">Datenschutzbestimmungen</ModalHeader>
                <ModalBody>
                  <PrivacyStatement />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="rounded-xxl text-light" onClick={toggle}>Schließen</Button>{' '}
                </ModalFooter>
            </Modal>
        </Fragment>
    );

      /* OLD STATEMENT FOR RECORDINGS */
    // <h5 className="text-primary">Daten, Foto- und Videoaufnahmen</h5>

    // <p>Im Rahmen der Veranstaltung werden Daten, Bild- und Videomaterial
    // verarbeitet und damit Recruitingvideos erstellt. Nachfolgend
    // informieren wir Sie über die Art und Zwecke der Daten und Bild- und
    // Videoaufnahmen, über die Rechtsgrundlagen und Ihre Rechte.</p>

    // <strong>Die Daten, Bild- und Videoaufnahmen werden zu den folgenden Zwecken verwendet:</strong>
    // <p>Teilnehmer; Interne Bilddatenbank, bzw. Bildarchiv; Präsentation und
    // Bewerbung unserer eigenen Leistungen; Hinweise auf weitere und auf
    // ähnliche Veranstaltungen; Werbezwecke; Öffentliche Berichterstattung
    // über die Veranstaltung; Öffentlichkeitsarbeit; Verwendung auf unserer
    // Website.</p>

    // <strong>Rechtsgrundlagen der Daten, Bild- und Videoaufnahmen:</strong>
    // <p>Berechtigte Interessen an der Nutzung der Daten und Bild- und
    // Videoaufnahmen zu den vorgenannten Zwecken gem. Art. 6 Abs. 1 S. 1
    // lit. f., 85 DSGVO in Verbindung mit §§ 22, 23 KUG..</p>

    // <strong>Aufbewahrungsdauer der Daten, Bild- und Videoaufnahmen:</strong>
    // <p> Die Daten, Bild- und Videoaufnahmen werden solange aufbewahrt, wie das
    // zu den vorgenannten Zwecken erforderlich ist. Die Daten, Bild- und
    // Videoaufnahmen können intern unbeschränkt aufbewahrt werden, z.B.
    // zur Sicherung von urheberrechtlichen Rechtsansprüchen durch
    // Nachweis von Originalaufnahmen und darüber hinaus aus Gründen
    // zeitgeschichtlicher Dokumentation. Im Fall der Veröffentlichung,
    // können die Daten, Bild- und Videoaufnahmen solange publiziert
    // werden, wie die jeweiligen Publikationsträger, Artikel oder Beiträge
    // öffentlich zugänglich sind.</p>

    // <strong>Hinweise auf Bildaufnahmen Dritter:</strong>
    // <p>Bildaufnahmen können auch von
    // den Teilnehmern der Veranstaltung in deren eigener Verantwortung
    // erstellt werden. Wir bitten die Teilnehmer um gegenseitige
    // Rücksichtnahme auf ihre Persönlichkeitsrechte. Während der
    // Veranstaltung können Medienvertreter vor Ort sein und in eigener
    // Verantwortung Bildaufnahmen erstellen.</p>

    // <p><strong>Wir bitten Sie besondere Rücksicht auf minderjährige Personen zu
    // nehmen und diese nicht oder nur mit der Einwilligung der
    // Erziehungsberechtigten, bzw. bei Jugendlichen ab 16. Lebensjahr
    // mit deren Einwilligung abzulichten.</strong></p>

    // <strong>Ihre Rechte:</strong>
    // <p>Sie können Ihr Recht auf Auskunf oder Berichtigung,
    // Löschung und Einschränkung der Verarbeitung der Fotografien geltend
    // machen und können sich bei der zuständigen Aufsichtsbehörde
    // beschweren. Sie können der Verarbeitung von Aufnahmen und Daten,
    // die Sie betreffen jederzeit widersprechen.
    // Sowie Ihr Recht auf Auskunf oder Berichtigung und Löschung der
    // Verarbeitung der Recruitingvideos geltend machen.</p>

    // <strong>Verantwortlicher:</strong>
    // <address>
    //     <strong className="text-miuvi">miuVi</strong><br />
    //     Florian Herrmann<br />
    //     Jakobinenstraße 22<br />
    //     90762 Fürth
    // </address>

    // <strong>E-Mail-Adresse:</strong><p>flo@miuvi.de</p>


    const stuff = (
      <div className="RECHTLICHES">

        <div className="custom-control custom-checkbox mb-3">
          <input type="checkbox" className="custom-control-input" id="privacyPolicy" name="privacyPolicy" isRequired />
          <label className="custom-control-label" for="privacyPolicy">Ich bin mit miuVi's <a className="text-secondary" data-toggle="collapse" href="#collapsePrivacyPolicy" role="button" aria-expanded="false"
              aria-controls="collapsePrivacyPolicy">Datenschutzbestimmungen</a>
            einverstanden.</label>

          <div className="invalid-feedback">
            Dies ist ein Pflichtfeld.
          </div>

          <div className="collapse" id="collapsePrivacyPolicy">
            <div className="card card-body text-secondary">
              <strong>Kurzform:</strong>
              <p>Ihr berechtigt uns zur Verarbeitung eurer Daten.</p>
              <strong>Langform:</strong>
              <p>Datenschutzhinweise hinsichtlich der Herstellung und Verwendung
                von Foto- und/oder Videoaufnahmen gemäß Art. 13 DSGVO</p>

              Name und Kontaktdaten des/der Verantwortlichen:
              Verantwortlich für die Datenverarbeitung ist<p></p>
              <address>
                <strong>miuVi</strong><br />
                Florian Herrmann<br />
                Kleestraße 21-23<br />
                90461 Nürnberg
              </address>
              Zweck der Verarbeitung:
              Die Fotos und/oder Videos dienen evtl. der Öffentlichkeitsarbeit, bzw. als Beispielmaterial für
              das Produkt.
            </div>
          </div>
        </div>

        <hr className="mb-4 mt-4" />
      </div>
    );
}

export default PrivacyCheck;
