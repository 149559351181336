import React, { Fragment } from 'react';
import { 
    Col,
    Container,
    Row
 } from 'reactstrap';
import CalendlyPopupButton from './booking/CalendlyPopupButton';



import ContactForm from './ContactForm';

const ContactPage = (props) => {
    return (
        <Container fluid className="bg-blue">
            <Row className="align-items-center pt-5">
                <Col>
                </Col>
            </Row>
            <Row>
                <Col  className="pt-5 pb-1 text-miuvi text-center">
                    <h1 classname="text-center ">Wir freuen uns von Ihnen zu hören</h1>
                    <h2 className="text-light mt-3">Für ein persönliches Gespräch<br />vereinbaren Sie gerne einen Termin mit uns</h2>
                    <CalendlyPopupButton className="my-3 p-3" url="https://calendly.com/miuvi/30min">
                      Erstgespräch vereinbaren
                    </CalendlyPopupButton>
                    <h2 className="mt-5 text-light">Oder schicken Sie uns eine Nachricht</h2>
                </Col>
            </Row>
            <Row className="justify-content-center mt-3 mb-5">
                <Col xs="2" sm="3" md="4"></Col>
                <Col xs="8" sm="6" md="4"> 
                    <ContactForm dark />
                </Col>
                <Col xs="2" sm="3" md="4">
                </Col>
            </Row>
            <Row className="justify-content-center text-center text-miuvi">
                <Col md="8">
                    <img src="team.svg" alt="Card image cap" className="img-fluid mx-auto mt-5 d-block px-3" />
                </Col>
            </Row>
        </Container>
    );
}

export default ContactPage;