import React from 'react';
import { InputGroupAddon, InputGroupText } from 'reactstrap';

/* props
    text
    maxLength
    toleranceFactor
*/
const TextLengthIndicator = (props) => {

  return (
    <InputGroupAddon addonType="append">
      <InputGroupText className={(
          props.text.length >= props.maxLength * props.toleranceFactor ?
            (props.text.length >= props.maxLength ? "bg-danger text-light" : "bg-warning text-light") :
            ""
        )}>
        {props.maxLength - props.text.length}
      </InputGroupText>
    </InputGroupAddon>

  );
}

TextLengthIndicator.defaultProps = {
  toleranceFactor: 0.75
}

export default TextLengthIndicator;
