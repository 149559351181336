import React, { Fragment, useState } from 'react';
import { Input, InputGroup, Tooltip, Badge } from 'reactstrap';

/* props
    id
    text
*/
const HelpBadge = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    function handleTooltipToggle() {
      setTooltipOpen(!tooltipOpen);
    }

  return (
    <Fragment>
        {props.text != "" &&
            <Fragment>
                <Badge id={props.id} color="primary" className="text-light ml-2">?</Badge>
                <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={props.id}
                toggle={handleTooltipToggle}
                >
                    {props.text}
                </Tooltip>
            </Fragment>
        }
    </Fragment>
    
  );
}

export default HelpBadge;
