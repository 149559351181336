import React, { useState, Fragment, useEffect } from 'react';
import { Container } from 'reactstrap';

const InfoMessage = (props) => {

    // useEffect(() => {
    //     console.log("Template chosen? " + props.leftVideos);
    //   }, []);
    
    return (
        <Container className="py-5">
            <h2 className="text-center">Sie haben keine freien Videos mehr zur Verfügung</h2>
            <p className="text-center">Bitte kontaktieren Sie Ihre Ansprechsperson</p>
        </Container>
    );
}

export default InfoMessage;