import React, { Fragment, useState } from 'react';
import {
    Collapse,
    List
} from 'reactstrap';

//props
// children
// numShown
const RetractableList = (props) => {
    // const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => {
        props.onToggle();
        // setIsOpen(!isOpen);
    }




    return(
        <List className="text-left">
            {Array.isArray(props.children) ?
                <Fragment>
                    {React.Children.toArray(props.children).filter((child, i) => i < props.numShown)}
                    <Collapse isOpen={props.isOpen}>
                        {React.Children.toArray(props.children).filter((child, i) => i >= props.numShown)}
                    </Collapse>
                    {!props.isOpen && <a style={{cursor: "pointer"}} className="text-primary" onClick={toggle}>mehr...</a>}
                    {props.isOpen && <a style={{cursor: "pointer"}} className="text-primary" onClick={toggle}>...weniger</a>}
                </Fragment>
                :
                props.children
            }
        </List>
    )
}

export default RetractableList;