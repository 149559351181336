import React, { useState, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

/* props
    modalIsOpen
    errorData
    onToggle
*/
const ErrorModal = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    function assignNotificationText (errorData) {
        switch (errorData.message) {
            case "File too large":
                return (
                    <Fragment>
                        <p className="text-center">{`Mindestens eine der hochgeladenen Dateien überschreitet die maximale Dateigröße von ${Math.round(errorData.maxSize / 1000000)}MB.`}</p>
                    </Fragment> );
            case "Insufficient credits":
                return (
                    <Fragment>
                        <p className="text-center">Dein Videokontingent scheint aufgebraucht zu sein.</p>
                        <p className="text-center">Falls es sich hierbei um einen Fehler handelt, kontaktiere bitte unseren Support.</p>
                    </Fragment> );
            case "Unsupported file":
                return (
                    <Fragment>
                        <p className="text-center">{`Die Datei "${errorData.fileName}" scheint fehlerhaft zu sein.`}</p>
                        <p className="text-center">Bitte prüfe ob du die Datei ansehen/abspielen kannst, sowie ob sie die richtige Endung hat und versuche es dann ggf. erneut.</p>
                        <p className="text-center">Fehlermeldung: <code>{errorData.message}</code></p>
                    </Fragment> );        
            default:
                return (
                    <Fragment>
                        <p className="text-center">Ein Fehler ist aufgetreten.</p>
                        <p className="text-center">Bitte versuche es erneut oder kontaktiere unseren Support.</p>
                        <p className="text-center">Fehlermeldung: <code>{errorData.message}</code></p>
                    </Fragment> );
        }
    }

    return (
        <Fragment>      
            <Modal isOpen={props.modalIsOpen} onToggle={props.onToggle} className={""} centered>
                <ModalHeader onToggle={props.onToggle} className="text-white bg-danger">
                    Dein Video konnte leider nicht erstellt werden
                </ModalHeader>
                <ModalBody>
                    {
                        assignNotificationText(props.errorData)
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.onToggle} className={"text-light d-flex align-items-center "}>Na gut</Button>{' '}
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default ErrorModal;