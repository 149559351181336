import React, { Fragment, useState } from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  CardColumns,
  CardDeck,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  InputGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
  Fade,
  Collapse,
  List,
  CustomInput,
  Modal,
  ModalBody
} from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ExampleVideo from './videoForm/ExampleVideo';
import ContactForm from './ContactForm';
import CalendlyPopupButton from './booking/CalendlyPopupButton';
import TextLoop from 'react-text-loop';
import WistiaEmbed from './player/WistiaEmbed';
import RetractableList from './RetractableList';
// import VideoCarousel from './VideoCarousel';

const apiUrl = process.env.REACT_APP_API_URL;

const pricesEur = {
  monthly1: 79,
  monthly2: 249,
  monthly3: 499,
  annually1: 63,
  annually2: 199,
  annually3: 399
}

//Wistia plugins
// You can define plugins here, like the ones documented at https://wistia.com/doc/embed-options#embed_plugins
// If you're feeling fancy, try making a plugin of your very own! https://wistia.com/doc/plugin-api
const wistiaPlugins = {
  "postRoll-v1": {
    text: "Remix this project on Glitch to try the WistiaEmbed React component for yourself",
    link: "https://wistia.com"
  }
}


const LandingPage = (props) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false); 
  const toggleVideoModal = () => setVideoModalOpen(!videoModalOpen);

  const [paymentTypesShown, setPaymentTypesShown] = useState("bundles");

  function handleBundlesButtonClick(event) {
    if(paymentTypesShown != "bundles")
      setPaymentTypesShown("bundles");
  }
  function handleSubscriptionsButtonClick(event) {
    if(paymentTypesShown != "subscriptions")
      setPaymentTypesShown("subscriptions");
  }

  return (
      <Fragment>
        
        <Container fluid className="py-5 mb-5 bg-primary">
          <Row className="align-items-center pt-4">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col className="pt-5 pb-3 text-center">
              <h1 className="text-blue"><strong>Mehr Erfolg im <span className="bg-blue text-yellow rounded px-2">Recruiting</span><br/>durch authentisches Employer Branding</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="pt-5 pb-3 text-center">
              <h3>
                <span className="text-blue rounded px-2">Erstellen Sie schnell & einfach hochwertige Videos,<br />die <span className="bg-blue text-yellow rounded px-2">A-Mitarbeiter</span> magnetisch anziehen</span>
                
              </h3>
            </Col>
          </Row>   
          <Row>
            <Col>
            </Col>
          </Row> 
          <Row className="">
            <Col xs="12" sm={{size:10, offset: 1}} md={{size:8, offset: 2}} xl={{size:6, offset: 3}} className="my-3 rounded"> {/* embed-responsive embed-responsive-16by9*/}
              <WistiaEmbed hashedId="6a54ueysce" playerColor="#34C1B4" /> {/*plugin={wistiaPlugins}*/}
              {/* <VideoCarousel>
              </VideoCarousel>
              <video playsInline preload="metadata" controls >
                <source src={`/recruiting-video_universa.mp4`} />
              </video>
              <ExampleVideo className="my-3 mx-2" templateId="21" videoId="examplevid.mp4" /> */}
            </Col>
          </Row>
          <Row>
            <Col className="pt-3 mb-5 d-flex justify-content-center">
              <Button tag={HashLink} to={`/#offer`} className="bg-blue text-yellow rounded-xxl mt-4 py-3 px-4">
                Zum Angebot
              </Button>
            </Col>
          </Row>
        </Container>

        <Container className="py-3 mt-5">
          <Row className="align-items-center pt-3">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col className="py-5 pb-3 mt-2 text-blue text-center">
              <h1 className="text-blue"><strong>Diese <span className="bg-pink text-light rounded pb-1 px-2">Probleme</span>kennt<br />jeder im Recruiting...</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} xl={{size: 4, offset: 4}} className="rounded-xxl bg-primary p-3" >
              <img src={'shock.png'} alt="content" className="mx-auto img-fluid d-block rounded-xxl" />
                          {/* <div className="text-center">
                              <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                              <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                              <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
                          </div> */}
            </Col>
          </Row>
          <Row className="px-2 pb-5 pt-5 mt-2 mb-4 d-flex justify-content-center">
            <CoolPinkBulletPointStuff />
          </Row>
          <Row>
            <Col>
              <img src={'arrow_pink.svg'} height="75rem" alt="arrow" className="mb-5 mx-auto d-block" />
            </Col>
          </Row>
          <Row>
            <Col className=" my-3 pb-5 text-blue text-center">
              <h2 className="text-pink"><strong>Kennen Sie das auch?</strong></h2>
              <h5 className="pl-2">Dann können wir Ihnen helfen!</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={'arrow_pink.svg'} height="75rem" alt="arrow" className="mb-5 mx-auto d-block" />
            </Col>
          </Row>
        </Container>


        <Container fluid className="bg-blue text-light">
          <Row>
            <Col className="pt-5 mb-3 text-center">
              <h1 className=""><strong>Erstellen Sie Videos ab<br />sofort in <span className="bg-pink rounded px-2 pb-1">Minuten</span></strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="mb-5 d-flex justify-content-center">
              <Button tag={HashLink} to={`/#offer`} className="bg-primary text-dkshade rounded-xxl mt-3 py-3 px-4">
                Zum Angebot
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={'arrow_outline_yellow_light.svg'} height="75rem" alt="arrow" className="mt-3 mx-auto d-block" />
            </Col>
          </Row>
          <Row className="px-3">
            <Col sm={{size: 12, offset: 0}} lg={{size: 8, offset: 2}} xl={{size: 10, offset: 1}} className=" rounded-xxl">
              <Row className="align-items-center py-5">
                <Col xl="5" className=" text-center text-xl-right">
                  <h3><span className="bg-yellow text-blue text-uppercase rounded px-2 mx-4 pb-1 pt-2">1. Video-Konzept</span></h3>
                  <h3 className="mx-4 pb-4" ><strong>auswählen</strong></h3>
                </Col>
                <Col xs={{size: 6, offset: 3}} sm={{size: 4, offset: 4}} xl={{size: 2, offset: 0}}>
                  <img src={'choose_dark.svg'} alt="dreamworker" className="d-block p-1 rounded-circle bg-yellow" />
                </Col>
                <Col className="d-flex" xl="5">
                    <ul className="mx-auto my-auto pl-5 pr-2 py-4 mx-xl-0">
                      <h5><strong><li>Vorgefertigter Ablauf</li></strong></h5>
                      <h5><strong><li>Ansprechendes Design</li></strong></h5>
                      <h5><strong><li>Professionell spezialisiert</li></strong></h5>
                    </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src={'arrow_outline_pink_light.svg'} height="75rem" alt="arrow" className="mx-auto d-block" />
                </Col>
              </Row>
              <Row className="align-items-center py-5">
                <Col xl="5" className="text-center text-xl-right">
                  <h3><span className="bg-pink text-blue text-uppercase rounded mx-4 px-2 pb-1 pt-2">2. Inhalte</span></h3>
                  <h3 className="mx-4 pb-4" ><strong>einfügen</strong></h3>
                </Col>
                <Col xs={{size: 6, offset: 3}} sm={{size: 4, offset: 4}} xl={{size: 2, offset: 0}}>
                  <img src={'dragdrop_dark.svg'} alt="problem" className="d-block p-1 rounded-circle bg-pink" />
                </Col>
                <Col className="d-flex" xl="5">
                    <ul className="mx-auto pr-2 py-4 pl-5 mx-xl-0">
                      <h5><strong><li>Texte & Logo</li></strong></h5>
                      <h5><strong><li>Bilder & Videoaufnahmen</li></strong></h5>
                      <h5><strong><li>Musik & Farben</li></strong></h5>
                    </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src={'arrow_outline_miuvi_light.svg'} height="75rem" alt="arrow" className="mx-auto d-block" />
                </Col>
              </Row>
              <Row className="align-items-center py-5">
                <Col xl="5" className="text-center text-xl-right">
                  <h3><span className="bg-primary text-blue text-uppercase rounded mx-4 px-2 pb-1 pt-2">3. Recruitingvideo</span></h3>
                  <h3 className="mx-4 pb-4" ><strong>erhalten</strong></h3>
                </Col>
                <Col xs={{size: 6, offset: 3}} sm={{size: 4, offset: 4}} xl={{size: 2, offset: 0}}>
                  <img src={'dreamjob_dark.svg'} alt="solution" className="d-block rounded-circle p-1 bg-miuvi" />
                </Col>
                <Col className="d-flex" xl="5">
                    <ul className="mx-auto pr-2 pl-5 py-4 mx-xl-0">
                      <h5><strong><li>Top-Job-Image</li></strong></h5>
                      <h5><strong><li>Attraktive Präsentation</li></strong></h5>
                      <h5><strong><li>Employer Branding</li></strong></h5>
                    </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container className="pt-3 mb-5 pt-sm-5">
          <Row>
            <Col>
              <img src={'arrow_yellow.svg'} height="75rem" alt="arrow" className="mt-3 mb-2 mx-auto d-block" />
            </Col>
          </Row>
          <Row>
            <Col className="pt-5 my-2 text-center text-blue">
              <h2><strong>So stechen Sie aus der Masse <span className="text-primary">&</span><br/>heben sich von Ihrer <strong><span className="bg-yellow rounded px-2 pb-1">Konkurrenz</span></strong> ab</strong></h2>
            </Col>
          </Row>
          <Row className="bg-primary mt-3 p-3 rounded-xxl">
            <Col xs="12" lg={{size:10, offset: 1}} xl={{size:8, offset: 2}} className="my-3 rounded"> {/* embed-responsive embed-responsive-16by9*/}
              <WistiaEmbed hashedId="mtj2vo37kh" playerColor="#34C1B4" />
              {/* <VideoCarousel>
                
              </VideoCarousel> */}
              {/* <video playsInline preload="metadata" controls >
                <source src={`/recruiting-video_universa.mp4`} />
              </video> */}
              {/* <ExampleVideo className="my-3 mx-2" templateId="21" videoId="examplevid.mp4" /> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={'arrow_yellow.svg'} height="75rem" alt="arrow" className="my-5 mx-auto d-block" />
            </Col>
          </Row>
          <Row>
            <Col className=" my-3 pb-3 text-blue text-center">
              <h2 className="dkshade"><strong>Nehmen Sie Menschen mit <span className="text-primary">&</span> lassen Sie sie spüren, <br/> was Sie und Ihre Stelle <strong><span className="bg-yellow px-2 pb-1 rounded">besonders</span></strong> macht </strong></h2>
            </Col>
          </Row>
          <Row className="px-2 pb-5 pt-5 mt-2 mb-4 d-flex justify-content-center">
            <CoolYellowBulletPointStuff />
          </Row>
          <Row>
            <Col className=" my-3 pb-5 text-blue text-center">
              <h3 className="dkshade"><strong>Verschafft Ihnen ein innovatives <span className="text-primary">&</span> modernes Image <br/> das  <strong><span className="bg-yellow pb-1 rounded px-2">attraktiv</span></strong> bei den Bewerber/-innen wirkt</strong></h3>
            </Col>
          </Row>
          <Row>
            <Col className=" my-4 text-blue text-center">
              <h4 className="dkshade">Erfahren Sie mehr über uns auf:</h4>
            </Col>
          </Row>
          <Row className="justify-content-center pb-3">
            <Col xs="0" sm="1" lg="2" xl="3" />
            <Col xs="6" sm="6" md="4" lg="3" className="d-flex align-items-center">
                <a target="_blank" href="https://persoblogger.de/2021/11/08/miuvi-employer-branding-und-recruiting-video-do-it-yourself-tool"><img src="/partner_persoblogger.png" alt="Card image cap" className="img-fluid mt-3 mb-4 my-auto rounded" /></a>
            </Col>
            <Col xs="0" sm="1" lg="2" xl="3" />
          </Row>
        </Container>

        {/* <Container fluid className="bg-lightmiuvi  py-3 py-sm-5 mb-5">
          <Row>
            <Col className="pt-5 text-blue text-center">
              <h2><strong>Unsere Preismodellvorschläge <span className="text-pink"></span></strong></h2>
            </Col>
          </Row>
          <MoneyStuff />
          <Row>
            <Col className="text-blue text-center pb-5">
              <h3>Videos für jede Stelle perfekt anpassen</h3>
            </Col>
          </Row>
        </Container> */}

        

        <Container fluid id="offer" className="bg-lightmiuvi my-5 py-3 py-sm-5">
            <Row>
              <Col className="py-5 text-blue text-center">
                <h1><strong>Unser Angebot</strong></h1>
              </Col>
            </Row>
            {/* <Row className="text-blue mt-3 justify-content-center">
              <Button type="button" name="bundlesButton" className={`${(paymentTypesShown === "bundles") ? "bg-pink text-light" : "bg-light text-pink" } rounded-xxl p-4 border-0 mx-3 mb-3`} onClick={handleBundlesButtonClick} >
                <h5 className={`mb-0 ${false && "text-blue"}`}><strong>Monatliche Abrechnung</strong></h5>
              </Button>
              <Button type="button" name="subscriptionsButton" className={`${(paymentTypesShown === "subscriptions") ? "bg-pink text-light" : "bg-light text-pink" } rounded-xxl p-4 border-0 mx-3 mb-3`} onClick={handleSubscriptionsButtonClick} >
                <h5 className="mb-0"><strong>Jährliche Abrechnung</strong> <span className={`${(paymentTypesShown === "subscriptions") ? "text-dkshade" : "text-primary" }`}><small>(-20%)</small></span></h5>
              </Button>
            </Row> */}
            <Row>
              <Col xs="12" lg={{size:10, offset: 1}} xl={{size:8, offset: 2}}>
                {paymentTypesShown === "bundles" &&
                  <MoneyStuffBundles />
                }
                {paymentTypesShown === "subscriptions" &&
                  <MoneyStuffSubs />
                }
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h5><strong>Individuelle Lösung für Ihr Unternehmen</strong></h5>
                <h5>Erhalten Sie eine maßgeschneiderte Lösung mit individuellen Videovoralgen und -Kontigent</h5>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 mb-5">
              <CalendlyPopupButton url="https://calendly.com/miuvi/30min" className="p-3 bg-blue text-pink rounded-xxl border-0">
                Angebot anfragen
              </CalendlyPopupButton>
            </Row>
          </Container>

        <Container fluid className="rounded-xxl mb-3 mt-5" >
          <Row>
            <Col className="pt-5 pb-3 text-dkshade text-center">
              <h1 className="text-blue"><strong>Lassen Sie sich von unseren <br/>Usern <span className="bg-yellow text-blue pb-1 rounded px-2">inspirieren</span></strong></h1>
            </Col>
          </Row>
          <Row className=" rounded-xxl my-md-5 py-5">
            <Col xs="0" sm="2" md="1" lg="2" xl="3"></Col>
            <Col xs="12" sm="8" md="5" lg="4" xl="3" className="py-3">
                <CoolVerticalStuff />
            </Col>
            <Col xs="12" sm="8" md="5" lg="4" xl="3" className="p-3">
                <img src="feedback.svg" alt="Card image cap" className=" p-2 img-fluid mx-auto d-block" />
            </Col>
            <Col xs="0" sm="2" md="1" lg="2" xl="3"></Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="0" sm="1" lg="2" xl="3" />
            <Col xs="4" sm="" className="d-flex align-items-center">
                <img src="/customer_josephs.png" alt="Card image cap" className="img-fluid mt-3 mb-4 my-auto rounded" />
            </Col>
            <Col xs="4" sm="" className="d-flex align-items-center">
                <img src="/customer_notyz.png" alt="Card image cap" className="img-fluid mt-3 mb-4 rounded" />
            </Col>
            <Col xs="4" sm="" className="d-flex align-items-center">
                <img src="/customer-universa.png" alt="Card image cap" className="img-fluid mt-3 mb-4 rounded" />
            </Col>
            <Col xs="4" sm="" className="d-flex align-items-center">
                <img src="/customer_auditheroes.png" alt="Card image cap" className="img-fluid mt-3 mb-4 rounded" />
            </Col>
            <Col xs="4" sm="" className="d-flex align-items-center">
                <img src="/customer_flowauditor.png" alt="Card image cap" className="img-fluid mt-3 mb-4 rounded" />
            </Col>
            <Col xs="0" sm="1" lg="2" xl="3" />
          </Row>
          {/* <Row> // set as own container?
            <Col className="py-5 my-5 text-blue text-center">
              <h1 className="dkshade"><strong><span className="bg-pink text-light rounded px-2">Firmenlogo</span> Karusell</strong></h1>
            </Col>
          </Row> */}
        </Container>
        
        <Container fluid className="py-5 mt-5 bg-lightpink">
          <Row>
            <Col className="pt-5 text-blue text-center">
              <h2><strong>Häufig gestellte Fragen</strong></h2>
            </Col>
          </Row>
          <Row className="py-3">
            <Col md="2"></Col>
            <Col md="8" className="py-3">
              <CoolVerticalFAQ />
            </Col>
            <Col md="2"></Col>
          </Row>
        </Container> 

        <Container className="py-5 my-5">
          <Row>
            <Col className="text-center mb-4">
              <h1><strong>
                <TextLoop 
                  className="text-primary"
                  interval={1500}>
                  <span>Professionals</span>
                  <span>Young Talents</span>
                  <span>Praktikanten</span>
                  <span>Facharbeiter</span>
                  <span>Auszubildende</span>
                </TextLoop>
              </strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mb-4">
              <i class="fas fa-heart fa-5x text-red"></i>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <h2><strong>Videos</strong></h2>
            </Col>
          </Row>
        </Container>
        
        <Container fluid className="text-blue gradient-righ bg-lightmiuvi py-5">
          <Row className="pt-2 pt-xl-5">  
            <Col xs="12" md={{size: 4, offset: 1}} className="mt-0 pt-lg-5" >
                <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                  <h3 className="text-blue"><strong>„Stellen Sie sich vor was in Ihrem <span className="bg-pink text-light rounded px-2 pb-1">Recruiting</span> passiert…</strong></h3>
                  <h5 className="lead mt-4">…wenn Sie mit miuvi Ihre Persönlichkeit als entscheidenden Vorteil nutzen.“</h5>
                  <Button tag={HashLink} to={`/#offer`} className="bg-blue text-pink border-0 rounded-xxl mt-4 py-3 px-4">
                    Zum Angebot
                  </Button>
                </Jumbotron>
            </Col>
            <Col xs={{size: 10, offset: 1}} md={{size: 5, offset: 1}} className="my-auto" >
                  {/* <video playsInline preload="metadata" autoPlay muted loop className="img-fluid mx-auto d-block">
                    <source src={'business_title.m4v'} type="video/mp4" />
                    :(
                  </video> */}
                  <img src={'home_business.svg'} alt="content" className="img-fluid mx-auto d-block" />
                  {/* <div className="text-center">
                      <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                      <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                      <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
                  </div> */}
              </Col>
          </Row>
        </Container>


      </Fragment>
  );
}

const ToolStuff = () => {
  return(
    // <Fade mountOnEnter in={false}>
      
    <Row className="mb-5">
      <Col xs="1" sm=""></Col>
      <Col xs="10" sm="12">
        <CardDeck className="text-center text-light d-flex justify-content-center">
          <Card className="m-3 bg-transparent border-red shadow">
            <CardBody className=" p-3 m-3">
              <CardImg src="/text.svg" className="d-none d-sm-block p-3" alt="Card image cap" />
              <CardTitle className="m-0 text-light rounded-top">
                <h4 className="mb-0">Texten</h4>
              </CardTitle>
            </CardBody>
          </Card>
          <Card className="m-3 bg-transparent border-yellow shadow">
            <CardBody className=" p-3 m-3">
              <CardImg src="/image.svg" className="d-none d-sm-block p-3" alt="Card image cap" />
              <CardTitle className="m-0 text-light rounded-top">
                <h4 className="mb-0">Bildern</h4>
              </CardTitle>
            </CardBody>
          </Card>
          <Card className="m-3 bg-transparent border-primary shadow">
            <CardBody className=" p-3 m-3">
              <CardImg src="/video_streaming.svg" className="d-none d-sm-block p-3" alt="Card image cap" />
              <CardTitle className="m-0 text-light rounded-top">
                <h4 className="mb-0">Videos</h4>
              </CardTitle>
            </CardBody>
          </Card>
          <Card className="m-3 bg-transparent border-purple shadow">
            <CardBody className=" p-3 m-3">
              <CardImg src="/musical_note_ii.svg" className="d-none d-sm-block p-3" alt="Card image cap" />
              <CardTitle className="m-0 text-light rounded-top">
                <h4 className="mb-0">Musik</h4>
              </CardTitle>
            </CardBody>
          </Card>
          <Card className="m-3 bg-transparent border-orange shadow">
            <CardBody className=" p-3 m-3">
              <CardImg src="/color_pallete_ii.svg" className="d-none d-sm-block p-3" alt="Card image cap" />
              <CardTitle className="m-0 text-light rounded-top">
                <h4 className="mb-0">Farben</h4>
              </CardTitle>
            </CardBody>
          </Card>
        </CardDeck>
      </Col>
      <Col xs="1" sm=""></Col>
    </Row>
    // </Fade>
  );
}

const CoolStuff = () => {
    return(
      // <Fade mountOnEnter in={false}>
        
      
      <Row className="mb-5">
        <Col xs="0"></Col>
        <Col >
          <CardDeck className="text-center text-light py-5 d-flex justify-content-center">
            <Card className="card-wider bg-dkshade p-3 m-3 shadow border-0">
              <CardImg top src="step1.svg" className="p-3" alt="Card image cap" />
              <CardBody className="">
                <CardTitle><h3 className="text-primary">1. Vorbereitung</h3></CardTitle>
                <CardText>Schauen Sie sich das Beispielvideo an, wie möchten Sie Ihre Stelle darin zeigen. Was macht die Stelle besonders? Was sind die Aufgaben, was sind die Werte Ihrer Firma?</CardText>
              </CardBody>
            </Card>
            <Card className="card-wider bg-dkshade p-3 m-3 shadow border-0">
              <CardImg top src="step2.svg" className="p-3" alt="Card image cap" />
              <CardBody >
                <CardTitle><h3 className="text-primary">2. Erstellung</h3></CardTitle>
                <CardText>Mit unserem Tool erstellen Sie kinderleicht ihr eigenes Video. Geben Sie Ihre gewünschten Daten ein, nehmen Sie die Momente auf, die im Video relevant sind. Wählen Sie eine Farbe aus und hinterlegen Sie das Video mit der passenden Musik.</CardText>
              </CardBody>
            </Card>
            <Card className="card-wider bg-dkshade p-3 m-3 shadow border-0">
              <CardImg top src="step3.svg" className="p-3" alt="Card image cap" />
              <CardBody >
                <CardTitle><h3 className="text-primary">3. Zusendung</h3></CardTitle>
                <CardText>Super, wir haben Ihre Daten erhalten und erstellen Ihnen nun das Video. In Kürze erhalten Sie Ihr fertiges Video per Mail.</CardText>
              </CardBody>
            </Card>
          </CardDeck>
        </Col>
        <Col xs="0"></Col>
      </Row>
      // </Fade>
    );
}

const FlagStuff = () => {
  const TextFlag = (props) => {
    return ( 
        <Col xs="12" lg="" className={`border-left border-dkshade px-0 my-3 mx-3`}>
          {props.children}
        </Col>
    );
  }
  const TextFlagTitle = (props) => {
    return ( 
        <Row className="m-0">
          <Col xs="10" sm="7" lg="10" xl="7" className="bg-blue text-light p-3  rounded-right">
            {props.children}
          </Col>
          <Col xs="2" sm="5" lg="2" xl="7" ></Col>
        </Row>
    );
  }
  const TextFlagBody = (props) => {
    return ( 
      <Row className="m-0 pt-4 pb-5">
        <Col className="text-blue">
          {props.children}
        </Col>
      </Row>
    );
  }

  return(
    // <Fade mountOnEnter in={false}>
    <Row className="my-3">
      <TextFlag>
        <TextFlagTitle>
          <h4 className="text-miuvi">Konzept & Design</h4>
        </TextFlagTitle>
        <TextFlagBody>
          <strong>Zusammen mit Ihnen entwickeln wir ihre ganz eigene Videomaske basierend auf Ihren  Wünschen & Bedürfnissen</strong>
        </TextFlagBody>
      </TextFlag>
      <TextFlag>
        <TextFlagTitle>
          <h4 className="text-yellow">Variable & <br/> fixe Werte</h4>
        </TextFlagTitle>
        <TextFlagBody>
          <strong>Sie bestimmen welche Bereiche im Video verändert werden dürfen und welche fix bleiben sollen</strong>
        </TextFlagBody>
      </TextFlag>
      <TextFlag>
        <TextFlagTitle>
          <h4 className="text-pink">Login & Nutzerrechte</h4>
        </TextFlagTitle>
        <TextFlagBody>
          <strong>Sie finden die Videomaske in Ihrem Memberbereich, legen Sie fest wer ein Video erstellen darf und mit wieviel Gestaltungsfreiheit</strong>
        </TextFlagBody>
      </TextFlag>
    </Row>

    // </Fade>
  );
}
const MoneyStuff = () => {
  return(
    // <Fade mountOnEnter in={false}>
      
  <Row className="mx-1 mb-5">
    <Col sm={{size: 10, offset: 1}}>
      <CardDeck className=" text-center text-blue pt-5 pb-3 pb-md-5 d-flex justify-content-center">
        <Card className="rounded-xxl m-3 bg-blue border-primary shadow">
            <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
              <h4>Kleinunternehmen</h4>
            </CardSubtitle>
          <CardBody className=" p-3 m-3">
            <CardImg  src="bundle1.svg" className="p-3" alt="Card image cap" />
            <CardTitle className="text-light"> <h5 style={{fontSize: "3rem"}} >Bundles</h5> {/*s <h1 style={{fontSize: "3rem"}}>{`${pricesEur.vids1}€`}</h1>*/}</CardTitle>
            <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
              Kontakt
            </Button>
          </CardBody>
        </Card>
        <Card className="rounded-xxl m-3 bg-blue border-primary shadow">
            <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
              <h4>Mittelstand</h4>
            </CardSubtitle>
          <CardBody className=" p-3 m-3">
            <CardImg src="bundle2.svg" className="p-3" alt="Card image cap" />
            <CardTitle className="text-light"><h5 style={{fontSize: "3rem"}} >Abos</h5> {/* <h1 style={{fontSize: "3rem"}}>{`${pricesEur.monthly1}€`}</h1>*/}</CardTitle>
            <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
              Kontakt
            </Button>
           {/* <CardText className="text-light mb-3"><small>{`${pricesEur.monthly1 / 5}€ pro Video`}</small></CardText> */}
          </CardBody>
        </Card>
        <Card className="rounded-xxl m-3 bg-blue border-primary shadow">
            <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
              <h4>Enterprise</h4>
            </CardSubtitle>
          <CardBody className=" p-3 m-3">
            <CardImg src="bundle3.svg" className="p-3" alt="Card image cap" />
            <CardTitle className="text-light"> <h5 style={{fontSize: "3rem"}} >Individuell</h5> {/*  <h1 style={{fontSize: "3rem"}}>{`${pricesEur.monthly2}€`}</h1> */} </CardTitle>
            <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
              Kontakt
            </Button>
           {/* <CardText className="text-light mb-3"><small>{`${pricesEur.monthly2 / 10}€ pro Video`}</small></CardText> */}
          </CardBody>
        </Card>
      </CardDeck>
    </Col>
  </Row>
  
    // </Fade>
  );
}

const MoneyStuffBundles = () => {
  
  const [isRetListOpen, setIsRetListOpen] = useState(false);

  function handleToggleRetListOpen() {
    setIsRetListOpen(!isRetListOpen);
  }

  return(
    // <Fade mountOnEnter in={false}>
      
    <Row className="mb-5 ">
      <Col>
        <CardDeck className="text-center mx-center text-blue pt-5 pb-3 pb-md-5 d-flex justify-content-center">
          <Card className="rounded-xxl m-3 bg-blue border-primary">
              <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
                <h4>Pro</h4>
              </CardSubtitle>
            <CardBody className=" p-3 m-3">
              <CardImg src="bundle1.svg" className="p-3" alt="Card image cap" />
              <CardTitle className="text-light">
                {/* <h1 style={{fontSize: "3rem"}}>{`${pricesEur.monthly1}€`} <span className="text-secondary" style={{fontSize: "1rem"}}>/mo</span></h1> */}
              </CardTitle>
              <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
                Anfragen
              </Button>
              <CardText className="text-light d-flex justify-content-center my-3">
                <small>
                  <RetractableList numShown={3} isOpen={isRetListOpen} onToggle={handleToggleRetListOpen}>
                    {/* <li>3 Videos/mo</li> */}
                    <li>Reduzierte Dateigrößen</li>
                    <li>Vorgefertigte Video-Konzepte</li>
                    <li>Verschiedene Bildformate </li>
                    <li>Musiklizenzen</li>
                    <li>Nutzungslizenzen</li>
                    <li>Empfehlung Videoequipment</li>
                    <li>Schulungsvideos</li>
                  </RetractableList>
                </small>
              </CardText>
            </CardBody>
          </Card>
          <Card className="rounded-xxl mx-3 bg-blue shadow">
            <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
              <h4>Business</h4>
            </CardSubtitle>
            {/* <CardSubtitle className="bg-pink text-light p-2">
              <h5>Am beliebtesten</h5>
            </CardSubtitle> */}
            <CardBody className=" p-3 m-3">
              <CardImg src="bundle2.svg" className="p-3" alt="Card image cap" />
              <CardTitle className="text-light">
                {/* <h1 style={{fontSize: "3rem"}}>{`${pricesEur.monthly2}€`} <span className="text-secondary" style={{fontSize: "1rem"}}>/mo</span></h1> */}
              </CardTitle>
              <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
                Anfragen
              </Button>
              <CardText className="text-light d-flex justify-content-center my-3">
                <small>
                  <RetractableList numShown={3} isOpen={isRetListOpen} onToggle={handleToggleRetListOpen}>
                    {/* <li>25 Videos/mo</li> */}
                    <li>Reduzierte Dateigrößen</li>
                    <li>Vorgefertigte Video-Konzepte</li>
                    <li>Verschiedene Bildformate</li>
                    <li>Musiklizenzen</li>
                    <li>Nutzungslizenzen</li>
                    <li>Beratung Videoequipment</li>
                    <li>Online Schulung</li>
                    <li>Support</li>
                  </RetractableList>
                </small>
              </CardText>
            </CardBody>
          </Card>
          <Card className="rounded-xxl m-3 bg-blue border-primary shadow">
              <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
                <h4>Enterprise</h4>
              </CardSubtitle>
            <CardBody className=" p-3 m-3">
              <CardImg src="bundle3.svg" className="p-3" alt="Card image cap" />
              <CardTitle className="text-light">
                {/* <h1 style={{fontSize: "3rem"}}>{`${pricesEur.monthly3}€`} <span className="text-secondary" style={{fontSize: "1rem"}}>/mo</span></h1> */}
              </CardTitle>
              <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
                Anfragen
              </Button>
              <CardText className="text-light d-flex justify-content-center my-3">
                <small>
                  <RetractableList numShown={3} isOpen={isRetListOpen} onToggle={handleToggleRetListOpen}>
                    {/* <li>100 Videos/mo</li> */}
                    <li>Reduzierte Dateigrößen</li>
                    <li>Vorgefertigte Video-Konzepte</li>
                    <li>Verschiedene Bildformate</li>
                    <li>Musiklizenzen</li>
                    <li>Nutzungslizenzen</li>
                    <li>Beratung Videoequipment</li>
                    <li>Workshop fürs Team</li>
                    <li>Support</li>
                  </RetractableList>
                </small>
              </CardText>
            </CardBody>
          </Card>
        </CardDeck>
      </Col>
    </Row>
    // </Fade>
  );
}

const MoneyStuffSubs = () => {

  const [isRetListOpen, setIsRetListOpen] = useState(false);

  function handleToggleRetListOpen() {
    setIsRetListOpen(!isRetListOpen);
  }

  return(
    // <Fade mountOnEnter in={false}>
    <Row className="mb-5 ">
      <Col>
        <CardDeck className="text-center mx-center text-blue pt-5 pb-3 pb-md-5 d-flex justify-content-center">
          <Card className="rounded-xxl m-3 bg-blue border-primary">
              <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
                <h4>Pro</h4>
              </CardSubtitle>
            <CardBody className=" p-3 m-3">
              <CardImg src="bundle1.svg" className="p-3" alt="Card image cap" />
              <CardTitle className="text-light">
                {/* <span style={{fontSize: "1.5rem"}} className="text-pink"><s>{`${pricesEur.monthly1}€`}</s></span>
                <h1 style={{fontSize: "3rem"}}>{`${pricesEur.annually1}€`} <span className="text-secondary" style={{fontSize: "1rem"}}>/mo</span></h1> */}
              </CardTitle>
              <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
                Anfragen
              </Button>
              <CardText className="text-light d-flex justify-content-center my-3">
                <small>
                  <RetractableList numShown={3} isOpen={isRetListOpen} onToggle={handleToggleRetListOpen}>
                    {/* <li>3 Videos/mo</li> */}
                    <li>Reduzierte Dateigrößen</li>
                    <li>Vorgefertigte Video-Konzepte</li>
                    <li>Verschiedene Bildformate </li>
                    <li>Musiklizenzen</li>
                    <li>Nutzungslizenzen</li>
                    <li>Empfehlung Videoequipment</li>
                    <li>Schulungsvideos</li>
                  </RetractableList>
                </small>
              </CardText>
            </CardBody>
          </Card>
          <Card className="rounded-xxl mx-3 bg-blue border-primary shadow">
            <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
              <h4>Business</h4>
            </CardSubtitle>
            {/* <CardSubtitle className="bg-pink text-light p-2">
              <h5>Am beliebtesten</h5>
            </CardSubtitle> */}
            <CardBody className=" p-3 m-3">
              <CardImg src="bundle2.svg" className="p-3" alt="Card image cap" />
              <CardTitle className="text-light">
                {/* <span style={{fontSize: "1.5rem"}} className="text-pink"><s>{`${pricesEur.monthly2}€`}</s></span>
                <h1 style={{fontSize: "3rem"}}>{`${pricesEur.annually2}€`} <span className="text-secondary" style={{fontSize: "1rem"}}>/mo</span></h1> */}
              </CardTitle>
              <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
                Anfragen
              </Button>
              <CardText className="text-light d-flex justify-content-center my-3">
                <small>
                  <RetractableList numShown={3} isOpen={isRetListOpen} onToggle={handleToggleRetListOpen}>
                    {/* <li>25 Videos/mo</li> */}
                    <li>Reduzierte Dateigrößen</li>
                    <li>Vorgefertigte Video-Konzepte</li>
                    <li>Verschiedene Bildformate</li>
                    <li>Musiklizenzen</li>
                    <li>Nutzungslizenzen</li>
                    <li>Beratung Videoequipment</li>
                    <li>Online Schulung</li>
                    <li>Support</li>
                  </RetractableList>
                </small>
              </CardText>
            </CardBody>
          </Card>
          <Card className="rounded-xxl m-3 bg-blue border-primary shadow">
              <CardSubtitle className="m-0 p-3 text-primary border-bottom border-primary rounded-top">
                <h4>Enterprise</h4>
              </CardSubtitle>
            <CardBody className=" p-3 m-3">
              <CardImg src="bundle3.svg" className="p-3" alt="Card image cap" />
              <CardTitle className="text-light">
                {/* <span style={{fontSize: "1.5rem"}} className="text-pink"><s>{`${pricesEur.monthly3}€`}</s></span>
                <h1 style={{fontSize: "3rem"}}>{`${pricesEur.annually3}€`} <span className="text-secondary" style={{fontSize: "1rem"}}>/mo</span></h1> */}
              </CardTitle>
              <Button tag={Link} to={`/contact`} className="rounded-xxl text-light bg-pink my-3 py-3 px-4 border-0">
                Anfragen
              </Button>
              <CardText className="text-light d-flex justify-content-center my-3">
                <small>
                  <RetractableList numShown={3} isOpen={isRetListOpen} onToggle={handleToggleRetListOpen}>
                    {/* <li>100 Videos/mo</li> */}
                    <li>Reduzierte Dateigrößen</li>
                    <li>Vorgefertigte Video-Konzepte</li>
                    <li>Verschiedene Bildformate</li>
                    <li>Musiklizenzen</li>
                    <li>Nutzungslizenzen</li>
                    <li>Beratung Videoequipment</li>
                    <li>Workshop fürs Team</li>
                    <li>Support</li>
                  </RetractableList>
                </small>
              </CardText>
            </CardBody>
          </Card>
        </CardDeck>
      </Col>
    </Row>
    // </Fade>
  );
}

const BulletPoint = (props) => {
  return (
    <Container>
      <Row>           
        <Col className="" xs="1" sm="2" md="1">
            <img src={props.imgSrc} alt="Card image cap" style={{height: "1.5rem"}} className="float-center rounded-circle" />
        </Col>  
        <Col xs="10" sm="10" md="11">
            <h5 className="text-blue pl-2">{props.children}</h5>
        </Col>
      </Row>
    </Container>
  );
}

const CoolPinkBulletPointStuff = () => {
    return(
      <Row>
        <Col xs="12">
          <BulletPoint imgSrc="check_circle_pink.svg">In überlaufenen Märkten gehen Sie in der Masse unter</BulletPoint>
          <BulletPoint imgSrc="check_circle_pink.svg">Ihnen fehlt <strong>das</strong> entscheidende Alleinstellungsmerkmal</BulletPoint>
          <BulletPoint imgSrc="check_circle_pink.svg">Sie wollen mehr Persönlichkeit zeigen - am liebsten in Videos...</BulletPoint>
          <BulletPoint imgSrc="check_circle_pink.svg">...aber wollen sich nicht mit der Technik & Erstellung rumquälen</BulletPoint>
          <BulletPoint imgSrc="check_circle_pink.svg">...fühlen sich unsicher vor der Kamera</BulletPoint>
          <BulletPoint imgSrc="check_circle_pink.svg">und wollen kein Videoteam für tausende Euros engagieren</BulletPoint>
        </Col>
      </Row>
    );
}

const CoolYellowBulletPointStuff = () => {
    return(
      <Row>
        <Col xs="12" sm="6">
          <BulletPoint imgSrc="check_circle_yellow.svg">Persönlicher Einblick</BulletPoint>
          <BulletPoint imgSrc="check_circle_yellow.svg">Authentisch</BulletPoint>
          <BulletPoint imgSrc="check_circle_yellow.svg">Teamvorstellung</BulletPoint>
          <BulletPoint imgSrc="check_circle_yellow.svg">Modernes Image</BulletPoint>
        </Col>
        <Col xs="12" sm="6">
          <BulletPoint imgSrc="check_circle_yellow.svg">Kommunikation der Unternehmens-Kultur</BulletPoint>
          <BulletPoint imgSrc="check_circle_yellow.svg">Schafft Entaitainment</BulletPoint>
          <BulletPoint imgSrc="check_circle_yellow.svg">Überzeugende Präsentation</BulletPoint>
        </Col>
      </Row>
    );
}

const CoolVerticalStuff = () => {
    return(
    <div className="">
    <Card className="bg-lightyellow rounded-xxl mb-3 shadow border-0">
      <CardBody className="">
        <Row>
          <Col xs={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
              <img src="/customer_josephs.png" alt="Card image cap" className="img-fluid mt-3 mb-4 rounded" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12" className="embed-responsive embed-responsive-9by16">
            <video playsInline preload="metadata" controls >
              <source src="/josephs_recension.mp4" className=""></source>
              {/* <source src={`${apiUrl}/file/examplefile/${21}/${"examplevid.mp4"}`} /> */}
            </video>
          </Col>
        </Row>
        <Row>
          <CardTitle className="text-blue pl-3" >
            <h5><strong>
              Lena Jäger<br />
            </strong>
              Innovation Specialist</h5>
          </CardTitle>
          <CardText className="text-blue pl-3">
            <p><strong>
              "Die Videoerstellung mit miuvi war sehr einfach und professionell! Das fertige Video kam super an, wir konnten darin unsere Praktikumsstellen persönlich und packend präsentieren."
            </strong></p>
          </CardText>
          {/* <h5 className="pr-4 text-right text-yellow"> <span className="bg-blue rounded-xxl px-2 py-1"><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i></span></h5> */}
        </Row>
      </CardBody>
    </Card>
    {/* <Card className="bg-lightyellow rounded-xxl mb-3 shadow border-0">
      <CardBody className="">
       <Row>
          <Col xs={{size: 6, offset: 3}} lg={{size: 4, offset: 0}}>
              <img src="/user_fuerkan.png" alt="Card image cap" className="img-fluid m-3 bg-yellow p-2 rounded-circle" />
          </Col>
          <Col xs="12" sm="8">
              <CardTitle className="text-blue pl-5" ><h3><strong>Fürkan, Student</strong></h3></CardTitle>
              <CardText className="text-blue pl-5">War eine sehr interessante Erfahrung und ich wurde im Vorstellungsgespräch direkt positiv darauf angesprochen.</CardText>
              <h5 className="pr-4 text-right"> <span className="bg-blue rounded-xxl px-2 py-1"><i className=' text-yellow fas fa-star'></i><i className='text-yellow fas fa-star'></i><i className='text-yellow fas fa-star'></i><i className='text-yellow fas fa-star'></i><i className='text-yellow fas fa-star'></i></span></h5>
          </Col>
        </Row>
      </CardBody>
    </Card> */}

    </div>
    );
}

const CoolVerticalFAQ = () => {
  const FAQCard = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return(
      <Card className="bg-light mb-3 mx-3 shadow border-0">
        <CardTitle onClick={toggle} tag={"h5"} style={{cursor: "pointer"}} className="text-primary bg-blue m-0 p-3 rounded" >{props.label}</CardTitle>
        <Collapse isOpen={isOpen}>
          <CardBody className="bg-light rounded">
            {props.children}
          </CardBody>
        </Collapse>
      </Card>
    );
  }
  return(
  <div className="text-blue">
  <FAQCard label="Brauche ich bereits Erfahrung mit dem Format Video?">
      <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
      <CardText className="">Nein, um einfache Videoaufnahmen ohne Bewegung zu erstellen benötigen Sie keine Vorerfahrung. Falls Sie aufwendigere Videos drehen wollen, können wir Ihnen Schulungen oder einen Dreh vor Ort anbieten (Verlinkung zu Videoaufnahmen/Service)</CardText>
  </FAQCard>
  {/* <FAQCard label="Ist miuvi DSGVO konform & ist die Videoerstellung sicher?">
      <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
      <CardText className="">Vermutlich...</CardText>
  </FAQCard>
  <FAQCard label="Wo werden meine Kundendaten genau gespeichert?">
      <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
      <CardText className="">Auf einem PC in Flos' Keller</CardText>
  </FAQCard> */}
  <FAQCard label="Bekomme ich die Nutzungslizenz für die Videos?">
      <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
      <CardText className="">Ja innerhalb des Abonnements können Sie die Videos für alle Zwecke verwenden.</CardText>
  </FAQCard>
  <FAQCard label="Wieviel Zeit benötige ich für das Erstellen von Videos?">
      <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
      <CardText className="">Je nach Konzept und Übung vor der Kamera, kann ein Video schon innerhalb von 20 Minuten verwendet werden.</CardText>
  </FAQCard>
  <FAQCard label="Funktioniert miuvi auch für mein Unternehmen?">
      <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
      <CardText className="">Ja, wenn Sie vorhaben Ihre Arbeitgebermarke zu stärken, sind Sie hier genau richtig.</CardText>
  </FAQCard>
    {/* <FAQCard label="Wo können wir das Recruitingvideo einbetten, einstellen oder teilen?">
        <CardSubtitle className="text-primary" ><h5></h5></CardSubtitle>
        <CardText className="">Info folgt in Kürze</CardText>
    </FAQCard>
    <FAQCard label="Für welche Stellen ist ein Recruitingvideo geeignet?">
        <CardSubtitle className="" ><h5></h5></CardSubtitle>
        <CardText>Info folgt in Kürze</CardText>
    </FAQCard>
    <FAQCard label="Benötigen wir Vorkenntnisse in Videoproduktion/-Design?">
        <CardSubtitle className="" ><h5></h5></CardSubtitle>
        <CardText>Info folgt in Kürze</CardText>
    </FAQCard>
    <FAQCard label="Welches Equipment benötigen wir?">
        <CardSubtitle className="" ><h5></h5></CardSubtitle>
        <CardText>Info folgt in Kürze</CardText>
    </FAQCard>
    <FAQCard label="Müssen sich unsere Mitarbeiter mit der Kamera auskennen?">
        <CardSubtitle className="" ><h5></h5></CardSubtitle>
        <CardText>Info folgt in Kürze</CardText>
    </FAQCard>
    <FAQCard label="Wie sieht es mit dem Datenschutz aus?">
        <CardSubtitle className="" ><h5></h5></CardSubtitle>
        <CardText>Info folgt in Kürze</CardText>
    </FAQCard> */}
  </div>
  );
}

// const Contact = () => {
//   return (
//     <Form>
//         <Input type="text" id="contact_name" className="mb-3 form-control bg-transparent text-light border-primary" placeholder="Name"></Input>
        
//         <Input type="email" className="mb-3 bg-transparent text-light border-primary" placeholder="E-Mail"></Input>
        
//         <Input type="text" className="mb-3 bg-transparent text-light border-primary" placeholder="Betreff"></Input>
        
//         <Input type="textarea" rows="4" className="mb-3 bg-transparent text-light border-primary" placeholder="Nachricht"></Input>

//         <Button type="button" color="primary" className="text-light py-3 px-5">
//           <i className="fas fa-paper-plane"></i> {"  "}Abschicken
//         </Button>
//     </Form>
//   );
// }

export default LandingPage;
