import React, { Fragment, useState } from 'react';

import { Container, Row, Col, Jumbotron, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle, CardText, Button, Card, ListGroup, ListGroupItem } from 'reactstrap';

import { Link, useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL; //TODO move

const TutorialPage = () => {

  const {sectionName, vidOrdinal} = useParams();

  
  const VideoFrame = (props) => {
    if(props.sectionName === "tech") {
      let ytVidId = "";
      switch(props.vidOrdinal) {
        case "1": ytVidId = "UwpGwh1wWrc";
          break;
        case "2": ytVidId = "jjdkzpuSNOU";
          break;
        case "3": ytVidId = "pHA1zOnYUI4";
          break;
      }
      return(
        <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${ytVidId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      );
    } else {
      return(
        <video playsInline preload="metadata" controls className="img-fluid mx-auto d-block border-0" >
          <source src={`${props.apiUrl}/file/tutorial/${props.sectionName}/${props.vidOrdinal}!.mp4`} />
        </video>
      );
    }
  } 

    return(
      <Fragment>
        
        
        {/* <Container fluid className="text-light bg-primary py-5">
            <Row className="py-5">
                <Col sm="1" />
                <Col sm="4" className="mt-5 pt-5" >
                  <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                      <h1 className="text-light">Tutorials</h1>
                      <p className="lead">Hier erfährst du alles, was du zur Erstellung deines Bewerbungsvideos benötigst</p>
                  </Jumbotron>
                </Col>
                { <Col sm="1" /> }
                <Col sm="6" className="my-3 d-flex align-content-center justify-content-center flex-wrap" >
                  <img src={'leandra.png'} alt="content" className="img-fluid mx-auto d-block" />
                  { <div className="text-center">
                      <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                      <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                      <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
                  </div> }
                </Col>
                <Col sm="1" />
            </Row>
        </Container> */}

        <Container fluid className=" py-3 pt-5 bg-lightmiuvi"> 
          <Row className="py-5  ">
            <Col lg="1"></Col>
            <Col xs="12" sm="10" lg="7" className="rounded-xxl mb-4 embed-responsive embed-responsive-16by9">
              <VideoFrame apiUrl={apiUrl} sectionName={sectionName} vidOrdinal={vidOrdinal} />
            </Col>
            <Col xs={{size: 12, offset: 0}} sm={{size: 10, offset: 1}} md={{size: 8, offset: 2}} lg={{size: 3, offset: 0}} className="p-0">
              <VidSelection />
            </Col>
            <Col lg="1"></Col>
          </Row> 
          <Row className="align-items-center pt-3">
          <Col className="pt-5 pb-3 mb-3 text-blue text-center">
          <h1 className=""><strong><span className="bg-pink text-light rounded px-2 pb-1">Persönliche</span> Videos für mehr Vertrauen  <br/>empfehlen wir Ihnen in der <span className="bg-blue text-yellow rounded px-2 pb-1">"Du-Form"</span></strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="pb-5 d-flex justify-content-center">
              <Button tag={Link} to={`/training`} color="" className="bg-yellow text-blue rounded-xxl py-3 px-4">
                Weitere Unterstützung
              </Button>
            </Col>
          </Row>
        </Container>
      </Fragment>
  
    );
  }

  // const VidContainer = () => {
  //   return(
  //     <Container className="m-0p-5 text-dkshade">
  //       <Row className="my-5">
  //           <Col md="1" />
  //           <Col md="10" className="py-3">
  //             <h3 className="text-primary">Wie nehme ich mich auf?</h3>
  //             <p className="text-light">
  //               A cyclorama is a panoramic image on the inside of a cylindrical platform, designed to give viewers standing in the middle of the cylinder a 360° view, and also a building designed to show a panoramic image. The intended effect is to make viewers, surrounded by the panoramic image, feel as if they were standing in the midst of the place depicted in the image.
  //             </p>
  //           </Col>
  //           <Col md="1" />
  //       </Row>              
  //       <Row className="my-5">
  //           <Col md="1" />
  //           <Col md="5" className="py-3">
  //             <h3 className="text-primary">Haltung und Körpersprache</h3>
  //           </Col>
  //           <Col md="5" className="py-3">
  //               <video playsInline preload="metadata" src={`${apiUrl}/file/tutorial/${"4!.mp4"}`} controls alt="Card image cap" className="img-fluid mx-auto d-block rounded shadow" />
  //           </Col>
  //           <Col md="1" />
  //       </Row>
  //       <Row className="my-5">
  //           <Col md="1" />
  //           <Col md="5" className="py-3">
  //               <video playsInline preload="metadata" src={`${apiUrl}/file/tutorial/${"5!.mp4"}`} controls alt="Card image cap" className="img-fluid mx-auto d-block rounded shadow" />
  //           </Col>
  //           <Col md="5" className="py-3">
  //             <h3 className="text-primary">Aussprache</h3>
  //             <p className="text-light">
  //             Mel Villena is a Filipino musician, composer and musical director who has worked with various artists such as Sharon Cuneta,[1] Pops Fernandez, Martin Nievera, Lea Salonga, The Company, and Mitch Valdez. He was also the musical director of Associated Broadcasting Company's (ABC) Philippine Idol.
  //             </p>
  //           </Col>
  //           <Col md="1" />
  //       </Row>
  //     </Container>
  //   );
  // }

  const VidSelection = () => {
    const [activeTab, setActiveTab] = useState(useParams().sectionName);
  
    const {sectionName, vidOrdinal} = useParams();

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    return(
      <Container className="p-0">
        <Row className="pl-3 pt-3 m-0">
          <h2 className=" bg-yellow rounded-xxl text-center px-4 py-2 text-blue"><strong>Tutorials</strong></h2>
        </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{cursor: "pointer"}}
            className={(activeTab === 'leandra') ? "bg-pink text-blue border-0" : "text-blue"}
            onClick={() => { toggle('leandra'); }}
          >
            <h6>Performance</h6>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{cursor: "pointer"}}
            className={(activeTab === 'tech') ? "bg-pink text-blue border-0" : "text-blue"}
            onClick={() => { toggle('tech'); }}
          >
            <h6>Technik</h6>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="leandra">
          <ListGroup>
            <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "1"} tag={Link} to={`/tutorials/leandra/1`} action className=" text-blue rounded-o"><strong>1. Schüchternheit</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "2"} tag={Link} to={`/tutorials/leandra/2`} action className=" text-blue rounded-0"><strong>2. Erscheinungsbild</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "3"} tag={Link} to={`/tutorials/leandra/3`} action className=" text-blue rounded-0"><strong>3. Der gesprochene Text</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "4"} tag={Link} to={`/tutorials/leandra/4`} action className=" text-blue rounded-0"><strong>4. Auflockerungsübungen</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "5"} tag={Link} to={`/tutorials/leandra/5`} action className=" text-blue rounded-0"><strong>5. Körperhaltung</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "6"} tag={Link} to={`/tutorials/leandra/6`} action className=" text-blue rounded-1"><strong>6. Die Stimme</strong></ListGroupItem>
            {/* <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "7"} tag={Link} to={`/tutorials/leandra/7`} action className=" text-blue rounded-0"><strong>7. Alleine oder mit jemanden?</strong></ListGroupItem> */}
            {/* <ListGroupItem active={sectionName === "leandra" && vidOrdinal === "8"} tag={Link} to={`/tutorials/leandra/8`} action className=" text-blue rounded-1"><strong>8. Vorstellungsgespräch</strong></ListGroupItem> */}
          </ListGroup>
        </TabPane>
        <TabPane tabId="tech">
          <ListGroup>
            <ListGroupItem active={sectionName === "tech" && vidOrdinal === "1"} tag={Link} to={`/tutorials/tech/1`} action className=" text-blue rounded-o"><strong>1. Drehort</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "tech" && vidOrdinal === "2"} tag={Link} to={`/tutorials/tech/2`} action className=" text-blue rounded-0"><strong>2. Equipment</strong></ListGroupItem>
            <ListGroupItem active={sectionName === "tech" && vidOrdinal === "3"} tag={Link} to={`/tutorials/tech/3`} action className=" text-blue rounded-1"><strong>3. Aufnahme</strong></ListGroupItem>
          </ListGroup>
        </TabPane>
      </TabContent>
    </Container>
    );
  }

  export default TutorialPage;