import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Button,
  Fade,
  Spinner,
  Progress,
  Form,
  Col,
  Alert,
  Collapse
} from 'reactstrap';

///

import InputArea              from './inputs/InputArea';
import ProgressChecker        from './ProgressChecker';
import FormValidation         from './validation/FormValidation';
import TextInput              from './inputs/TextInput';
import SubmitModal            from './SubmitModal';
import PrivacyCheck           from './inputs/PrivacyCheck';
import ValidationCheck        from './inputs/ValidationCheck';
import ErrorModal             from '../notification/ErrorModal';
import Admin_RegistrationForm from '../authentication/admin/Admin_RegistrationForm';
import ExampleVideo           from './ExampleVideo';
import LoadPresetModal        from './presets/LoadPresetModal';
import SavePresetModal        from './presets/SavePresetModal';
import { Fragment } from 'react';

const axios = require('axios');

const isInProductionMode = (process.env.NODE_ENV === 'production');

// const CancelToken = axios.CancelToken;
// const cancelTokenSource = CancelToken.source();

/* props
    templateId
    apiUrl
    isAuthenticated
    onAuthChange
*/
const VideoForm = (props) => {
  const [formState, setFormState]                           = useState({});
  const [inputValues, setInputValues]                       = useState({});
  const [optionalInfo, setOptionalInfo]                     = useState({}); //TODO shouldn't optional info be submitted with the actual input data? (as object?)
  const [presetData, setPresetData]                         = useState(undefined);

  const [dependencies, setDependencies]                     = useState({});

  const [isLoadPresetsModalOpen, setIsLoadPresetsModalOpen] = useState(false);
  const [isSavePresetsModalOpen, setIsSavePresetsModalOpen] = useState(false);

  const [areInputsValid, setAreInputsValid]                 = useState({}); //TODO
  const [isPrivacyAccepted, setIsPrivacyAccepted]           = useState({valid: false, messages: ["Bitte akzeptiere die Datenschutzrichtlinien um fortzufahren"]}); //TODO incorporate with above

  const [validationTrigger, setValidationTrigger]           = useState(0); //counter for triggering validation on inputs

  const [targetMail, setTargetMail]                         = useState(props.preferedTargetMail ? props.preferedTargetMail : ""); //TODO solve better (auth token?); remove preferedTargetMail stuff
  const [isTargetMailValid, setIsTargetMailValid]           = useState(props.preferedTargetMail ?
                                                                {valid: true, messages: []}
                                                                :
                                                                {valid: false, messages: ["Bitte ausfüllen"]}); //TODO incorporate with areInputsValid

  const [isLoading, setIsLoading]                           = useState(true);
  const [loadingError, setLoadingError]                     = useState("");

  const [triedSubmit, setTriedSubmit]                       = useState(false);
  const [isSubmitted, setIsSubmitted]                       = useState(false);
  const [submitVerified, setSubmitVerified]                 = useState(false);
  const [submitFailed, setSubmitFailed]                     = useState(false);
  const [errorData, setErrorData]                           = useState({message: ""});

  const [uploadProgress, setUploadProgress]                 = useState(0);

  const [jobId, setJobId]                                   = useState("");
  const [renderInfo, setRenderInfo]                         = useState({state: "", progress: 0});


  // const checkToken = async () => {
  //   const token = window.sessionStorage.getItem('mv_usr');
  //   if(token) {
  //     try {
  //       const result = await axios.get(`${apiUrl}/test/token`, { headers: { Authorization: `Bearer ${token}` } });
  //       const isValid = (result.status === 200 || result.status === 304);
  //       console.log(isValid);
  //       if(isValid) {
  //         console.log("Token valid");
  //         setIsAuthenticated(true);
  //       } else {
  //         console.log("Token invalid");
  //         setIsAuthenticated(false);
  //       }  
  //     } catch(err) {
  //       console.log("Token invalid");
  //       setIsAuthenticated(false);
  //     }
  //   } else {
  //     console.log("No token: user not signed in");
  //     setIsAuthenticated(false);
  //   }
  // }


  useEffect(() => {
    if(!triedSubmit)
      fetchData();

  }, [props.templateId, props.isAuthenticated]);


  // should check for incomming (bad) responses during file upload & cancel request accordingly - but doesn't... TODO (if even possible, see link below)
  // https://stackoverflow.com/questions/18367824/how-to-cancel-http-upload-from-data-events
  //
  // axios.interceptors.response.use(function (response) {
  //   // Any status code that lie within the range of 2xx cause this function to trigger
  //   // Do something with response data
  //   console.log("All cool");
  //   return response;
  // }, function (error) {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger
  //   // Do something with response error
  //   console.log("FUUUUU");
  //   console.log(error);
  //   console.log(error.response);
  //   // console.log(error.response.status); 
  //   // if(isSubmitted) {
  //   //   console.log("hej")
  //   //   setIsSubmitted(false);
  //   //   setUploadProgress(0);
  //   // }else{
  //   //   console.log("?~?");
  //   // }
  //   setIsSubmitted(false);
  //   setUploadProgress(0);
  //   if(error.response.status === 401) {
  //     console.log("Got unauth");
  //     if(props.isAuthenticated)
  //       props.onAuthChange(false);
  //       console.log("Auth changed");
  //   }
  //   cancelTokenSource.cancel("OIOIOI");
  //   return Promise.reject(error);
  // });

  const fetchData = async () => {
    setIsLoading(true);
    const token = window.sessionStorage.getItem('mv_usr');
    if(token) {
      try { //TODO replace try/catch block with axios' then/catch (see handle submit)
        const result = await axios.get(
          `${props.apiUrl}/formModel/${props.templateId}`, // api
          { headers: { Authorization: `Bearer ${token}` } } // auth
        );
        const isValid = (result.status === 200 || result.status === 304);
        if(isValid) {
          // console.log("Token valid");
          setFormState(result.data);
          // console.log(result.data);

          
          // console.log(retrieveInputs(result.data.inputs, "text", ""));

          setInputValues({
            ...retrieveInputs(result.data.inputs, "text", ""),
            ...retrieveInputs(result.data.inputs, "color", "#212B3A"), //TODO make nicer
            ...retrieveInputs(result.data.inputs, "video", undefined),
            ...retrieveInputs(result.data.inputs, "audio", undefined),
            ...retrieveInputs(result.data.inputs, "image", undefined)
          });

          setDependencies(
            retrieveDependencies(result.data.inputs)
          );
          
          //initialise all inputs validity
          setAreInputsValid({
            ...retrieveValidation(result.data.inputs)
          });
          setIsLoading(false);
        } else {
          console.log("Token invalid");
          if(props.isAuthenticated)
            props.onAuthChange(false);
        }

        
      } catch (error) {
        console.log(error);
        setLoadingError("404") //TODO recieve message from res (requierse above todo [then/catch])
      }
      // console.log(formState);
    } else {
      console.log("No token: user not signed in");
      if(props.isAuthenticated)
        props.onAuthChange(false);
    }
  };

  function handleInputChange(inputName, inputValue, newOptionalInfo) {
    
    updateInputs(inputName, inputValue);

    // console.log(areInputsValid);  
    if(newOptionalInfo) { // optionalInfo -> {tag: {infoType: info}}
      setOptionalInfo(prevState => {
        return ({
          ...prevState,
          [inputName]: {
            ...prevState[inputName],
            ...newOptionalInfo
          }
        });
      });
    }
  }

  function handleOptionalInfoChange(inputName, newOptionalInfo) {
    console.log("lel", inputName, newOptionalInfo);
    setOptionalInfo(prevState => {
      return ({
        ...prevState,
        [inputName]: {
          ...prevState[inputName],
          ...newOptionalInfo
        }
      });
    });
  }

  function handleTargetMailChange(inputName, inputValue, newOptionalInfo) {
    setTargetMail(inputValue);
  }

  function handleSubmitClick() {
    setTriedSubmit(true);
    if(!isInProductionMode || (checkInputValidity(areInputsValid) && isTargetMailValid.valid && isPrivacyAccepted.valid)) {
      setIsSavePresetsModalOpen(true);
    }
  }

  function handleSubmit(_presetData) {
    
    // event.preventDefault();

    // console.log(JSON.stringify(formState.data));
    // console.log(JSON.stringify(inputValues));

    //allow submit if privacy statement is accepted and no input validation errors exist    
    if(!isInProductionMode || (checkInputValidity(areInputsValid) && isTargetMailValid.valid && isPrivacyAccepted.valid)) { //WARNING duplicate, see ValidationCheck after submit button (minus production check)
      
      const token = window.sessionStorage.getItem('mv_usr');
      if(token) {
        const formData = new FormData();
        for (var key of Object.keys(inputValues)) {
          formData.append(key, inputValues[key]);
        }
        // console.log(optionalInfo);
        formData.append('optionalInfo', JSON.stringify(optionalInfo));
        formData.append('targetMail', targetMail);
        if(_presetData) {
          formData.append('_presetName', _presetData.saveName);
          formData.append('_presetCategory', "presets"); //TODO PROVISORIC should be submitted via URL
        }
        logFD(formData);

        setIsSubmitted(true);

        axios.post(`${props.apiUrl}/formsubmit/${props.templateId}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
          // timeout: 60*60*1000, // makes little sense for uplaods ?
          // cancelToken: cancelTokenSource.token,
          onUploadProgress: progressEvent => {
            setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total*100));
          }
        })
        .then(res => {
          // console.log(res.status + " " + res.statusText);
          if(res.status === 200 || res.status === 304) {
            setSubmitVerified(true);
            setJobId(res.data);
            console.log("Submit", res.status, res.statusText);
          } else {
            console.log("Error occured - status:", res.status, res.statusText);
            setIsSubmitted(false);
            setSubmitFailed(true); //user notification of error // should be here ?
            setUploadProgress(0);
          }
        })
        .catch((err) => {
          setIsSubmitted(false);
          setUploadProgress(0);
          if(err.response) {
            console.log(err.response);
            // console.log(err.response.status, typeof err.response.status);
            setErrorData(err.response.data);
            if(err.response.status === 401) {
              if(props.isAuthenticated) {
                props.onAuthChange(false);
              }
            }
            else { 
              setSubmitFailed(true); //user notification of error
            }
          }
        }) 
      } else {
        console.log("No token: user not signed in");
        if(props.isAuthenticated)
          props.onAuthChange(false);
      }
    }
  }

  function handleSave(data) {
    setPresetData(() => {return data});
    handleSubmit(data); //TODO make use state or handle completely differently
  }

  function handleDontSave() {
    setPresetData(() => {return undefined});
    handleSubmit();
  }

  function handleSaveTest(data) {

    //allow submit if privacy statement is accepted and no input validation errors exist    
    // if(!isInProductionMode || (checkInputValidity(areInputsValid) && isTargetMailValid.valid && isPrivacyAccepted.valid)) { //WARNING duplicate, see ValidationCheck after submit button (minus production check)
      
      const token = window.sessionStorage.getItem('mv_usr');
      if(token) {
        const formData = new FormData();
        for (var key of Object.keys(inputValues)) {
          formData.append(key, inputValues[key]);
        }
        formData.append('_presetName', data.saveName)
        // console.log(optionalInfo);
        // formData.append('optionalInfo', JSON.stringify(optionalInfo));
        // formData.append('targetMail', targetMail);
        logFD(formData);

        // setIsSubmitted(true);

        axios.post(`${props.apiUrl}/saves/add/${props.templateId}/presets`, formData, {
          headers: { Authorization: `Bearer ${token}` }
          // ,
          // timeout: 60*60*1000, // makes little sense for uplaods ?
          // cancelToken: cancelTokenSource.token,
          // onUploadProgress: progressEvent => {
          //   setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total*100));
          // }
        })
        .then(res => {
          // console.log(res.status + " " + res.statusText);
          if(res.status === 200 || res.status === 304) {
            // setSubmitVerified(true);
            // setJobId(res.data);
            console.log("Save-test", res.status, res.statusText);
          } else {
            console.log("Save-test: Error occured - status:", res.status, res.statusText);
            // setIsSubmitted(false);
            // setSubmitFailed(true); //user notification of error // should be here ?
            // setUploadProgress(0);
          }
        })
        .catch((err) => {
          // setIsSubmitted(false);
          // setUploadProgress(0);
          if(err.response) {
            console.log(err.response);
            // console.log(err.response.status, typeof err.response.status);
            setErrorData(err.response.data);
            if(err.response.status === 401) {
              if(props.isAuthenticated) {
                props.onAuthChange(false);
              }
            }
            else { 
              // setSubmitFailed(true); //user notification of error
            }
          }
        }) 
      } else {
        console.log("No token: user not signed in");
        if(props.isAuthenticated)
          props.onAuthChange(false);
      }
    // }
  }


  const handleModalToggle = (shouldReload) => {
    if(isSubmitted || submitFailed) {
      setIsSubmitted(false);
      setSubmitFailed(false);
      setSubmitVerified(false);
      if(/*isInProductionMode &&*/ shouldReload)
        window.location.reload();
    }
    else if(!submitFailed)
      setIsSubmitted(true);
  }

  function handleLoadSave(data) {
    console.log("hi", data)
    setInputValues(data);
    triggerValidation();

  }

  function handleProgressChange(event) {
    // console.log(event);
    switch(event.state) {
      case "error": //TODO
      case "finished": //TODO
        setIsSubmitted(false);
        setRenderInfo({
          state: "",
          progress: 0
        });
        setJobId("");
        break;
      default:
        setRenderInfo(event);
    }
  }

  function handleValidationChange(inputName, validity) {
    setAreInputsValid(prev => ({
      ...prev,
      [inputName]: validity
    }));
  }

  function triggerValidation() {
    setValidationTrigger(prev => prev + 1);
  };

  function handleTargetMailValidityChange(inputName, validity) {
    // const targetMail = event.target.value; //TODO
    // const lastAtPos = targetMail.lastIndexOf('@');
    // const lastDotPos = targetMail.lastIndexOf('.');
    // const mailCheck = (lastAtPos < lastDotPos && lastAtPos > 0 && targetMail.indexOf('@@') == -1 && lastDotPos > 2 && (targetMail.length - lastDotPos) > 2)

      setIsTargetMailValid(validity) // && mailCheck
  }

  function handlePSCheckChange(validity) {    
    setIsPrivacyAccepted(validity);
  }

  function checkInputValidity(validityState) {
    let allValid = true;
    for (const inputValue in validityState) {
      if (Object.hasOwnProperty.call(validityState, inputValue)) {
        if(!validityState[inputValue].valid) {
          allValid = false;
          break;
        } 
      }
    }
    console.log(allValid); //TODO check
    return allValid;
  }

  // function formatFieldsWithErrors(validityState) {
  //   const tagsWithErrors = Object.keys(areInputsValid).filter(inputTag => !areInputsValid[inputTag].valid)
  //   const labelsWithErrors = [];
  //   for (let tag = 0; tag < tagsWithErrors.length; tag++) {

  //     // TODO find a simple way to get labels from tags
      
  //   }
  // }

  // console.log("---", isLoading, props.isAuthenticated, triedSubmit, isSubmitted, uploadProgress, submitVerified);
  if(!isLoading && (props.isAuthenticated || triedSubmit)) {
    return (
      <Container fluid className="bg-primary">

        <Container className="py-5" >
            <Fade in={true}>
          <Row className="justify-content-center pt-5">
            <h1 className="text-light text-center mb-3">{formState.templateInfo.name}</h1>
          </Row>
          <Row className="justify-content-center pb-5">
            <h5 className="text-center">{formState.templateInfo.description}</h5>
          </Row>
          <Row>
              {/* action={`http://localhost:3001/formsubmit/${props.templateId}`} method="post" target="_blank" enctype="multipart/form-data" */}
              

              <Form className="needs-validation rounded shadow bg-white p-4" noValidate={true}>
                <div>
                  <ExampleVideo
                    className="py-5"
                    templateId={props.templateId} 
                    videoId="examplevid.mp4"
                    />


                  <Row>
                    <Col xs={{size: 6, offset: 3}}>
                      <Button color="light" type="button" className="mb-3 form-control rounded-xxl text-primary border-primary" onClick={() => setIsLoadPresetsModalOpen(true)} disabled={isSubmitted}>
                        Gespeicherte Vorlage laden
                      </Button>
                      <LoadPresetModal
                        modalIsOpen={isLoadPresetsModalOpen}
                        templateId={props.templateId}
                        onToggle={() => setIsLoadPresetsModalOpen(false)}
                        onLoadSave={handleLoadSave}
                        />
                    </Col>
                  </Row>


                  <InputArea
                    inputs={formState.inputs}
                    inputValues={inputValues}
                    areInputsValid={areInputsValid}
                    templateId={props.templateId}
                    validationTrigger={validationTrigger}
                    onInputChange={handleInputChange}
                    onOptionalInfoChange={handleOptionalInfoChange}
                    onValidationChange={handleValidationChange}
                    />
                </div>
                <div className="m-3">
                  {/*<Col lg="6" className="p-0">
                    <TextInput
                        type={"email"}
                        name={"targetMail"}
                        label={"Deine E-Mail (hier erhältst du dein Video)"}
                        placeholder={""}
                        value={targetMail}
                        initialValue={props.preferedTargetMail}
                        help={""}
                        maxLength={null}
                        required={true}
                        isValid={isTargetMailValid} //TODO
                        onInputChange={handleTargetMailChange}
                        onValidationChange={handleTargetMailValidityChange}
                        />
                    </Col>*/}
                  <PrivacyCheck
                    isValid={isPrivacyAccepted}
                    onCheckChange={handlePSCheckChange} />
                </div>
                <div className="px-3">
                  <Button color="primary" type="button" className="mb-3 rounded-xxl form-control text-light" onClick={handleSubmitClick} disabled={isSubmitted}>
                    {isSubmitted ? "Dateien werden hochgeladen..." : "Video erstellen"} 
                  </Button>

                  <SavePresetModal 
                    modalIsOpen={isSavePresetsModalOpen}
                    onToggle={() => setIsSavePresetsModalOpen(!isSavePresetsModalOpen)}
                    onSave={handleSave}
                    onDontSave={handleDontSave}
                    />

                  <Collapse isOpen={isSubmitted && presetData && !(submitVerified || submitFailed) }>
                      <Fade in={isSubmitted && presetData && !(submitVerified || submitFailed) }>
                          <Alert color="primary">
                              Deine Vorlage wird gespeichert sobald alles hochgeladen ist.
                          </Alert>
                      </Fade>
                  </Collapse>

                  {/*<Button type="button" onClick={() => console.log(triedSubmit)}>_debug</Button>*/}
                  {triedSubmit &&
                    <ValidationCheck //TODO check if all val checks return true
                      criteria={checkInputValidity(areInputsValid) && isTargetMailValid.valid && isPrivacyAccepted.valid} //TODO NOW!!!
                      messages={[`Einige Felder ${/*formatFieldsWithErrors(areInputsValid)*/""}enthalten noch Fehler, bitte korrigiere diese um fortzufahren`]}
                    />
                  }
                </div>
                {isSubmitted &&
                    <Progress animated color="primary" className="" value={uploadProgress}>
                      {uploadProgress + "% hochgeladen"}
                    </Progress>
                }
                
                {/* <FormValidation valErrors={areInputsValid} /> */}
                <SubmitModal
                  modalIsOpen={submitVerified} // isSubmitted && uploadProgress === 100
                  onToggle={() => handleModalToggle(true)}
                  onToggleNoReload={() => handleModalToggle(false)}
                  // uploadProgress={uploadProgress}
                  />
                <ErrorModal
                  modalIsOpen={submitFailed}
                  onToggle={() => handleModalToggle(false)}
                  errorData={errorData}
                  />
                {/*isSubmitted && <ProgressChecker apiUrl={props.apiUrl} jobId={jobId} interval={5000} info={renderInfo} onProgressChange={handleProgressChange} />*/}
              </Form>
              {targetMail === "_regUser" &&
                <Admin_RegistrationForm />
              }
          </Row>
        
          </Fade>
      </Container>
      </Container>   
    );
  } else if(loadingError != "") {
    return (
      <Container fluid className="m-2 p-5">
        <Row className="justify-content-center">
            {(loadingError === "404") ? 
              <Alert color="danger">
                <h3 className="alert-heading">404</h3>
                <p>Die ausgewählte Template konnte leider nicht gefunden werden</p>
              </Alert>
              : 
              <Alert color="danger">
                <h3 className="alert-heading">Das tut uns leid...</h3>
                <p>Ein unerwarteter Fehler ist aufgetreten, bitte lade die Seite erneut oder probiere es mit einer anderen Template</p>
              </Alert>
            }
        </Row>
      </Container>
    )
  }
  else {
    return (//TODO useState height when rescaling browser window style={{height: `${window.innerHeight-132-60}px`}}
      <Container className="m-2 p-5">
        <Row>
          <Spinner color="primary" />
          <h3 className="ml-3 text-primary">Lade Vorlage...</h3>
        </Row>
      </Container>
    );
  }


  function updateInputs(masterTag, masterValue) { //TODO manage setting validation on slaves to true (properly)

    setInputValues((prevState) => {
      let newState = {
        ...prevState,
        [masterTag]: masterValue
      };
  
      //
      if(dependencies[masterTag]) {
        for (let i = 0; i < dependencies[masterTag].length; i++) {
          if(dependencies[masterTag][i].slaveOptions) {
            for (let j = 0; j < dependencies[masterTag][i].slaveOptions.length; j++) {
              if(masterValue === dependencies[masterTag][i].slaveOptions[j].masterVal) {
                newState[dependencies[masterTag][i].slaveTag] = dependencies[masterTag][i].slaveOptions[j].slaveVal;

                areInputsValid[dependencies[masterTag][i].slaveTag] = {valid: true, messages: []}; //TODO IMPORTANT!!! should run input specific check instead of just setting true

              }
            }         
          } else {
            console.log("Warning: Time to finish m/s functionality...");
            //TODO for option-less master/slave relationships
          } 
        }
      }
      return(newState);
    });
  }


  //returns object with key:value pairs of html input-tag-tags with an associated initial value //TODO make better definition
  function retrieveInputs(inputStateObject, filterType, defaultValue) {
    let _values = {}
    for(var i = 0; i < inputStateObject.length; i++) {
      if(typeof inputStateObject[i].tag != "undefined") {
        if(!filterType || inputStateObject[i].type === filterType) {
          // console.log(inputStateObject[i].type, filterType);
          if(typeof inputStateObject[i].initialValue != "undefined") { // initialValue has priority over defaultValue
            _values = { 
                ..._values,
                [inputStateObject[i].tag]: inputStateObject[i].initialValue
            }
          } else {
            _values = { 
                ..._values,
                [inputStateObject[i].tag]: defaultValue
            }
          }
        }
      } else {
        _values = {
          ..._values,
          ...retrieveInputs(inputStateObject[i].groupContent, filterType, defaultValue)
        }
      }
    }
    return _values;
  }


  //returns object of scheme:
  // {
  //   masterTag: [
  //     {
  //       slaveTag,
  //       slaveOptions: [
  //         {
  //           masterVal,
  //           slaveVal
  //         }
  //       ]
  //     }
  //   ]
  // }
  function retrieveDependencies(inputStateObject) {
    let _values = {}

    for(let i = 0; i < inputStateObject.length; i++) {
      if(typeof inputStateObject[i].tag != "undefined") {
        if(typeof inputStateObject[i].options != "undefined") {
          for (let j = 0; j < inputStateObject[i].options.length; j++) {
            if(inputStateObject[i].options[j].masters) {
              for (let k = 0; k < inputStateObject[i].options[j].masters.length; k++) {
                if(_values[inputStateObject[i].options[j].masters[k].tag]) {
                  let found = false;
                  for (let l = 0; l < _values[inputStateObject[i].options[j].masters[k].tag].length; l++) {
                    if(_values[inputStateObject[i].options[j].masters[k].tag][l].slaveTag === inputStateObject[i].tag) {
                      _values[inputStateObject[i].options[j].masters[k].tag][l].slaveOptions.push(
                        {
                          masterVal: inputStateObject[i].options[j].masters[k].key,
                          slaveVal:  inputStateObject[i].options[j].value
                        }
                      );
                      found = true;
                    }
                  }
                  if(!found) {
                    _values[inputStateObject[i].options[j].masters[k].tag].push(
                      {
                        slaveTag:     inputStateObject[i].tag,
                        slaveOptions: [
                          {
                            masterVal: inputStateObject[i].options[j].masters[k].key,
                            slaveVal:  inputStateObject[i].options[j].value
                          }
                        ]
                      }
                    );
                  }
                } else {
                  _values = {
                    ..._values,
                    [inputStateObject[i].options[j].masters[k].tag]: [
                      {
                        slaveTag:     inputStateObject[i].tag,
                        slaveOptions: [
                          {
                            masterVal: inputStateObject[i].options[j].masters[k].key,
                            slaveVal:  inputStateObject[i].options[j].value
                          }
                        ]
                      }
                    ]
                  }
                }
              }
            }
          }
        }
      } else {
        const foundDeps = retrieveDependencies(inputStateObject[i].groupContent); //recursive call
        const foundDepsKeys = Object.keys(foundDeps);
        const currentValuesKeys = Object.keys(_values);
        for (let depKeyIndex = 0; depKeyIndex < foundDepsKeys.length; depKeyIndex++) { //merges the recursion results
          let found = false;
          for (let valKeyIndex = 0; valKeyIndex < currentValuesKeys.length; valKeyIndex++) {
            if(foundDepsKeys[depKeyIndex] === currentValuesKeys[valKeyIndex]) {
              _values[currentValuesKeys[valKeyIndex]] = _values[currentValuesKeys[valKeyIndex]].concat(foundDeps[foundDepsKeys[depKeyIndex]]);
              found = true;
            }
          }
          if(!found) {
            _values = {
              ..._values,
              [foundDepsKeys[depKeyIndex]]: foundDeps[foundDepsKeys[depKeyIndex]]
            }
          }
        }
      }
    }
    return _values;
  }

  function retrieveValidation(inputStateObject) {
    let _values = {}
    for(var i = 0; i < inputStateObject.length; i++) {
      if(typeof inputStateObject[i].tag != "undefined") {
        if(typeof inputStateObject[i].initialValue != "undefined") { // if there's an initial value set, we can suppose it is valid - right??
          _values =
            { ..._values,
               [inputStateObject[i].tag]: {valid: true, messages: []}
            }
        } else {
          let initialMessage = undefined;
          switch(inputStateObject[i].type) {
            case "text": initialMessage = (typeof inputStateObject[i].options != "undefined") ? (!inputStateObject[i].hideRegular ? "Bitte auswählen oder ausfüllen" : "Bitte auswählen") : "Bitte ausfüllen";
              break;
            case "audio": 
            case "color": initialMessage = "Bitte auswählen";
              break;
            case "video":
            case "image": initialMessage = (typeof inputStateObject[i].options != "undefined") ? "Bitte auswählen" : "Bitte hochladen oder aufnehmen";
              break;
          }
          _values =
            { ..._values,
               [inputStateObject[i].tag]: inputStateObject[i].required ? {valid: false, messages: [initialMessage]} : {valid: true, messages: [initialMessage]}
            }
        }
      } else {
        _values = {
          ..._values,
          ...retrieveValidation(inputStateObject[i].groupContent)
        }
      }
    }
    return _values;
  }
}

function logFD(formDataObject) {
  let log = "formData:\n";
  for (var pair of formDataObject.entries()) {
    log += "  " + pair[0] + ": " + pair[1] + "\n";
  }
  console.log(log);
}


export default VideoForm;
