import React, { Fragment, useState, useEffect } from 'react';

import { 
    Button,
    Modal, 
    ModalBody, 
    Form, 
    Input, 
    FormGroup, 
    Label, 
    ModalFooter, 
    ModalHeader } from 'reactstrap';
    
import ValidationCheck from '../videoForm/inputs/ValidationCheck';
import PrivacyCheck from '../videoForm/inputs/PrivacyCheck';
import PrivacyStatement from '../legal/PrivacyStatement';

const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

/* props
    isAuthenticated
    onAuthChange
*/
const RegistrationModal = (props) => {

    // const [modal, setModal]                         = useState(false); 
    const [inputs, setInputs]                       = useState({userEmail: "", userPw: ""});
    const [modal, setModal]                         = useState(false);
    const [privacyModal, setPrivacyModal]           = useState(false);
    const [wrongCredentials, setWrongCredentials]   = useState(false);
    
    const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false); //TODO incorporate with above



    useEffect(() => {
        setInputs({userEmail: "", userPw: ""});
        setWrongCredentials(false);
    }, [props.isAuthenticated])

    function toggle() {
        // props.onAuthChange(!props.isAuthenticated);
        setModal(!modal);
    } 

    function togglePrivacyModal() {
        setPrivacyModal(!privacyModal);
    }

    function handleInputChange(event) {
        const targetName = event.target.name;
        const value = event.target.value;

        setInputs({
            ...inputs,
            [targetName]: value});
    }


    //TODO NOW
    function handleRegSubmit() {
        const params = new URLSearchParams();
        params.append("email", inputs.reg_user);
        params.append("password", inputs.reg_pw);
        // params.append("leftVideos", inputs.reg_leftVids);
        // params.append("templateIDsString", inputs.reg_templates);
        // logFD(params); //TODO pull out to util file
        const token = window.sessionStorage.getItem('mv_usr');
        if(token) {
            axios.post(
            `${apiUrl}/api/auth/register`,
            params,
            {headers: { Authorization: `Bearer ${token}` }}) //api/auth/register
            .then(res => {
            // console.log(res.status + " " + res.statusText);
            // console.log(res.data);
            })
            .catch((err) => {
            // console.log(err);
            console.log(err.response.message);
            console.log(err.response.status);
            });
        } else {
            console.log("Err: token missing");
        }
    }


    function handlePSCheckChange(event) {
        const checkValue = event.target.checked;
        // console.log(checkValue);
        
        setIsPrivacyAccepted(checkValue);
      }

    return(
        <Fragment>
            <Button className="text-dkshade bg-miuvi border-dark" onClick={toggle}>
                Registrieren
            </Button>
            {!props.isAuthenticated &&
                <Modal isOpen={!props.isAuthenticated && modal /*&& (true || modal)*/} toggle={toggle} centered={true} size={""}>
                    <Form>
                        <ModalHeader onToggle={toggle} className="text-miuvi bg-dkshade">
                            Registrierung
                        </ModalHeader>
                        <ModalBody className="">
                            <FormGroup>
                                <Label for="userEmail">E-Mail</Label>
                                <Input type="email" name="userEmail" id="userEmail" className={`${(wrongCredentials && "border-danger")}`} onChange={handleInputChange} value={inputs.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="userPw">Passwort</Label>
                                <Input type="password" name="userPw" id="userPw" className={`${(wrongCredentials && "border-danger")}`} onChange={handleInputChange} value={inputs.pw} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="userPw">Passwort wiederholen</Label>
                                <Input type="password" name="userPw" id="userPw" className={`${(wrongCredentials && "border-danger")}`} onChange={handleInputChange} value={inputs.pw} />
                            </FormGroup>
                            <FormGroup check>
                                <Label check>   
                                    <Input type="checkbox" onChange={handlePSCheckChange} />{' '}
                                        * Ich bin mit miuVi's <a className="text-miuvi" onClick={togglePrivacyModal}> Datenschutzbestimmungen </a> einverstanden.
                                    <Modal isOpen={privacyModal} toggle={togglePrivacyModal} size="lg" scrollable={true}>
                                        <ModalHeader toggle={togglePrivacyModal} className="text-white bg-dkshade">Datenschutzbestimmungen</ModalHeader>
                                        <ModalBody>
                                        <PrivacyStatement />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" className="text-light" onClick={togglePrivacyModal}>Schließen</Button>{' '}
                                        </ModalFooter>
                                    </Modal>
                                </Label>
                            </FormGroup>
                            <ValidationCheck 
                                criteria={!wrongCredentials}
                                messages={[<span>Registrierung fehlgeschlagen<br/> Bitte überprüfe deinen Nutzernamen und Passwort</span>]}>
                            </ValidationCheck>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                handleRegSubmit();
                                }}>
                                Registrieren
                            </Button>{' '}
                            <Button color="secondary" onClick={toggle}>Abbrechen</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </Fragment>
    );
}



//TODO I DON'T BELONG HERE !!! (already present in VideoForm [where i also don't belong {should be globally accessible help function}])
function logFD(formDataObject) {
    let log = "formData:\n";
    for (var pair of formDataObject.entries()) {
      log += "  " + pair[0] + ": " + pair[1] + "\n";
    }
    console.log(log);
  }




export default RegistrationModal;

