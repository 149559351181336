import React, { Fragment } from 'react';
import {
  Link
} from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button
} from 'reactstrap';
import 'react-circular-progressbar/dist/styles.css';

const TrainingPage = (props) => {

  return (
      <Fragment>
        
        
        <Container fluid className="bg-blue py-3 text-light py-sm-5 ">
          <Row className=" ">
            <Col md={{size: 10, offset: 1}} className="pb-3 border-corners d-flex flex-column">
              <Row style={{height: "17rem"}} className="text-center mb-auto  px-3">
                <Col xs="3" className="d-flex justify-content-start p-0 pl-4 pt-4 pl-md-5 pt-md-5">
                  <img src="battery.svg" style={{maxHeight: "2rem"}} className="img-fluid d-block" />
                </Col>
                <Col xs="6" className="d-flex align-items-center flex-column">
                  <Row className="mb-auto">
                    <Col className="">
                      {/* <img src="rec_time.svg" style={{maxHeight: "1.25rem"}} className="mt-3 img-fluid d-block" />
                      <span style={{fontSize: "2rem"}} className="text-primary">00:21:02:35</span> */}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col className="">
                      <h2 className="text-miuvi"><strong>Videoaufnahmen die sitzen</strong></h2>
                      <h5 className="text-light"><span className="">Sie wünschen sich zusätzliche Unterstützung?</span></h5>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3" className="d-flex justify-content-end p-0 pr-4 pt-4 pr-md-5 pt-md-5">
                  {/* <img src="red_dot.svg" style={{maxHeight: "3rem"}} className="mr-3 img-fluid d-block" /> */}
                  <img src="rec_text_dot.svg" style={{maxHeight: "2rem"}} className="img-fluid d-block" />
                  {/* <Row className="align-items-start">
                    <Col className="d-flex align-items-center">
                      <span style={{height: "2.5rem", width: "2.5rem", display: "inline-block"}} className="bg-danger rounded-circle mr-3 blink"></span>
                      <span style={{fontSize: "2.5rem"}} className="text-primary">REC</span>
                    </Col>
                  </Row> */}
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col></Col>
                <Col className="d-flex p-3 align-items-center justify-content-center">
                  <div className=" d-flex align-items-center justify-content-center border-corners-s">
                    <span style={{fontSize: "2rem"}} className="text-primary">+</span>
                  </div>
                </Col>
                <Col></Col>
              </Row>
              <Row style={{height: "18rem"}} className="text-center align-items-center">
                <Col sm="2" />
                <Col sm="4" className=" px-3 py-3" >
                  <h5 className="">Wir kommen vorbei & drehen professionelle Aufnahmen </h5>
                    <p className="lead pt-3">
                      <Button tag={Link} to={`/contact`} color="transparent" className="rounded-xxl text-pink py-3 px-4 border-pink">
                         Kontakt
                      </Button>
                    </p>
                </Col>
                <Col sm="4" className="px-3 py-3" >
                  <h5 className="">Wir bieten Schulungen vor Ort & online an</h5>
                  <p className="lead pt-3">
                      <Button tag={Link} to={`/contact`} color="transparent" className="rounded-xxl text-yellow py-3 px-4 border-yellow">
                          Kontakt
                      </Button>
                    </p>
                </Col>
                <Col sm="2" />
              </Row>
            </Col>
          </Row>
        </Container>
       
        <Container fluid className="text-light gradient-righ pt-5 mt-5">
          <Row className="pb-2 pt-3 pt-xl-5">
            <Col xs={{size: 10, offset: 1}} md={{size: 6, offset: 1}} className="my-auto rounded" >
                {/* <video playsInline preload="metadata" autoPlay muted loop className="img-fluid mx-auto d-block">
                  <source src={'smart.m4v'} type="video/mp4" />
                </video> */}
                
                <img src={'tutorial.svg'} alt="content" className="img-fluid rounded mx-auto p-3 d-block" />
                {/* <div className="text-center">
                    <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                    <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                    <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
                </div> */}
            </Col>
            <Col xs="12" md={{size: 4, offset: 1}} className="mt-0 pt-lg-5" >
              <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                  <h1 className="text-blue"><strong><span className="bg-miuvi text-light rounded px-2 pb-1">Erstklassige</span> Begleitung & Unterstützung</strong></h1>
                  <p className="lead mt-4 text-blue">Schritt für Schritt zeigen wir Ihnen worauf es bei Videoerstellung ankommt</p>
                  <p className="lead mt-4">
                      <Button tag={Link} to={`/contact`} color="primary" className="text-light rounded-xxl py-3 px-4">
                          Mehr Infos
                      </Button>
                  </p>
              </Jumbotron>
            </Col>
          </Row>
        </Container>

        <Container fluid className="text-light gradient-righ">
          <Row className="pb-3 pt-1 pt-xl-5">
              <Col xs={{size: 12, offset: 0, order: 2}} md={{size: 4, offset: 1, order: 1}} className="mt-0 pt-lg-5" >
                <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                  <h1 className="text-blue"><strong>Die <span className="bg-primary text-light rounded px-2 pb-1">richtige</span> Wahl des Equipments</strong></h1>
                  <p className="lead mt-4 text-blue">Zugeschnitten auf Sie, stellen wir Ihnen das richtige Equipment zusammen</p>
                  <p className="lead mt-4">
                      <Button tag={Link} to={`/contact`} color="primary" className="text-light rounded-xxl py-3 px-4">
                          Mehr Infos
                          </Button>
                  </p>
                </Jumbotron>
              </Col>
              <Col xs={{size: 11, offset: 1, order: 1}} md={{size: 5, offset: 1, order: 2}} className=" my-auto mx-auto" >
                {/* <video playsInline preload="metadata" autoPlay muted loop className="img-fluid mx-auto d-block">
                  <source src={'smart.m4v'} type="video/mp4" />
                </video> */}
                
                <img src={'equipment.svg'} alt="content" className="rounded-xxl img-fluid mx-auto d-block" />
                {/* <div className="text-center">
                    <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                    <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                    <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
                </div> */}
              </Col>
          </Row>
        </Container>
       
       <Container fluid className="text-light gradient-righ ">
         <Row className="pb-5 pt-3 pt-xl-5 ">
           <Col xs={{size: 10, offset: 1}} md={{size: 6, offset: 1}} className="my-auto rounded" >
               {/* <video playsInline preload="metadata" autoPlay muted loop className="img-fluid mx-auto d-block">
                 <source src={'smart.m4v'} type="video/mp4" />
               </video> */}
               
               <img src={'duplicate.svg'} alt="content" className="img-fluid rounded mx-auto p-3 d-block" />
               {/* <div className="text-center">
                   <img className="d-block mx-auto mb-2 logo" src="miuVi_logo_col2.svg" alt="miuVi Logo" width={"100rem"} />
                   <h1><span className="text-miuvi">miu</span><span className="text-dark"><strong>Vi</strong></span></h1>
                   <p className="lead"><span className="text-dark">Videopitch für</span> <strong className="text-miuvi">Recruiting</strong></p>
               </div> */}
           </Col>
           <Col xs="12" md={{size: 4, offset: 1}} className="mt-0 pt-lg-5" >
             <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                 <h1 className="text-blue"><strong><span className="bg-miuvi text-light rounded px-2 pb-1">Schulung</span><br/> für Spitzen-Aufnahmen</strong></h1>
                 <p className="lead mt-4 text-blue">Mit der richtigen Schulung, fällt Ihnen das Aufnehmen ab sofort kinderleicht</p>
                 <p className="lead mt-4">
                     <Button tag={Link} to={`/contact`} color="primary" className="text-light rounded-xxl py-3 px-4">
                         Mehr Infos
                         </Button>
                 </p>
             </Jumbotron>
           </Col>
         </Row>
       </Container>



        <Container className="rounded-xxl bg-lightpink my-5">
          <Row className="mt-5 mb-5 mt-xl-5 pt-3 pt-lg-5">
              <Col lg="4" className="pt-3 px-4 pt-xl-5">
                <Jumbotron className="bg-transparent rounded-0 rounded-bottom">
                  <h2 className="text-blue"><strong>Professionelles <span className="rounded bg-blue px-2 pb-1 text-light">Komplett-Paket</span> <span className=""></span> </strong></h2>
                  <p className="lead mt-4"><strong><span className="text-light"></span></strong>Wir übernehmen den kompletten Dreh - von der Planung bis zur Fertigstellung</p>
                  <p className="lead mt-4 pt-3">
                    {/*TODO*/}
                      <Button tag={Link} to={`/contact`} color="" className="bg-blue text-light rounded-xxl py-3 px-4 mr-3">
                        Kontakt
                      </Button>
                  </p>
                </Jumbotron>
              </Col>
              <Col xs="11" lg="7" className="d-flex mx-auto mx-2 align-items-end">
                <img src="videostation.svg" className="img-fluid mx-auto d-block" />
              </Col>
          </Row>
        </Container>


        <Container className="py-5 mb-5">
          <Row className="align-items-center pt-3">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col className="pt-5 pb-3 text-dkshade text-center">
              <h1 className="text-blue"><strong>Erstellen Sie Videos innerhalb<br/>von <span className="bg-pink text-light rounded pb-2 px-2">wenigen</span> Minuten</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Button tag={Link} to={`/contact`} color="" className="bg-primary text-light rounded-xxl mt-4  py-3 px-4">
                Video erstellen
              </Button>
            </Col>
          </Row>
        </Container>

      </Fragment>
  );
}

export default TrainingPage;
