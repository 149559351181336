import React, { Fragment, useState } from 'react';

import { Container, Row, Col, Jumbotron, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle, CardText, Button, Card, ListGroup, ListGroupItem, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import { Link, useParams } from 'react-router-dom';


const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;


const PreviewPage = () => {

  const {bucketId, fileName} = useParams();

  const [triedSubmit, setTriedSubmit] = useState(false);
  const [revokeModalOpen, setRevokeModalOpen] = useState(false);
  const [result, setResult] = useState({res: {}, modalHeader: "", modalBody: ""});


  const handleAcceptOrDeclineVideo = async (accepted, bucketId, fileName) => { //TODO fetch info of all requested templates in one db call @ TemplatePage
    // setIsLoading(true);
    console.log("accepted: ", accepted, bucketId, fileName);
  
    // const token = window.sessionStorage.getItem('mv_usr');
    // if(token) {
      axios.get(
        `${API_URL}/acceptvideo/${accepted}/${fileName}`
        //{ headers: { Authorization: `Bearer ${token}` } } //TODO? auth (not 100% necessary as video is tied to client mail)
      )
      .then(res => {
          setTriedSubmit(() => true);
          // console.log(res.status + " " + res.statusText);
          // console.log(res.data);
          if(res.status === 200 || res.status === 304) {
            console.log(res.data);
            switch(res.data) {
              case "Video sent": 
                setResult({res,
                           modalHeader: "Dein Video ist auf dem Weg",
                           modalBody: <p>Du erhältst in Kürze eine weitere Email mit dem fertigen Video.</p>
                           });
                break;
              case "Video revoked":
                setResult({res,
                           modalHeader: "Video storniert",
                           modalBody: <p>Dein Videokontingent wurde zurückgesetzt und du kannst nun ein neues Video erstellen.</p>
                           });
                break;
              default: console.log("Err: Preview 200 without message");
            }
          } else {
              console.log(res.data);
          }
      })
      .catch(err => {
          setTriedSubmit(() => true);
          console.log(err.response.status, err.response.data);
          switch(err.response.data) {
            case "Video already accepted":
              setResult({res: err.response,
                         modalHeader: "Video bereits angenommen",
                         modalBody: <p>Du hast dein Video bereits angenommen.<br/>Schau noch einmal in deiner Inbox nach ob du die E-Mail bekommen hast.</p>
                         });
              break;
            case "No video found":
              setResult({res: err.response,
                         modalHeader: "Video nicht gefunden",
                         modalBody: <p>Das von dir angeforderte Video existiert nicht. Falscher Link?</p>
                         });
              break;
            case "Reset leftVideos failed":
              setResult({res: err.response,
                         modalHeader: "Videokontingent konnte nicht zurückgesetzt werden",
                         modalBody: <p>Existiert dein Account noch?</p>
                         });
              break;
            default: setResult({res: err.response,
              modalHeader: "Fehler",
              modalBody: <p>Ein unerwarteter Fehler ist aufgereten.<br/>Bitte versuche es erneut oder kontaktiere unseren Support.</p>
              });
          }
      })
  };
  
  const VideoFrame = (props) => {
    
      return(
        <video playsInline preload="metadata" controls className="img-fluid mx-auto d-block border-0" >
          <source src={`https://www.dropbox.com/s/${props.bucketId}?raw=1`} /> {/*/${props.fileName} -> drop auto replace; raw=1 -> direct embed*/}
        </video>
      );
  } 

    return(
      <Fragment>
        <Modal isOpen={triedSubmit} centered>
          <ModalHeader className={result.res.status && parseInt(result.res.status) >= 400 ?
                                  `text-white bg-danger` :
                                  `bg-primary`}>
            {result.modalHeader}
          </ModalHeader>
          <ModalBody>
            {result.modalBody}
          </ModalBody>
          <ModalFooter>
            {result.res.status && parseInt(result.res.status) < 400 ?
              <Button color="primary" tag={Link} to="/create">
                  Zurück zum Tool
              </Button>
              :
              <Button onClick={() => {setResult({res: {}, modalHeader: "", modalBody: ""}); setTriedSubmit(() => false)}}>
                Zurück
              </Button>
            }
          </ModalFooter>
        </Modal>
        <Modal isOpen={revokeModalOpen} centered>
          <ModalHeader className={`bg-warning`}>
            Video stornieren?
          </ModalHeader>
          <ModalBody>
            <p>Dein Video wird storniert und du kannst das Video nochmals erstellen.</p>
          </ModalBody>
          <ModalFooter>
              <Button onClick={() => handleAcceptOrDeclineVideo(false, bucketId, fileName)} color="primary">
                Stornieren
              </Button>
              <Button onClick={() => setRevokeModalOpen(false)}>
                Zurück
              </Button>
          </ModalFooter>
        </Modal>
        <Container fluid className=" py-3 pt-5"> 
          <Row className="align-items-center pt-3">
            <Col className="pt-5 pb-3 mb-3 text-blue text-center">
              <h1 className="">Vorschau</h1>
            </Col>
          </Row>
          <Row className="align-items-center pt-3">
            <Col className="mb-3 text-blue text-center">
              <span className=''>Hier kannst du dir dein fertiges Video in geringer Qualität ansehen.<br/>
                  Wenn alles passt schicken wir dir sofort dein Video in voller Aufösung hinterher, ansonsten kannst du es jetzt noch stornieren.</span>
            </Col>
          </Row>
          {/* <code>{bucketId + "___" + fileName}</code>
          <br></br>
          <code>- {result.res.data + ", " + result.res.status} -</code> */}
          <Row className="py-5  ">
            <Col xs={{size: 12, offset: 0}} sm={{size: 10, offset: 1}} md={{size: 8, offset: 2}} lg={{size: 6, offset: 3}} className="rounded-xxl shadow mb-4 embed-responsive embed-responsive-16by9">
              <VideoFrame API_URL={API_URL} bucketId={bucketId} fileName={fileName} />
            </Col>
          </Row> 
          <Row>
            <Col className="pb-5 d-flex justify-content-center">
              <Button onClick={() => handleAcceptOrDeclineVideo(true, bucketId, fileName)} color="miuvi" className=" rounded-xxl py-3 px-4">
                <strong>Sieht gut aus!</strong>
              </Button>
            </Col>
            <Col className="pb-5 d-flex justify-content-center">
              <Button onClick={() => setRevokeModalOpen(true)} color="dkshade" className=" rounded-xxl py-3 px-4">
                Nicht zufrieden
              </Button>
            </Col>
          </Row>
        </Container>
      </Fragment>
  
    );
  }

  export default PreviewPage;