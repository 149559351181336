import React, { useEffect } from 'react';
import useScript from '../../hooks/useScript';


const CookieConsentModal = (props) => {
    useScript("https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.6.1/dist/cookieconsent.js");
    useScript("/vanilla-components/cookieConsent/cookieConsent.js");

    return(<React.Fragment></React.Fragment>);
}

export default CookieConsentModal;